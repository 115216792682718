import React from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import Home from "./frontend/Home";
import Users from "./frontend/users";
import Studio from "./studio";
import Admin from "./admin";
import Login from "./admin/Login";
import EditProfile from "./frontend/users/EditProfile";
import Upgrade from "./frontend/users/Upgrade";
import RequestedVocals from "./frontend/users/RequestedVocals";
import CheckoutVocal from "./frontend/users/CheckoutVocal";
import { config } from "./config";
import { pdfjs } from "react-pdf";

import "./fonts/Matter-Regular.ttf";
import "./fonts/Matter-Regular.eot";
import "./fonts/Matter-Regular.woff";
import "./fonts/Matter-Regular.woff2";
import "./fonts/Matter-Medium.ttf";
import "./fonts/Matter-Medium.eot";
import "./fonts/Matter-Medium.woff";
import "./fonts/Matter-Medium.woff2";
import "./fonts/Matter-Bold.ttf";
import "./fonts/Matter-Bold.eot";
import "./fonts/Matter-Bold.woff";
import "./fonts/Matter-Bold.woff2";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Recover from "./frontend/Recover";

const stripePromise = loadStripe(config.stripeApikey);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function OnlineContent() {
    const isAdmin =
        config.user && config.user.user_type === "admin" ? true : false;
    const isUser =
        config.user && config.user.user_type === "public" ? true : false;
    const isSuper =
        (config.user && config.user.user_type === "public") ||
        config.user.user_type === "admin"
            ? true
            : false;
    return (
        <Elements
            stripe={stripePromise}
            options={{
                fonts: [
                    {
                        cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
                    },
                ],
            }}
        >
            <Router>
                <Switch>
                    {/*normal routes*/}
                    <Route
                        exact
                        path="/"
                        render={props => {
                            return <Home {...props} />;
                        }}
                    />

                    <Route path="/edit_profile" component={EditProfile} />
                    <Route path="/login" component={Login} />
                    <Route path="/recover/:id" component={Recover} />

                    {/*private routes*/}

                    <UpgradeRoute
                        authed={isUser}
                        path="/subscribe"
                        component={Upgrade}
                    />
                    <UpgradeRoute
                        authed={isUser}
                        path="/profile/requested-vocals"
                        component={RequestedVocals}
                    />
                    <UpgradeRoute
                        authed={isUser}
                        path="/profile/checkout-vocal-request"
                        component={CheckoutVocal}
                    />

                    <PrivateRouteUser
                        authed={isUser}
                        path="/profile/:id"
                        component={Users}
                    />
                    <StudioRoute
                        authed={isSuper}
                        path="/studio"
                        component={Studio}
                    />
                    <PrivateRouteAdmin
                        authed={isAdmin}
                        path="/admin"
                        component={Admin}
                    />
                </Switch>
            </Router>
        </Elements>
    );
}

function StudioRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                authed === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location, showlogin: true },
                        }}
                    />
                )
            }
        />
    );
}

function UpgradeRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                authed === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location, showlogin: true },
                        }}
                    />
                )
            }
        />
    );
}

function PrivateRouteUser({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                authed === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location, showlogin: true },
                        }}
                    />
                )
            }
        />
    );
}

function PrivateRouteAdmin({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                authed === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

export default OnlineContent;
