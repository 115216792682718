import React, { Component } from 'react'
import { Button, Card, Col, Form, Input, message, Modal, Row, Table } from 'antd';
import { callapi, deleteapi, patchapi, postapi } from "../helper";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import HTMLReactParser from 'html-react-parser';
import renderHTML from 'react-render-html';

class WrappedSubscriptionNotices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingnotice: false,
            noticeText: '',
            notices: [],
            addpostMdal: false,
            loadingposts: false,

            single: false,
        }
    }

    componentDidMount() {
        // load essentials data
        this.getNotices();
    }

    // load all notice data
    getNotices() {
        //getNotices
        const _this = this;
        _this.setState({
            loadingnotice: true
        });
        const notice = callapi('subnotices');
        notice.then((data) => {
            _this.setState({
                notices: data.data.data,
                loadingnotice: false
            });
        });
    }

    // parse notice
    parseNotice(notice) {
        const noticeText = HTMLReactParser(notice)

        if (Array.isArray(noticeText)) {
            return notice
        }
        else {
            return noticeText
        }

    }

    // submit the form if validation success
    handleSubmit = e => {
        const _this = this;
        e.preventDefault();
        _this.props.form.validateFields((err, values) => {

            if (!err) {

                values.notice = _this.state.noticeText;

                const savenotice = postapi('subnotices', values);

                savenotice.then((data) => {
                    if (data.status === 'success') {
                        message.success('Notice successfully added.');
                        _this.setState({
                            noticeText: ""
                        }, () => {
                            _this.props.form.resetFields();
                            _this.getNotices();
                        });
                    } else {
                        message.error(data.message);
                    }
                })
                    .catch((err) => {
                        message.error(err.message);
                    });
            }
        });
    };

    // update the notice if validation success
    handleUpdate = e => {
        const _this = this;
        e.preventDefault();
        _this.props.form.validateFields((err, values) => {

            if (!err) {

                values.notice = _this.state.noticeText;

                const savenotice = patchapi(`subnotices/${_this.state.single._id}`, values);
                savenotice.then((data) => {
                    if (data.status === 'success') {
                        message.success('Notice successfully updated');
                        _this.setState({
                            noticeText: "",
                            single: false
                        }, () => {
                            _this.props.form.resetFields();
                            _this.getNotices();
                        });
                    } else {
                        message.error(data.message);
                    }
                })
                    .catch((err) => {
                        message.error(err.message);
                    });
            }
        });
    };

    render() {
        // get the field decorator for the form
        const { getFieldDecorator } = this.props.form;
        const _this = this;

        // set the table columns
        const columns = [
            {
                title: 'Title',
                dataIndex: 'notice_title',
                key: 'notice_title',
                width: "40%"
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                width: "20%",
                render: (text, record) => {
                    return (
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                        }}>
                            <Button
                                size="small"
                                type="primary"
                                icon="edit"
                                onClick={() => {
                                    // set update states
                                    _this.setState({
                                        single: record,
                                        addpostMdal: true,
                                        noticeText: this.parseNotice(`${record.notice}`)
                                    }, () => {
                                        _this.props.form.setFieldsValue({
                                            notice_title: record.notice_title
                                        })
                                    });
                                }}
                            />
                            <Button
                                size="small"
                                type="danger"
                                icon="delete"
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Are you sure delete this post?',
                                        content: 'You cannot retrieve this post again after delete.',
                                        okText: 'Yes',
                                        okType: 'danger',
                                        cancelText: 'No',
                                        onOk() {
                                            // delete a post
                                            const deletepost = deleteapi(`subnotices/${record._id}`);
                                            deletepost.then((data) => {
                                                // check if posts is successfully deleted

                                                Modal.success({
                                                    title: "Success",
                                                    content: data.msg,
                                                });
                                                _this.getNotices();


                                            })
                                                .catch((err) => {
                                                    // show the error message if post was not deleted
                                                    Modal.error({
                                                        title: "Sorry!",
                                                        content: err.message,
                                                    })
                                                });
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                                }}
                            />
                        </div>
                    )
                }
            },
        ];

        return (
            <div>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Card
                            title="Manage Subscription Notices"
                        >
                            <Form onSubmit={_this.state.single ? this.handleUpdate : this.handleSubmit}>
                                <Form.Item
                                    label='Title'
                                >
                                    {getFieldDecorator('notice_title', {
                                        initialValue: _this.state.single.notice_title,
                                        rules: [{ required: true, message: 'Please type notice title!' }],
                                    })(
                                        <Input
                                            placeholder="Notice Title"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label='Message'
                                >
                                    <ReactQuill
                                        theme="snow"
                                        style={{
                                            height: 300,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginBottom: '30px'
                                        }}
                                        value={this.state.noticeText}
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean']
                                            ],
                                        }}
                                        formats={[
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'link'
                                        ]}
                                        onChange={(val) => {
                                            this.setState({
                                                noticeText: val
                                            });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        {_this.state.single ? "Update notice" : "Save notice"}
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    {
                                        _this.state.single &&
                                        <Button
                                            type="danger"
                                            className="login-form-button"
                                            onClick={() => {
                                                _this.props.form.resetFields();
                                                _this.setState({
                                                    single: false,
                                                    noticeText: ""
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    }
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Card>
                            <Table rowKey={rec => rec._id} loading={this.state.loadingnotice} dataSource={this.state.notices} columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}

const SubscriptionNotices = Form.create({ name: 'add_notice' })(WrappedSubscriptionNotices);


export default SubscriptionNotices
