import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Icon,
    Divider,
    Table,
    Drawer,
    Form,
    Input,
    message,
    Modal, InputNumber
} from "antd";
import { callapi, deleteapi, patchapi, postapi } from "../helper";

import voucher_codes from "voucher-code-generator";

const FormItem = Form.Item;

const coupon_config = {
    length: Math.floor(Math.random() + 6),
    count: 1,
    charset: voucher_codes.charset("alphanumeric"),
};

class Coupon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            openDrawer: false,
            isFetching: true,
            isLoading: false,
            couponsData: [],
            drawerWidth: 520,
            values: {
                name: "",
                coupon: "",
                amount: "",
            },
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getCoupons();
    }

    generateCoupons = () => {
        const coupon = voucher_codes.generate(coupon_config);

        this.setState({
            values: {
                ...this.state.values,
                coupon: coupon[0],
            },
        });

        this.props.form.setFieldsValue({
            coupon: coupon[0],
        });
    };

    getCoupons = () => {
        callapi("coupons").then((response) => {
            if (response) {
                const data = response.data.data.map((res) => ({ key: res._id, ...res }));
                this.setState({
                    isFetching: false,
                    couponsData: data,
                });
            }
        }).catch((error) => {
            console.log(error);
        })
    };

    handleEdit = (id) => {
        const value = this.state.couponsData.filter((coupon) => coupon._id === id);

        this.setState({
            id: id,
            isToUpdate: true,
            openDrawer: true,
            values: value[0],
        });

        this.props.form.setFieldsValue({
            coupon: value[0].coupon,
            name: value[0].name,
            amount: value[0].amount,
        });
    };

    handleDelete = (id) => {
        const _this = this;
        Modal.confirm({
            title: "Do you want to delete this coupon?",
            content: "You cannot retrieve the coupon once you deleted.",
            onOk() {
                _this.setState({
                    isLoading: true,
                });

                deleteapi(`coupons/${id}`).then((response) => {
                    _this.setState({
                        isLoading: false,
                        openDrawer: false,
                    });
                    message.success(
                        "Coupon deleted successfully!"
                    );
                    _this.getCoupons();
                })
                    .catch((error) => {
                        _this.setState({
                            isLoading: false,
                        });
                        message.error(error.response.message);
                    });
            },
            onCancel() {
            },
        });
    };

    handleReset = () => {
        this.setState({
            values: {
                name: "",
                coupon: "",
                amount: "",
            },
        });

        this.props.form.setFieldsValue({
            coupon: "",
            name: "",
            amount: "",
        });
    };

    handleChange = (value, name) => {
        this.setState({
            values: {
                ...this.state.values,
                [name]: value,
            },
        });
    };

    handleSubmit = () => {
        const data = {
            name: this.state.values.name,
            coupon: this.state.values.coupon,
            amount: this.state.values.amount,
            limit: this.state.values.limit,
        };

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    isLoading: true,
                });

                postapi('coupons', data).then((response) => {
                    this.setState({
                        isLoading: false,
                        openDrawer: false,
                    });
                    message.success(response.msg);
                    this.getCoupons();
                    this.handleReset();
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                    });
                    message.error(error.response.message);
                })
            }
        });
    };

    handleUpdate = () => {
        this.setState({
            isLoading: true,
        });

        const data = {
            id: this.state.id,
            name: this.state.values.name,
            coupon: this.state.values.coupon,
            amount: this.state.values.amount,
        };

        patchapi(`coupons/${this.state.id}`, data).then((response) => {
            if (response.data.status === "error" || response.data.status === "failed") {
                this.setState({
                    isLoading: false,
                });
                message.error(response.data.message);
            }

            this.setState({
                isLoading: false,
                openDrawer: false,
            });

            message.success(response.msg);
            this.getCoupons();
            this.handleReset();
        })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                message.error(err.response.message);
            })
    };

    render() {
        const _this = this;
        const { getFieldDecorator } = this.props.form;

        const columns = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Coupon",
                dataIndex: "coupon",
            },
            {
                title: "Amount (%)",
                dataIndex: "amount",
            },
            {
                title: "Limit",
                dataIndex: "limit",
            },
            {
                title: "Used",
                dataIndex: "used",
            },
            {
                title: "Action",
                dataIndex: "action",
                key: "action",
                render: (text, record) => (
                    <div key={record._id}>
                        {/* <Button
                            size="small"
                            type="primary"
                            onClick={() => this.handleEdit(record._id)}
                        >
                            <Icon type="edit" />
                        </Button> */}
                        {/* <Divider type="vertical" /> */}
                        <Button
                            size="small"
                            type="danger"
                            onClick={() => this.handleDelete(record._id)}
                        >
                            <Icon type="delete" />
                        </Button>
                    </div>
                ),
            },
        ];

        return (
            <div>
                <Row>
                    <Col lg={24}>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                                padding: "20px 0",
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={() =>
                                    this.setState({
                                        isToUpdate: false,
                                        openDrawer: true,
                                    })
                                }
                            >
                                Add New Coupon
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div style={{ backgroundColor: "#fff", padding: 20 }}>
                        <Table
                            loading={this.state.isFetching}
                            columns={columns}
                            dataSource={this.state.couponsData}
                        />
                    </div>
                </Row>

                <Drawer
                    title={this.state.isToUpdate ? "Update Coupon" : "Create Coupon"}
                    width={this.state.drawerWidth}
                    visible={this.state.openDrawer}
                    onClose={() => {
                        _this.props.form.resetFields();
                        _this.setState({
                            openDrawer: false,
                            values: {
                                name: "",
                                coupon: "",
                                amount: "",
                                limit: 0,
                            }
                        });
                    }}
                >
                    <Form>
                        <FormItem label="Name">
                            {getFieldDecorator("name", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Name is required.",
                                    },
                                ],
                                initialValue: this.state.values.name,
                            })(<Input onChange={e => this.handleChange(e.target.value, 'name')} />)}
                        </FormItem>

                        <FormItem label="Coupon">
                            {getFieldDecorator("coupon", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Coupon is required.",
                                    },
                                ],
                                initialValue: this.state.values.coupon,
                            })(<Input onChange={e => this.handleChange(e.target.value, 'coupon')} />)}
                            <Button
                                size="small"
                                type="primary"
                                onClick={this.generateCoupons}
                            >
                                Generate Code
                            </Button>
                        </FormItem>

                        <FormItem label="Limit">
                            {getFieldDecorator("limit", {
                                rules: [
                                    {
                                        required: true,
                                        message: "limit is required.",
                                    },
                                ],
                                initialValue: this.state.values.limit,
                            })(<InputNumber style={{ width: '100%' }} min={1} step={1} onChange={e => this.handleChange(e, 'limit')} />)}
                        </FormItem>

                        <FormItem label="Amount (%)">
                            {getFieldDecorator("amount", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Amount is required.",
                                    },
                                ],
                                initialValue: this.state.values.amount,
                            })(<InputNumber style={{ width: '100%' }} min={1} step={1} onChange={e => this.handleChange(e, 'amount')} />)}
                        </FormItem>

                        <FormItem>
                            <Button
                                type="primary"
                                block
                                loading={this.state.isLoading}
                                disabled={this.state.isLoading}
                                htmlType="submit"
                                onClick={
                                    this.state.isToUpdate
                                        ? this.handleUpdate
                                        : this.handleSubmit
                                }
                            >
                                Save
                            </Button>
                        </FormItem>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

export default Form.create()(Coupon);
