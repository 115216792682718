import { Icon } from "antd";

const ForwardArrowSvg = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 17.6 12.9">
            <g id="Layer_2_1_">
                <g id="Layer_1-2">
                    <path
                        style={{ fill: "currentColor" }}
                        d="M17.4,6.1l-5.3-5.9C12,0.1,11.9,0,11.7,0H8.2C8,0,7.8,0.1,7.7,0.3C7.6,0.6,7.6,0.8,7.8,1l4.9,5.5l-4.9,5.5
			c-0.2,0.2-0.2,0.4-0.1,0.6c0.1,0.2,0.3,0.4,0.5,0.4h3.5c0.2,0,0.3-0.1,0.4-0.2l5.3-5.8C17.6,6.6,17.6,6.3,17.4,6.1z"
                    />
                    <path
                        style={{ fill: "currentColor" }}
                        d="M9.8,6.1L4.5,0.2C4.4,0.1,4.3,0,4.1,0H0.6C0.4,0,0.1,0.1,0.1,0.3C0,0.6,0,0.8,0.2,1l4.9,5.4l-4.9,5.5
			C0,12.1,0,12.3,0.1,12.5c0.1,0.2,0.3,0.3,0.5,0.4h3.5c0.2,0,0.3-0.1,0.4-0.2l5.3-5.9C10,6.6,10,6.3,9.8,6.1z"
                    />
                </g>
            </g>
        </svg>
    );
};
const ForwardArrowIcon = props => (
    <Icon component={ForwardArrowSvg} {...props} />
);

export default ForwardArrowIcon;
