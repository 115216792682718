import React, { useState, useEffect } from "react";
import {
    Layout,
    Row,
    Col,
    Divider,
    Button,
    Drawer,
    Tabs,
    Modal,
} from "antd";
import LivePerformance from "./images/Live_Performance_Bg_Asset_4.png";
import { config } from "../config";
import SignIn from "./SigninForm";
import SignUp from "./SignupForm";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import ForwardArrowIcon from "./ForwardArrowIcon";
import HomeFooter from "./Home/Footer";
import HomePricing from "./Home/HomePricing";
import HomeFeature from "./Home/HomeFeature";
import HeroBanner from "./Home/HeroBanner";
import HomeHeader from "./Home/HomeHeader";
const { Content } = Layout;
const TabPane = Tabs.TabPane;

const Home = ({ location }) => {

    const [sidemenu, setSidemenu] = useState(false);
    const [signinmodal, setSigninmodal] = useState(false);
    const [defaultactivekey, setDefaultactivekey] = useState("1");
    const [isLogged, setIsLogged] = useState(config.islogged);

    const toggle = () => {
        setSidemenu(!sidemenu);
    };

    useEffect(() => {
        if (location.state && location.state.showlogin) {
            SignInModal(true);
        }
    }, [location.state]);


    const SignInModal = (signinmodal, activekey = "1") => {
        setSigninmodal(signinmodal);
        setDefaultactivekey(activekey);
    };



    return (
        <Layout className="body-front">
            <Modal
                wrapClassName="signinmodal"
                visible={signinmodal}
                onOk={() => SignInModal(false)}
                onCancel={() => SignInModal(false)}
            >
                <Tabs
                    defaultActiveKey={"1"}
                    activeKey={defaultactivekey}
                    onChange={activeKey => {
                        setDefaultactivekey(activeKey);
                    }}
                >
                    <TabPane tab="Login" key="1">
                        <SignIn
                            // {...this.props}
                            islogged={setIsLogged}
                            SignInModal={SignInModal}
                        />
                    </TabPane>
                    <TabPane tab="Register" key="2">
                        <SignUp
                            // {...this.props}
                            islogged={setIsLogged}
                            SignInModal={SignInModal}
                        />
                    </TabPane>
                </Tabs>
            </Modal>

            <Drawer
                placement="right"
                visible={sidemenu}
                onClose={toggle}
                width="70%"
            >
                {config.user &&
                    config.user._id &&
                    config.user.user_type !== "admin" ? (
                    <>
                        <Link
                            to={`/profile/${config.user._id}`}
                            className="ant-btn-link"
                        >
                            Your Profile
                        </Link>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={() => SignInModal(true)}
                            type="link"
                            className="color-dark"
                        >
                            LOG IN
                        </Button>
                        <Divider />
                        <Button
                            onClick={() => SignInModal(true, "2")}
                            type="link"
                        >
                            SIGN UP
                        </Button>
                    </>
                )}
            </Drawer>

            <HomeHeader SignInModal={SignInModal}
                sidemenu={sidemenu}
                toggle={toggle} />
            <Content>
                <HeroBanner
                    SignInModal={SignInModal}
                />

                <HomeFeature
                    SignInModal={SignInModal}
                />

                <Zoom>
                    <div
                        className="hero-section-image"
                        style={{
                            backgroundImage: `url("${LivePerformance}")`,
                        }}
                    >
                        <Row type="flex" justify="center">
                            <Col xs={{ span: 20 }} lg={{ span: 21 }}>
                                <Fade bottom cascade>
                                    <div className="hero-section-card">
                                        <h1>
                                            All mixes created are licensed
                                            and cleared for
                                        </h1>
                                        <h1 className="large-content">
                                            Live & Web Streaming
                                            Performances
                                        </h1>
                                        <a
                                            className="browse-now-btn"
                                            onClick={() =>
                                                SignInModal(true, "2")
                                            }
                                        >
                                            LEARN MORE <ForwardArrowIcon />
                                        </a>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </Zoom>

                <HomePricing
                    SignInModal={SignInModal}
                />

                <HomeFooter
                    SignInModal={SignInModal}
                    sidemenu={sidemenu}
                    toggle={toggle}
                />
            </Content>
        </Layout>
    );
};

export default Home;
