import React, { Component } from 'react'
import {
    Badge, Button, Icon, Table, Upload, message, Divider, Drawer,
    notification, Tooltip, Modal, Col, Row, Select, Form
} from 'antd';
import { callapi, multipart, deleteapi } from "../helper";
import moment from "moment";
import { config } from "../config";
import VocalRequestPlanForm from "./VocalRequestPlanForm";

const { confirm } = Modal;
const { Option } = Select;
const { Dragger } = Upload;

class VocalRequestsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            paletteHeight: window.innerHeight - 100,
            vocals: [],
            users: [],

            uploadingFile: false,

            imageUrl: "",
            loadingUploader: false,
            uploadingfiles: false,

            fileList: [],

            planrawervisible: false,

            vocalrequestplan: [],
            loadingvrp: false,

            singleplanreq: false,
        };

        this.handleChangeDraggable = this.handleChangeDraggable.bind(this);
    }

    componentWillMount() {
        this.getuserVocals();
        this.loadVocalreqPlan();
    }

    getuserVocals() {
        this.setState({
            loadingVocals: true
        });

        const getVocals = callapi('vocalrequests');

        getVocals.then((resp) => {
            if (resp) {
                this.setState({
                    loadingVocals: false,
                    vocals: resp.data.data
                })
            }
            else {
                this.setState({
                    loadingVocals: false
                });
            }
        }).catch((err) => {
            this.setState({
                loadingVocals: false
            });
        });

        const userisnotadmin = callapi('users');

        userisnotadmin.then((resp) => {
            this.setState({
                users: resp.data.users
            })
        }).catch((err) => {
            this.setState({
                users: []
            });
        });

    }

    loadVocalreqPlan() {
        this.setState({
            loadingvrp: true,
        });
        callapi('vocalplans')
            .then((resp) => {
                this.setState({
                    vocalrequestplan: resp.data.data,
                }, () => {
                    this.setState({
                        loadingvrp: false,
                    });
                });
            })
            .catch((err) => {
                this.setState({
                    loadingvrp: false,
                });
            });
    }

    deleteCustomVocalRequest(id) {
        const _this = this;
        confirm({
            title: 'Are you sure?',
            content: 'Once you deleted you cannot retrieve the request again.',
            onOk() {
                const remcsv = deleteapi(`vocalrequests/${id}`);
                remcsv.then((resp) => {

                    Modal.success({
                        title: 'Success',
                        content: (
                            <div>
                                <p>Vocal request has been deleted</p>
                            </div>
                        ),
                        onOk() {
                            _this.getuserVocals();
                        },
                    });
                })
                    .catch((err) => {
                        Modal.error({
                            title: 'Error',
                            content: (
                                <div>
                                    <p>Sorry! Cannot delete the vocal request now.</p>
                                </div>
                            ),
                            onOk() {
                                _this.getuserVocals();
                            },
                        });
                    })
            },
            onCancel() { },
        });
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    beforeUpload(file) {
        const isWav = file.type === 'audio/mp3' || file.type === 'audio/wav' || file.type === 'audio/mpeg';
        if (!isWav) {
            message.error('You can only upload Wav file!');
        }
        return isWav;
    };

    handleChange(info) {
        let fileList = info.fileList;
        if (info.file.status === 'uploading') {
            this.setState({
                loadingUploader: true
            })
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, imageUrl => {
                this.setState({
                    imageUrl: imageUrl,
                    loadingUploader: true
                })
            });
        }
        this.setState({
            fileList: fileList
        });
    };

    handleChangeDraggable(info) {
        let fileList = info.fileList;
        this.setState({
            fileList: fileList
        });
    };

    dummyRequest({ file, onSuccess }) {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    handleSubmitcustomvocal = (e) => {
        e.preventDefault();
        let _this = this;
        _this.setState({
            uploadingfiles: true
        });

        _this.props.form.validateFields((err, values) => {
            if (!err) {
                let requesttypeandprice = values.vocalrequesttype.split("-");

                let getuserinfo = _this.state.users.filter(item => item._id === values.user);

                let formData = new FormData();
                formData.append('uid', getuserinfo[0]._id);
                formData.append('full_name', getuserinfo[0].full_name);
                formData.append('email', getuserinfo[0].username);
                formData.append('reqkey', requesttypeandprice[0]);
                formData.append('reqprice', requesttypeandprice[1]);
                formData.append('added_at', moment().format("YYYY-MM-DD HH:mm:ss"));
                formData.append('updated_at', moment().format("YYYY-MM-DD HH:mm:ss"));

                values.tracks.fileList.map((file, findex) => {
                    formData.append(`file${findex}`, file.originFileObj, file.originFileObj.name);
                });

                multipart('vocalrequests/uploadnewtracks', formData)
                    .then((resp) => {
                        _this.getuserVocals();
                        _this.setState({
                            uploadingfiles: false
                        }, () => {
                            _this.setState({
                                fileList: []
                            });
                            notification.success({
                                message: 'Tracks successfully updated.',
                                description: 'You have uploaded all of the tracks for the user ' + getuserinfo[0].full_name,
                            });
                        });
                    }).catch((err) => {
                        _this.setState({
                            uploadingfiles: false
                        });
                        notification.error({
                            message: 'Error',
                            description: 'Sorry! Cannot upload the tracks now.',
                        });
                    })
                    .finally(() => {
                        _this.setState({
                            uploadingfiles: false
                        });
                        _this.getuserVocals();
                    });
            }
        });
    }

    removeCustomVocal(id, track) {
        const _this = this;
        confirm({
            title: 'Are you sure?',
            content: 'Once you remove you cannot retrieve the vocal again.',
            onOk() {
                const remcsv = deleteapi(`vocalrequests/${id}/removeTrack`);
                remcsv.then((resp) => {
                    if (resp.status === 'success') {
                        Modal.success({
                            title: 'Success',
                            content: (
                                <div>
                                    <p>Vocal has been removed successfully.</p>
                                </div>
                            ),
                            onOk() {
                                _this.getuserVocals();
                            },
                        });
                    }
                    else {
                        Modal.error({
                            title: 'Error',
                            content: (
                                <div>
                                    <p>Sorry! Cannot remove the vocal from the request.</p>
                                </div>
                            ),
                            onOk() {
                                _this.getuserVocals();
                            },
                        });
                    }
                })
            },
            onCancel() { },
        });
    }

    showDrawer = () => {
        this.setState({
            planrawervisible: true,
        });
    };

    onClose = () => {
        this.setState({
            planrawervisible: false,
        });
    };


    deletevocalreqplan(id) {
        const _this = this;

        Modal.confirm({
            title: 'Do you want to delete this plan?',
            content: 'You cannot retrieve once you deleted.',
            onOk() {
                deleteapi(`vocalplans/${id}`).then((data) => {
                    message.success('Plan deleted successfully.');
                    _this.loadVocalreqPlan();

                }).catch((err) => {
                    message.error(err);
                })
            },
            onCancel() {
            },
        });
    }


    render() {

        let _this = this;
        const { getFieldDecorator } = _this.props.form;
        const uploadButton = (
            <div>
                <Icon type={_this.state.loadingUploader ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div id="thstudiomiddle" style={{ backgroundColor: '#fff', padding: 20 }}>
                <Drawer
                    title="Add new vocal request plan"
                    placement="right"
                    closable={false}
                    width="40%"
                    maskClosable={false}
                    onClose={_this.onClose}
                    visible={_this.state.planrawervisible}
                >
                    <VocalRequestPlanForm
                        single={_this.state.singleplanreq}
                        onSubmit={() => {
                            _this.setState({
                                singleplanreq: false
                            }, () => {
                                this.loadVocalreqPlan();
                                _this.onClose();
                            });
                        }}
                        onClose={() => {
                            _this.setState({
                                singleplanreq: false
                            });
                            _this.onClose();
                        }}
                    />
                </Drawer>
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <h2>Upload tracks to users account</h2>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 20 }}>
                            <Button
                                type="primary"
                                onClick={_this.showDrawer}
                            >Create Plan</Button>
                        </div>
                        <Table columns={[
                            {
                                title: 'Plan name',
                                dataIndex: 'plan_name',
                                key: 'plan_name',
                                render: text => <a>{text}</a>,
                            },
                            {
                                title: 'Plan price',
                                dataIndex: 'plan_price',
                                key: 'plan_price',
                            },
                            {
                                title: 'Note',
                                dataIndex: 'has_note',
                                key: 'has_note',
                                render: (text) => {
                                    if (text) {
                                        return ('Yes');
                                    }
                                    else {
                                        return ('No');
                                    }
                                }
                            },
                            {
                                title: 'Action',
                                key: 'action',
                                width: "30%",
                                render: (text, record) => {
                                    return (
                                        <span>
                                            <Divider type="vertical" />
                                            <Button onClick={() => {
                                                _this.setState({
                                                    singleplanreq: record,
                                                }, () => {
                                                    _this.showDrawer()
                                                })
                                            }} size='small' type="primary">
                                                <Icon type="edit" />
                                            </Button>
                                            <Divider type="vertical" />
                                            <Button onClick={() => {
                                                _this.deletevocalreqplan(record._id)
                                            }} size='small' type="danger">
                                                <Icon type="delete" />
                                            </Button>
                                        </span>
                                    )
                                },
                            },
                        ]} dataSource={this.state.vocalrequestplan} loading={this.state.loadingvrp} rowKey="_id" />
                    </Col>
                    <Form
                        onSubmit={_this.handleSubmitcustomvocal}
                    >
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Select user to upload tracks"
                            >
                                {getFieldDecorator('user', {
                                    rules: [{ required: true, message: 'Please select user!' }],
                                    valuePropName: 'option',
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            this.state.users &&
                                            this.state.users.map((useritem, uindex) => {
                                                return <Option key={useritem._id} value={useritem._id}>{`${useritem.full_name} ${useritem.username && useritem.username}`}</Option>;
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                label="Select vocal request type"
                            >
                                {getFieldDecorator('vocalrequesttype', {
                                    rules: [{ required: true, message: 'Please select vocal request type!' }],
                                    valuePropName: 'option',
                                })(
                                    <Select
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            this.state.vocalrequestplan.map((vitem, index) => {
                                                return <Option key={index} value={`${vitem._id}-${vitem.plan_price}`}>{vitem.plan_name} (${vitem.plan_price})</Option>;
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Select files">
                                {getFieldDecorator('tracks', {
                                    initialValue: [],
                                    valuePropName: 'files',
                                })(
                                    <Dragger
                                        accept='audio/wav'
                                        multiple={true}
                                        headers={{
                                            'Authorization': `Bearer ${config.authKey}`
                                        }}
                                        name="tracks"
                                        customRequest={_this.dummyRequest}
                                        beforeUpload={_this.beforeUpload}
                                        onChange={_this.handleChangeDraggable}
                                        fileList={_this.state.fileList}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox" />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                            band files
                                        </p>
                                    </Dragger>,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" disabled={_this.state.uploadingfiles} loading={_this.state.uploadingfiles} htmlType="submit" block>
                                    Upload Tracks
                                </Button>
                            </Form.Item>
                        </Col>

                    </Form>
                </Row>

                <Table
                    loading={this.state.loadingVocals}
                    dataSource={this.state.vocals}
                    rowKey='_id'
                    columns={[
                        {
                            title: 'Requested for',
                            dataIndex: 'requested_for',
                            key: 'requested_for',
                            width: 200,
                            render: (text, record) => {
                                let thedata = record.requested_for;
                                return (
                                    <div>
                                        <p>Request type: {thedata.name}</p>
                                        <p>Description: {thedata.description}</p>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Requested by',
                            dataIndex: 'requested_by',
                            key: 'requested_by',
                            width: 150,
                            render: (text, record) => {
                                let reqdata = record.requested_for;
                                return <span>{record.full_name ? record.full_name : (record.username ? record.full_name : 'Guest')}</span>
                            }
                        },
                        {
                            title: 'Added at',
                            dataIndex: 'added_at',
                            key: 'added_at',
                            render: (text, record) => {
                                return moment(text).format("LLL");
                            }
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text, record) => {
                                if (text === 1) {
                                    return (
                                        <Badge count={'Track Uploaded'} style={{ backgroundColor: '#52c41a' }} />
                                    )
                                }
                                else {
                                    return (
                                        <Badge count={'Processing'} style={{ backgroundColor: 'rgba(255,73,11,0.7)' }} />
                                    )
                                }
                            }
                        },
                        {
                            title: 'Payment',
                            dataIndex: 'payment_status',
                            key: 'payment_status',
                            render: (text, record) => {
                                if (text && text === 'paid') {
                                    return (
                                        <Badge count={'Paid'} style={{ backgroundColor: '#52c41a' }} />
                                    )
                                }
                                else {
                                    return (
                                        <Badge count={'Unpaid'} style={{ backgroundColor: 'rgba(255, 0, 0, 0.7)' }} />
                                    )
                                }
                            }
                        },
                        {
                            title: 'Updated at',
                            dataIndex: 'updated_at',
                            key: 'updated_at',
                            render: (text, record) => {
                                return text && moment(text).format("LLL");
                            }
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions',
                            key: 'actions',
                            width: '10%',
                            render: (text, record) => {
                                let reqdata = record.requested_for
                                return (
                                    <div style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Upload {...{
                                            accept: 'audio/*',
                                            name: 'file',
                                            headers: {
                                                'Authorization': `Bearer ${config.authKey}`
                                            },
                                            action: `${config.apiurl}vocalrequests/${record._id}/addTrack`,
                                            data: {
                                                vocal_name: record.full_name,
                                                price: reqdata.amount,
                                                total: reqdata.amount,
                                                email: record.email,
                                                updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                                            },
                                            method: 'POST',
                                            beforeUpload: () => {
                                                _this.setState({
                                                    uploadingFile: true,
                                                })
                                            },
                                            showUploadList: false,
                                            onChange(info) {
                                                console.log(info.file.status)
                                                const status = info.file.status;
                                                if (status !== 'uploading') {
                                                    console.log(status);
                                                }

                                                if (status === 'done') {
                                                    const resp = info.file.response;
                                                    if (resp.status === 'success') {
                                                        _this.getuserVocals();
                                                        _this.setState({
                                                            uploadingFile: false
                                                        });
                                                        message.success(`${info.file.originFileObj.name} file uploaded successfully.`);
                                                    }
                                                    else {
                                                        _this.setState({
                                                            uploadingFile: false
                                                        });
                                                        message.error(`${info.file.originFileObj.name} - ${resp.msg}.`);
                                                    }
                                                } else if (status === 'error') {
                                                    _this.setState({
                                                        uploadingFile: false
                                                    });
                                                    message.error(`${info.file.originFileObj.name} file upload failed.`);
                                                }


                                            },
                                        }}>
                                            <Button loading={_this.state.uploadingFile}>
                                                Click to Upload
                                            </Button>
                                        </Upload>
                                        <Divider />
                                        {
                                            record.track &&
                                            <Tooltip title={
                                                `${
                                                // Replace any kind of URL encoded into plain text
                                                decodeURI(record.track).replace(
                                                    "https://firebasestorage.googleapis.com/v0/b/cheer-music-maker-2021.appspot.com/o/",
                                                    "").replace(/\?alt=media/, '')
                                                }`
                                            }>
                                                <Button type='danger' icon='close' style={{
                                                    width: '100%',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                                    onClick={() => {
                                                        _this.removeCustomVocal(record._id, record.track);
                                                    }}
                                                >
                                                    {/*
                                                       1 remove baseurl from track url
                                                       2 replace %2F with /
                                                       3 remove alt=media to get the original file
                                                    */}

                                                    {
                                                        record.track
                                                            .replace(/^.*[\\\\\\/]/, '')
                                                            .replace(/%2F/g, '/')
                                                            .replace(/\?alt=media/, '')

                                                    }
                                                </Button>
                                            </Tooltip>
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            title: null,
                            dataIndex: 'actionsextra',
                            key: 'actionsextra',
                            render: (text, record) => {
                                return (
                                    <Tooltip placement="topLeft" title="Delete Request">
                                        <Button type='danger' icon='delete' onClick={() => {
                                            this.deleteCustomVocalRequest(record._id);
                                        }} />
                                    </Tooltip>
                                )
                            }
                        }
                    ]}
                />

            </div>
        )
    }

}

const VocalRequests = Form.create({ name: 'vocal_requests_form' })(VocalRequestsForm);

export default VocalRequests
