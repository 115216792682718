import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { config } from './config';

let token = config.authKey ? reactLocalStorage.get('token') : null;

export const callapi = (url, enableauth = true, token) => {

    // if (enableauth) {
    //     axios.defaults.headers.common['x-api-key'] = `APIKEY ${config.authkey}`;
    // }

    token = config.authKey ? reactLocalStorage.get('token') : token;

    if (enableauth) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return axios.get(`${config.apiurl}${url}`)
        .then(function (response) {

            return response?.data;

        })
        .catch(function (error) {
            return error?.response?.data;
        });
}

export const deleteapi = (url, enableauth = true) => {
    token = config.authKey ? reactLocalStorage.get('token') : null;

    if (enableauth) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return axios.delete(`${config.apiurl}${url}`)
        .then(res => res?.data)
        .catch(err => err?.response?.data);
}

export const postapi = (url, data, enableauth = true) => {

    // if (enableauth) {
    //     axios.defaults.headers.common['x-api-key'] = `APIKEY ${config.authkey}`;
    // }
    token = config.authKey ? reactLocalStorage.get('token') : null;

    //Set bearer token
    if (enableauth) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }



    return axios.post(`${config.apiurl}${url}`, data)
        .then(function (response) {
            return response?.data;
        })
        .catch(function (error) {
            return error?.response?.data;
        });
}

export const patchapi = (url, data, enableauth = true) => {
    token = config.authKey ? reactLocalStorage.get('token') : null;

    if (enableauth) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return axios.patch(`${config.apiurl}${url}`, data)
        .then(res => res?.data)
        .catch(err => err?.response?.data);
}

// export const deleteapi = (url, enableauth = true) => {
//     if (enableauth) {
//         axios.defaults.headers.common['Authorization'] = `Bearer ${config.authKey}`;
//     }

//     return axios.delete(`${config.apiurl}${url}`)
//         .then(res => res.data)
//         .catch(err => err)
// }


export const multipart = async (endpoint, data) => {
    token = config.authKey ? reactLocalStorage.get('token') : null;

    return await fetch(`${config.apiurl}${endpoint}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: data
    })
        .then(response => response.json())
        .then((resp) => {
            return resp;
        })
        .catch(function (err) {
            console.log('Fetch Error :', err);
            return err
        });
};

