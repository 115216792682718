import React, { useEffect, useState } from "react";
import { Line } from '@reactchartjs/react-chart.js';
import { Skeleton } from "antd";

const StatsCard = (props) => {
    const [chartData, setChartData] = useState({
        labels: ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"],
        datasets: [
            {
                label: 'Today',
                fill: 'start',
                data: [4, 4, 4, 9, 20],
                borderWidth: 1.5,
                borderColor: 'rgb(23, 198, 113)',
                backgroundColor: 'rgba(23, 198, 113,0.2)',
            }
        ]
    });
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        // Uncomment the following line in order to disable the animations.
        // animation: false,
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        },
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            xAxes: [{
                gridLines: false,
                ticks: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: false,
                ticks: {
                    display: false
                }
            }]
        },
    });

    useEffect(() => {
        if (props.data) {
            setChartData({
                ...chartData,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        ...props.data
                    }
                ]
            });
        }
        if (props.options) {
            setChartOptions(props.options);
        }
    }, [props]);

    return (
        <div className="dash-card" style={props.loading ? { padding: 10 } : {}}>
            <Skeleton loading={props.loading} active={props.loading}>
                <div className="dash-card-inner">
                    <span className="card-label">{props.title}</span>
                    <div className="dash-card-title">
                        <div className="dash-card-small-data">
                            {
                                props.value ?
                                    <h6 className="card-count">{props.value}</h6> :
                                    <h6 className="card-count"></h6>
                            }
                        </div>
                        <div className="dash-card-small-data">
                            {
                                props.showParcentage ?
                                    <div style={{ color: props.color }} className={`card-percentage ${props.className ? " " + props.className : ""}`}>
                                        {props.percentage}%
                                    </div> : null
                            }
                        </div>
                    </div>
                    <Line
                        data={chartData}
                        height={53}
                        options={chartOptions}
                    />
                </div>
            </Skeleton>
        </div>
    )
}

export default StatsCard;
