import React, { Component, createRef } from 'react'
import { Button, Card, Col, Form, Input, message, Modal, Row, Table } from 'antd';
import { callapi, deleteapi, patchapi } from "../helper";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddPostFrom from "./AddPostForm"
import HTMLReactParser from 'html-react-parser';
import { Editor } from '@tinymce/tinymce-react';
import { uploadFile } from './firebase';


class WrappedNotices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingnotice: false,
            notice: [],
            noticeText: '',

            posts: [],
            addpostMdal: false,
            loadingposts: false,

            single: false,
        }
        this.editorRef = createRef(null);
    }


    componentDidMount() {
        // load essentials data
        this.getNotices();
        this.getPosts();
    }

    // load all notice data
    getNotices() {
        //getNotices
        const _this = this;
        _this.setState({
            loadingnotice: true
        });
        const notice = callapi('notices');
        notice.then((data) => {
            _this.setState({
                notice: data.data.data[0],
                noticeText: HTMLReactParser(data.data.data[0].notice),
                loadingnotice: false
            })
        })
    }

    // load all posts items
    getPosts() {
        //getPosts
        const _this = this;
        _this.setState({
            loadingposts: true
        });
        const posts = callapi('posts');
        posts.then((data) => {
            // set all posts
            _this.setState({
                posts: data.data.data,
                loadingposts: false
            })
        })
    }

    // submit the form if validation success
    handleSubmit = e => {
        const _this = this;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.notice = _this.state.noticeText;
                // values.video_id = _this.getYoutubeVideoID(values.notice_video);

                const savenotice = patchapi(`notices/${_this.state.notice._id}`, values);
                savenotice.then((data) => {
                    if (data.status === 'success') {
                        message.success('Notice successfully updated.');
                        _this.getNotices();
                    } else {
                        message.error(data.message);
                        _this.getNotices();
                    }
                });
            }
        });
    };

    // get youtube video id from URL
    getYoutubeVideoID(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }


    render() {
        // get the field decorator for the form
        const { getFieldDecorator } = this.props.form;
        const _this = this;

        // set the table columns
        const columns = [
            {
                title: 'Title',
                dataIndex: 'post_title',
                key: 'post_title',
                width: "40%"
            },
            {
                title: 'Video',
                dataIndex: 'post_featured_embed',
                key: 'post_featured_embed',
                width: "40%",
                render: (text, record) => {
                    // get video image using the video id
                    return (
                        <a
                            href={text}
                            target="_blank"
                            style={{
                                width: 60,
                                display: 'block'
                            }}
                        >
                            <img style={{ width: '100%' }}
                                src={`https://img.youtube.com/vi/${this.getYoutubeVideoID(text)}/0.jpg`} />
                        </a>
                    )
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record) => {
                    return (
                        <div>
                            <Button
                                size="small"
                                type="primary"
                                icon="edit"
                                onClick={() => {
                                    this.setState({
                                        single: record,
                                        addpostMdal: true
                                    })
                                }}
                            />
                            <Button
                                size="small"
                                type="danger"
                                icon="delete"
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Are you sure delete this post?',
                                        content: 'You cannot retrieve this post again after delete.',
                                        okText: 'Yes',
                                        okType: 'danger',
                                        cancelText: 'No',
                                        onOk() {
                                            // delete a post
                                            const deletepost = deleteapi(`posts/${record._id}`);
                                            deletepost.then((data) => {
                                                // show the error message if post was not deleted

                                                if (data.status === 'failed' || data.status === 'error') {
                                                    Modal.error({
                                                        title: "Error",
                                                        content: data.message,
                                                    });
                                                    _this.getPosts();
                                                } else {
                                                    // check if posts is successfully deleted,
                                                    Modal.success({
                                                        title: "Success!",
                                                        content: 'Post has been deleted successfully'
                                                    })
                                                    _this.getPosts();

                                                }
                                            })
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                                }}
                            />
                        </div>
                    )
                }
            },
        ];

        return (
            <div>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Card loading={this.state.loadingnotice}>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Item
                                    label='Heading'
                                >
                                    {getFieldDecorator('notice_title', {
                                        initialValue: this.state.notice.notice_title,
                                        rules: [{ required: true, message: 'Please type notice title!' }],
                                    })(
                                        <Input
                                            placeholder="Notice Title"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label='Subject'
                                >


                                    <Editor
                                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                        onInit={(evt, editor) => this.editorRef.current = editor}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter alignright alignjustify' +
                                                '| image media |' +
                                                ' | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            ,
                                            file_picker_callback: (callback, value, meta) => {

                                                /* Provide image and alt text for the image dialog */
                                                if (meta.filetype === 'image') {
                                                    // Create a new input element
                                                    const input = document.createElement('input');
                                                    input.type = 'file';
                                                    input.accept = 'image/*';

                                                    // Listen for changes to the input field
                                                    input.addEventListener('change', async function () {
                                                        const file = input.files[0];

                                                        // Make sure a file is actually selected
                                                        if (file) {
                                                            // Upload file to Firebase and get URL
                                                            const url = await uploadFile(file);
                                                            console.log('File uploaded, download URL:', url);

                                                            callback(url, { alt: file.name });
                                                        }
                                                    });

                                                    // Programmatically trigger a click event to open the file picker
                                                    input.click();
                                                }

                                                /* Provide alternative source and posted for the media dialog */
                                                if (meta.filetype === 'media') {
                                                    const input = document.createElement('input');
                                                    input.type = 'file';
                                                    input.accept = 'video/*';


                                                    // Listen for changes to the input field
                                                    input.addEventListener('change', async function () {
                                                        const file = input.files[0];

                                                        // Make sure a file is actually selected
                                                        if (file) {
                                                            // block file size more than 13MB
                                                            const size = file.size / 1024 / 1024;
                                                            const limit = 13;
                                                            if (size > limit) {
                                                                message.error('File size should not be more than 13MB');
                                                                return;
                                                            }
                                                            // Upload file to Firebase and get URL
                                                            const url = await uploadFile(file);
                                                            console.log('File uploaded, download URL:', url);

                                                            callback(url, { alt: file.name });
                                                        }
                                                    });

                                                    // Programmatically trigger a click event to open the file picker
                                                    input.click();
                                                }
                                            },
                                            image_caption: true,
                                        }}
                                        value={this.state.noticeText}
                                        onEditorChange={(val) => {
                                            this.setState({
                                                noticeText: val
                                            });
                                        }}

                                    />

                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Save notice
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Card
                            title="Manage video posts"
                            extra={<Button
                                type="primary"
                                onClick={() => {
                                    this.setState({
                                        addpostMdal: true
                                    })
                                }}
                            >Add New</Button>}
                        >
                            <Table rowKey="_id" loading={this.state.loadingposts} dataSource={this.state.posts}
                                columns={columns} />

                            <Modal
                                title={this.state.single ? "Update post" : "Create new post"}
                                visible={this.state.addpostMdal}
                                onCancel={() => {
                                    this.setState({
                                        addpostMdal: false
                                    })
                                }}
                                footer={null}
                            >
                                <AddPostFrom
                                    key={this.state.single ? this.state.single._id : 'createpost'}
                                    single={this.state.single}
                                    handleSubmit={(data) => {
                                        if (data.status === 'success') {
                                            this.setState({
                                                addpostMdal: false,
                                                single: false
                                            }, () => {
                                                this.getPosts();
                                            });
                                        }
                                    }}
                                />
                            </Modal>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}

const Notices = Form.create({ name: 'add_notice' })(WrappedNotices);


export default Notices
