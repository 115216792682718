const PlaySvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 208 208">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M104,9A95,95,0,1,1,9,104,95.11,95.11,0,0,1,104,9m0-9A104,104,0,1,0,208,104,104,104,0,0,0,104,0Z" />
                <path
                    style={{ fill: "currentColor" }}
                    d="M140.38,96.63,91.06,68.57c-5.23-3-10.56.28-10.56,6.15v58.59c0,5,3.24,7.25,6.25,7.25a8.3,8.3,0,0,0,4.3-1.32l49.53-30.32a6.76,6.76,0,0,0-.2-12.29Zm-3.12,6.87L87.73,133.81a2.91,2.91,0,0,1-.78.36,3.85,3.85,0,0,1-.09-.86V74.72a4.74,4.74,0,0,1,.11-1,2.5,2.5,0,0,1,1,.35l49.31,28.07c.61.35.79.65.84.59A2.05,2.05,0,0,1,137.26,103.5Z"
                />
            </g>
        </g>
    </svg>
);

export default PlaySvg;
