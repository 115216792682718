import React, { Component } from "react";
import {
	Button,
	Carousel,
	Checkbox,
	Col,
	Icon,
	Input,
	message,
	Modal,
	Row,
	Select,
	Skeleton,
	Tabs,
} from "antd";
import * as Tone from "tone";
import Scrollbar from "react-scrollbars-custom";
import Draggable from "./Draggable";
import Droppable from "./Droppable";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/resizable";
import { callapi, patchapi, postapi } from "../helper";
import StudioHeader from "./StudioHeader";
import toWav from "audiobuffer-to-wav";

import TimelineRuler from "./TimelineRuler";
import Annotations from "./Annotations";
import Annotation from "./Annotation";
import { reactLocalStorage } from "reactjs-localstorage";
import { config } from "../config";
import moment from "moment";
import axios from "axios";
import { PitchShifter } from "soundtouchjs";

// icon
import Wave from "./Wave";
import LicenseGenerator from "./LicenseGenerator";
import MiniPlayer from "./MiniPlayer";
import LicensePDF from "./LicensePDF";
import CsLoader from "./CsLoader";
import Sortable from "./Sortable";
import Watermark from "../assets/watermark-230.wav";
// import Watermark from "../assets/watermark_new.mp3";

import HeadPhonesSvg from "./components/HeadPhonesSvg";
import FastForwardSvg from "./components/FastForwardSvg";
import BackwardSvg from "./components/BackwardSvg";
import MusicSvg from "./components/MusicSvg";
import PlaySvg from "./components/PlaySvg";
import StopSvg from "./components/StopSvg";
import { callApi, postApi } from "../api";
import TutorialModal from "./components/modals/TutorialModal";
import AnnotationModal from "./components/modals/AnnotationModal";
import TempoChanger from "./components/TempoChanger";
import { difference, differenceBy } from "lodash";

const { TabPane } = Tabs;

const Search = Input.Search;

// custom icons

const MusicIcon = props => <Icon component={MusicSvg} {...props} />;

const BackwardIcon = props => <Icon component={BackwardSvg} {...props} />;

const FastForwardIcon = props => <Icon component={FastForwardSvg} {...props} />;

const HeadPhonesIcon = props => <Icon component={HeadPhonesSvg} {...props} />;

const PlayIcon = props => <Icon component={PlaySvg} {...props} />;

const StopIcon = props => <Icon component={StopSvg} {...props} />;

const visualResponse = (function () {
	let stateKey,
		eventKey,
		keys = {
			hidden: "visibilitychange",
			webkitHidden: "webkitvisibilitychange",
			mozHidden: "mozvisibilitychange",
			msHidden: "msvisibilitychange",
		};
	for (stateKey in keys) {
		if (stateKey in document) {
			eventKey = keys[stateKey];
			break;
		}
	}
	return function (c) {
		if (c) document.addEventListener(eventKey, c);
		return !document[stateKey];
	};
})();

class Studio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			overlapDetected: false,

			uid: 0,
			palete: 380,
			paleteLength: false,
			mix_length: false,
			paleteHeight: 0,
			step: 12,
			barStep: 8,
			zoom: 1,

			tempSaveID: false,

			// controls
			isPlaying: false,
			draggingPlayhead: false,

			// tracks
			categories: [],
			tracksList: [],
			tracksIndex: "",

			trackTitle: "",
			mix_name: "",
			playhead: 0,

			isLoadedBuffer: true,

			// Annotations
			annotations: [
				{
					name: "Intro Track",
					left: 0,
					width: 97,
					color: "#53B463",
				},
			],

			annonationVisible: false,

			draggingannotation: false,
			dragan: true,

			visibleannotationmodal: false,
			currentregion: [],
			currentregionid: false,

			thsscrollleft: 0,

			// extra
			preloadedTracks: false,
			preloadedTracksHolder: [],
			isLoadingTracks: false,
			currentcat_id: "",
			trackssearch: "",

			// license
			licenseModal: false,
			licensedownloadModal: false,
			rendering: false,

			mixLengthp: 1,
			purchasedata: [],

			loadingEssentials: false,
			// watermark
			toShow: 0,
			toShowVocals: 0,
			theSFX: 0,
			// vocals
			loadingVocals: false,
			vocals: [],

			scrollOpen: 0,
			scrollLeft: 0,

			// how to
			tutorialmodal: false,

			// ads
			ads: [],
		};

		const tempoFromStorage = reactLocalStorage.get("tempo", 145);

		this.originalTempo = 145;
		Tone.Transport.bpm.value = tempoFromStorage || this.originalTempo;
		// Tone.Transport.timeSignature = [8, 4];
		Tone.Transport.loop = true;
		Tone.Transport.loopStart = "0";
		Tone.Transport.loopEnd =
			this.state.palete * this.state.step * this.state.zoom;
		Tone.Transport.setLoopPoints(
			0,
			this.state.palete * this.state.step * this.state.zoom
		);
		// Tone.Context.latencyHint = 0.5;

		this.loopSchedule = this.loopSchedule.bind(this);
		this.addAnnotation = this.addAnnotation.bind(this);
		this.thsscrollleft = 0;

		this.changeVisibilitySensorState =
			this.changeVisibilitySensorState.bind(this);
	}

	componentWillMount() {
		this.updateDimensions();
		const isuser =
			config.user && config.user.user_type === "public" ? true : false;
		if (isuser) {
			this.setState({ uid: config.user._id });
		}
	}

	componentDidMount() {
		const _this = this;
		_this.getads();

		const tempoFromStorage = reactLocalStorage.get("tempo", 145);
		if (tempoFromStorage) {
			Tone.Transport.bpm.value = tempoFromStorage || 145;
		}

		// dynamic mix length
		let mixLength = this.getParameterByName("template");
		if (mixLength) {
			this.getSinglePlan(mixLength);
		} else {
			_this.loadAnnotations();
		}

		// apply optional css styles to the #root
		document.getElementById("root").classList.add("stutio-root");
		window.addEventListener("resize", _this.updateDimensions);
		_this.playhead = document.getElementById("playhead");

		// load initials
		_this.loopSchedule();
		_this.getcategories();
		_this.getuserVocals();

		const currentloadedtrack = reactLocalStorage.get("currenttrack");
		if (currentloadedtrack) {
			this.setState(
				{
					tempSaveID: currentloadedtrack,
				},
				() => {
					this.getCurrentSavedTrack(currentloadedtrack);
				}
			);
		}

		document.addEventListener("keydown", function (event) {
			switch (event.target.tagName.toLowerCase()) {
				case "input":
				case "textarea":
				case "select":
				case "ul":
				case "li":
					break;
				default:
					if (event.which === 32) {
						event.preventDefault();
						if (_this.state.isPlaying) {
							_this.pause();
						} else {
							_this.play();
						}
					}
					break;
			}
		});

		// Load timer
		this.formatTime();
		// load watermark
		this.loadWatermark();

		document.getElementById("sidebar-tracks").onwheel = function () {
			return false;
		};

		// zooming
		document
			.getElementById("timeline")
			.addEventListener("mousewheel", this.zoomHandler);
		document
			.getElementById("timeline")
			.addEventListener("DOMMouseScroll", this.zoomHandler);

		// add the tab visibility method to consume the memory if studio is not active
		visualResponse(function () {
			if (visualResponse()) {
				if (_this.state.isPlaying) {
					_this.play();
				}
			} else {
				Tone.Transport.pause();
			}
		});

		if (Tone.context.state !== "running") {
			Tone.context.resume();
		}

		const dontShowWelcome = reactLocalStorage.get("dontShowWelcome") === "true";

		if (!dontShowWelcome) {
			// show a modal popup with a message only for the first time
			Modal.info({
				title: "Welcome to Cheer Music Maker Studio",
				content: (
					<div>
						<p>
							The Cheer Music Maker audio watermark will be removed once
							downloaded.
						</p>

						<div>
							<Checkbox
								onChange={e =>
									reactLocalStorage.set("dontShowWelcome", e.target.checked)
								}
							>
								Don't show again
							</Checkbox>
						</div>
					</div>
				),
			});
		}

		const sample =
			"data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

		if (!_this.miniplayer) {
			_this.miniplayer = new Audio();
			_this.miniplayer.src = sample;
			_this.miniplayer.autoplay = true;
		}
	}

	//changeVisibilitySensorState
	changeVisibilitySensorState(state) {
		this.setState({ annonationVisible: state });
	}

	// loading initial annotations
	loadAnnotations() {
		let theprecissionValue = this.w2s(this.state.step * this.state.barStep);
		let theAccuateLength = this.s2w(
			(this.state.palete / 60) * (this.state.step * 2) * this.state.zoom
		);

		let bigBarStep = this.state.step * this.state.barStep;
		let endClipPosition = theAccuateLength - theprecissionValue;
		// calculatedMixLength * 2.48 * this.state.step * this.state.zoom

		let endFinalClipPosition = endClipPosition - (endClipPosition % bigBarStep);

		// create new annotation
		let endAnnotation = {
			name: "Ending Track",
			left: endFinalClipPosition,
			width: 97,
			color: "#ff0000",
		};
		this.setState({
			annotations: this.state.annotations.concat(endAnnotation),
		});
	}

	// get single plan
	getSinglePlan(planid) {
		//getplans
		const _this = this;
		_this.setState({
			loading: true,
		});
		// call the api to get the corresponds template data
		const plans = callapi(`plans/${planid}`);
		plans.then(resp => {
			if (resp.status === "success") {
				// get template mix length value
				let mixlengthVal = resp.data.data.music_duration;
				// calculate the mix length from minute to second
				let mixlength = String(mixlengthVal).split(".");
				let mixlengthMinstoSec = parseInt(mixlength[0]) * 60;
				let mixlengthSec = parseInt(mixlength[1]) * 10;
				let calculatedMixLength = mixlengthSec
					? mixlengthMinstoSec + mixlengthSec
					: mixlengthMinstoSec;
				// calculate the length of the template
				let theprecissionValue = _this.w2s(
					_this.state.step * _this.state.barStep + _this.state.barStep
				);
				let theAccuateLength = _this.s2w(calculatedMixLength);
				// calculatedMixLength * 2.48 * this.state.step * this.state.zoom

				let bigBarStep = this.state.step * this.state.barStep;
				let endClipPosition = theAccuateLength - theprecissionValue;
				// calculatedMixLength * 2.48 * this.state.step * this.state.zoom

				let endFinalClipPosition =
					endClipPosition - (endClipPosition % bigBarStep);

				// create new annotation
				let endAnnotation = {
					name: "Ending Track",
					left: endFinalClipPosition,
					width: 97,
					color: "#ff0000",
				};

				// set the states for new pallete length, mix length and initial annotations
				this.setState(
					{
						palete: theAccuateLength / _this.state.step + _this.state.barStep,
						paleteLength: resp?.data?.data?._id,
						mix_length:
							resp.data?.data?.music_duration +
							"-" +
							resp.data?.data?.plan_price,
						annotations: _this.state.annotations.concat(endAnnotation),
					},
					() => {
						Tone.Transport.loopEnd =
							theAccuateLength + _this.state.step * _this.state.barStep;
					}
				);
			} else {
				Modal.error({
					content: "Sorry mix template not found loading defaults.",
				});
			}
		});
	}

	// get params from url
	getParameterByName(name) {
		// get the url params
		let url = this.props.location.search;
		// replace unnecessary parts from url params
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return "";
		// return the url parts
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	zoomHandler = e => {
		// set the min/max zoom level
		let minzoom = 1;
		let maxzoom = 3;

		if (e.ctrlKey) {
			e.preventDefault();
			// calculate the mouse wheel data if zoom out or zoom in
			var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));

			if (delta === 1) {
				this.zoomInpallete();
			} else {
				this.zoomOutPallete();
			}
		}
	};

	// Zoom in the mix pallete
	zoomInpallete() {
		// set the min/max zoom level
		let minzoom = 1;
		let maxzoom = 3;
		if (this.state.zoom < maxzoom) {
			Tone.Transport.pause();
			let thzoomval = this.state.zoom + 0.5;
			this.setState(
				{
					zoom: thzoomval > maxzoom ? maxzoom : thzoomval,
				},
				() => {
					//set the loop option for Tonejs for zoom in or zoom out
					Tone.Transport.loopEnd =
						this.state.palete * this.state.step * this.state.zoom;
					if (this.state.isPlaying) {
						Tone.Transport.start("+0.0");
					}
				}
			);
		}
	}

	zoomOutPallete() {
		let minzoom = 1;
		if (this.state.zoom > minzoom) {
			Tone.Transport.pause();
			let thzoomval = this.state.zoom - 1;
			this.setState(
				{
					zoom: thzoomval < minzoom ? minzoom : thzoomval,
				},
				() => {
					Tone.Transport.loopEnd =
						(this.state.palete * this.state.step) / this.state.zoom.toFixed(1);
					if (this.state.isPlaying) {
						Tone.Transport.start("+0.0");
					}
				}
			);
		}
	}

	toShow() {
		this.setState({ toShow: 1 });
	}

	toShowVocals() {
		this.setState({ toShowVocals: 1 });
	}

	theSFX(count) {
		this.setState({ theSFX: count });
	}

	loadWatermark() {
		const _this = this;
		_this.setState({ loadingEssentials: true, isLoadedBuffer: false });
		const wmt = Watermark;

		const addwmt = new Tone.GrainPlayer({
			url: wmt,
			overlap: 0.5,
			loop: true,
			volume: -9,
			onload: function () {
				_this.setState({ isLoadedBuffer: true });
			},
			onerror: err => console.error(err),
		});

		// set the watermark volume to a measurable decibel that users can hear the mix
		// addwmt.volume.value = -15;

		const loadedPromise = new Promise(function (done) {
			Tone.Buffer.loaded().then(function () {
				done();
			});
		});
		loadedPromise.then(() => {
			addwmt.toDestination().sync().start(0);

			setTimeout(() => {
				_this.setState({ loadingEssentials: false });
			}, 500);
		});
	}

	getuserVocals() {
		this.setState({ loadingVocals: true });

		const getVocals = callApi(
			`vocalrequests/${config.user._id}/vocalsrequests`
		);

		getVocals.then(resp => {
			if (resp.status === "success") {
				this.setState({
					loadingVocals: false,
					vocals: resp.data.vocalRequests,
				});
			} else {
				this.setState({ loadingVocals: false });
			}
		});
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.reqanim);
	}

	// load current saved track
	getCurrentSavedTrack(id) {
		const _this = this;
		const ct = callApi(`savedtracks/${id}`);

		ct.then(data => {
			if (data.status === "success") {
				let maindta = data.data.data.track_data;
				// check if logged in user is an admin
				const isadmin =
					config.user && config.user.user_type === "admin" ? true : false;

				_this.setState(
					{
						uid: isadmin ? data.data.data.uid : config.user._id,
						tempSaveID: data.data.data._id,
						trackTitle: data.data.data.track_name,
						mix_name: data.data.data.mix_name,
						tempo: parseInt(maindta.tempo),
						annotations: maindta.annotations,
					},
					() => {
						maindta.tracks.map((item, index) => {
							_this.addTrack(item);
						});
					}
				);
			} else {
				_this.setState({ tempSaveID: false });
				reactLocalStorage.set("currenttrack", null);
			}
		});
	}

	// categories, memoize
	getcategories() {
		this.setState({ isLoadingcats: true });
		const cats = callApi("categories");
		cats.then(data => {
			this.setState({
				categories: data.data.data,
				isLoadingcats: false,
			});
		});
	}

	// categories
	getads() {
		const cats = callApi("ads");
		cats.then(data => {
			this.setState({ ads: data.data.data });
		});
	}

	handleChangeCategory = e => {
		this.getTracksbycategory(e);
		if (this.state.trackssearch.length > 0) {
			this.searchtracksbycat(e, this.state.trackssearch);
		}
	};

	//! get tracks by category
	getTracksbycategory(catid) {
		if (!catid) {
			return;
		}

		this.setState({
			isLoadingTracks: true,
			currentcat_id: catid,
		});
		const tracks = callApi(`tracks/bycategory/${catid}`);
		tracks.then(data => {
			this.setState({
				preloadedTracks: data.data.tracks,
				preloadedTracksHolder: [],
				isLoadingTracks: false,
			});
		});
	}

	searchtracksbycat(catid, search) {
		const _this = this;
		_this.setState({ isLoadingTracks: true });
		const trackssearch = postApi("tracks/bysearch", {
			catId: catid,
			search: search,
		});
		trackssearch.then(data => {
			_this.setState({
				preloadedTracks: data.data.tracks,
				isLoadingTracks: false,
			});
		});
	}

	updateDimensions = () => {
		this.setState({ paleteHeight: window.innerHeight - 110 });
	};

	toggleMusicBar() {
		$("#musicBar").toggleClass("active");
		if (this.state.scrollOpen === 0) {
			this.setState({ scrollOpen: 1 });
		} else {
			this.setState({ scrollOpen: 0 });
		}
	}

	hideMusicBar() {
		$("#musicBar").removeClass("active");
		this.setState({ scrollOpen: 0 });
	}

	// create a new Annotation
	addAnnotation = (left, width) => {
		this.setState({
			visibleannotationmodal: true,
			currentregion: {
				name: "",
				left: left,
				width: width,
				color: "#000",
			},
		});
	};

	handleaddAnnotation = () => {
		this.setState({
			visibleannotationmodal: false,
			currentregionid: false,
			annotations: this.state.annotations.concat(this.state.currentregion),
		});
		this.loopSchedule();
	};

	updateannotation = id => {
		cancelAnimationFrame(this.reqanim);
		setTimeout(() => {
			this.setState(
				{
					currentregionid: id,
					currentregion: this.state.annotations[id],
				},
				() => {
					this.setState({
						visibleannotationmodal: true,
					});
				}
			);
		}, 500);
	};

	// update annotation
	handleupdateAnnotation = () => {
		this.setState({ visibleannotationmodal: false }, () => {
			// delete the previous annotation
			delete this.state.annotations[this.state.currentregionid];
			// update the current annotation
			this.setState({
				annotations: this.state.annotations.concat(this.state.currentregion),
			});
			this.loopSchedule();
		});
	};

	// delete annotation
	deleteAnnotation = () => {
		let ann = this.state.annotations;
		delete ann[this.state.currentregionid];
		this.setState(
			{
				annotations: ann,
				visibleannotationmodal: false,
			},
			() => {
				message.info("Annotation deleted.");
			}
		);
		this.loopSchedule();
	};

	updateAnnotaeionWidth = (id, width) => {
		this.state.annotations[id].width = width / this.state.zoom;
	};

	loopSchedule = () => {
		const progress =
			(Tone.Transport.seconds / Tone.Transport.loopEnd) *
			(this.state.step * 2) *
			(Tone.Transport.bpm.value / 120) *
			this.state.zoom;

		const playbackRate = Tone.Transport.bpm.value / this.originalTempo;
		const adjustedPosition = progress / playbackRate;

		if (!this.state.draggingPlayhead) {
			this.playhead.style.left = (progress * 100).toFixed(2) + "%";
		}
		this.reqanim = requestAnimationFrame(this.loopSchedule);
	};

	// seconds to width px
	s2w(seconds) {
		const tracklength =
			(seconds / 120) * Tone.Transport.bpm.value * (this.state.step * 2) +
			1 * this.state.zoom;

		const playbackRate = Tone.Transport.bpm.value / this.originalTempo;

		const adjustedPosition = tracklength / playbackRate;

		return Math.round(adjustedPosition);
	}

	// width px to seconds
	w2s(seconds) {
		let tracklength =
			seconds / 120 / Tone.Transport.bpm.value / (this.state.step * 2) +
			1 / this.state.zoom;

		return Math.round(tracklength);
	}

	// create a new track
	addTrack(track, isnew = false, position = "none", showLoader = true) {
		// if the tracklist is empty and this is the first track,
		// make sure this track is an Intro track, otherwise return and show a notification
		if (this.state.tracksList.length === 0 && isnew) {
			if (!track.name.toLowerCase().includes("intro")) {
				message.info("First track must be an Intro track.");
				return;
			}
		}

		// get the current mix length
		let trackPaletteInitialWidth =
			this.state.palete * this.state.step * this.state.zoom;
		let bigBarStep = this.state.step * this.state.barStep;
		let trackPaletteWidth =
			trackPaletteInitialWidth - (trackPaletteInitialWidth % bigBarStep);

		// get the length of all tracks in the list and the current track
		let totalTrackLengths = this.state.tracksList.reduce((acc, cur) => {
			// find sfx tracks and ignore them
			if (cur.name.includes("SFX")) {
				return acc;
			}
			return acc + cur.width * this.state.zoom;
		}, 0);

		// if total track length is greater than the mix length, return and show a notification
		if (
			totalTrackLengths > trackPaletteWidth &&
			!track.name.includes("SFX") &&
			!track.url.includes("custom_vocals")
		) {
			Modal.info({
				title: "Mix length exceeded",
				content: (
					<div>
						<p>
							The total length of all tracks in the mix is exceeding the mix
							length.
						</p>
						<p>
							Please remove some tracks or adjust the mix length, and end with
							an Ending Track
						</p>
					</div>
				),
			});
			return;
		}

		if (track !== null) {
			this.setState({ loadingEssentials: showLoader, isLoadedBuffer: false });
			if (position !== "none") track.position = position;

			const playbackRate = Tone.Transport.bpm.value / this.originalTempo;

			let trackposition =
				track.position > 0
					? track.position /
					  (this.state.step * 2) /
					  (Tone.Transport.bpm.value / 120) /
					  this.state.zoom
					: 0;

			// Adjust the track position based on the playback rate
			// const adjustedPosition = trackposition * playbackRate;

			if (isnew) {
				this.thsscrollleft = track.position - 250;
			}

			var addnewtrack = new Tone.GrainPlayer({
				url: track.url,
				onload: () => {
					this.setState({ isLoadedBuffer: true });
				},
				playbackRate: playbackRate,
			});

			var loadedPromise = new Promise(function (done) {
				Tone.Buffer.loaded().then(function () {
					done();
				});
			});

			loadedPromise.then(() => {
				addnewtrack.toDestination().sync().start(`${trackposition}`, 0);

				this.draw(
					addnewtrack.buffer,
					track,
					addnewtrack,
					0,
					track.position,
					isnew
				);
			});
		}
	}

	// draw the track on mix pallete
	draw(buffer, item, track, yOffset, startTime, isnew) {
		if (item.isDelete !== 1) {
			let duration = buffer.duration;
			const _this = this;
			let imgPixels = _this.s2w(duration);

			_this.setState({
				loadingEssentials: false,
				tracksList: [
					..._this.state.tracksList,
					{
						...item,
						track: track,
						width: imgPixels,
						position: startTime,
						loop: false,
						bgcolor: isnew ? _this.getBGColor() : item.bgcolor,
					},
				],
			});
		}
	}

	removeItem(index) {
		this.setState(
			{
				timerval: "00:00",
				isPlaying: false,
				playhead: 0,
			},
			() => {
				let thtrackList = this.state.tracksList;
				let currentTrack = thtrackList[index].track;
				currentTrack.unsync();
				thtrackList[index].isDelete = 1;

				const filteredItems = this.state.tracksList.filter(
					item => item.isDelete !== 1
				);

				this.setState({ tracksList: filteredItems });
			}
		);
	}

	// After the tempo change, re-add all the tracks
	reAddAllTracks() {
		this.setState(
			{
				timerval: "00:00",
				isPlaying: false,
				playhead: 0,
			},
			() => {
				let thtrackList = this.state.tracksList;
				thtrackList.map((item, index) => {
					let currentTrack = thtrackList[index].track;
					currentTrack.unsync();
				});

				this.setState({ tracksList: [] }, () => {
					thtrackList.map((item, index) => {
						this.addTrack(item, false, "none", false);
					});
				});
			}
		);
	}

	getBGColor() {
		return (
			"rgb(" +
			Math.floor(Math.random() * 256) +
			"," +
			Math.floor(Math.random() * 256) +
			"," +
			Math.floor(Math.random() * 256) +
			", " +
			0.6 +
			")"
		);
	}

	// format the timer clock
	formatTime() {
		const timerCont = document.getElementById("timer");
		setInterval(() => {
			let t = Tone.Transport.seconds;
			var msec = Math.floor((t % 1) * 100);
			var seconds = Math.floor(t % 60);
			var minutes = Math.floor((t / 60) % 60);

			if (msec < 10) {
				msec = "0" + msec;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}

			const timetowidth = this.s2w(t);
			const totalmixlength =
				this.state.palete * this.state.step * this.state.zoom;

			if (timetowidth > totalmixlength) {
				this.stop();
				this.thsscrollleft = 0;
			}

			if (this.state.isPlaying) {
				this.scrollLeftPos(timetowidth);
			}

			//t = minutes+':'+seconds+':'+msec;
			// t = minutes+':'+seconds;
			timerCont.innerHTML =
				"Mix Time: " +
				(minutes > 0 ? minutes : "00") +
				":" +
				(seconds > 0 ? seconds : "00");
		}, 1000);
	}

	scrollLeftPos(playhead) {
		const _this = this;

		if (
			_this.state.scrollOpen &&
			!_this.state.isPlaying &&
			_this.state.annonationVisible
		) {
			return _this.state.scrollLeft + 400;
		} else if (_this.state.isPlaying && playhead) {
			_this.setState({
				scrollLeft: playhead - 500 < 0 ? 0 : playhead - 500,
			});
			return _this.state.scrollLeft;
		} else {
			return _this.state.scrollLeft;
		}
	}

	play() {
		this.setState({ isPlaying: true }, () => {
			if (Tone.context.state !== "running") {
				Tone.context.resume();
			}
			Tone.Transport.start("+0.0");
			this.hideMusicBar();
		});
	}

	pause() {
		this.setState({ isPlaying: false }, () => {
			Tone.Transport.pause();
		});
	}

	stop() {
		this.setState(
			{
				isPlaying: false,
				playhead: 0,
				scrollLeft: this.state.isPlaying ? 0 : this.state.scrollLeft,
			},
			() => {
				Tone.Transport.stop("+0.0");
				let playhead = document.getElementById("playhead");
				playhead.style.left = 0;
			}
		);
	}

	mute(id) {
		this.state.tracksList[id].track.mute = !this.state.tracksList[id].mute;
		this.state.tracksList[id].mute = !this.state.tracksList[id].mute;

		this.setState({
			tracksList: this.state.tracksList,
		});
	}

	loop(id) {
		this.state.tracksList[id].track.loop = !this.state.tracksList[id].loop;
		this.state.tracksList[id].loop = !this.state.tracksList[id].loop;

		this.setState({ tracksList: this.state.tracksList });
	}

	savename = name => {
		this.setState({ trackTitle: name });
	};

	savemix_name = name => {
		this.setState({ mix_name: name });
	};

	onChangetrackName(index, event) {
		this.state.tracksList[index].name = event.target.value;
	}

	cleanobject(obj) {
		const data = obj.filter(function (x) {
			return x !== null && x !== undefined;
		});
		return data;
	}

	// db essentials
	saveTemp = () => {
		const _this = this;
		if (this.state.trackTitle.length > 0) {
			// check if the tracklist has an ending track at any index
			if (
				this.state.tracksList.length > 0 &&
				this.state.tracksList.find(x => x.name.includes("ending"))
			) {
				Modal.warning({
					title: "Warning !!!",
					content: (
						<div>
							<p>Please add an ending track to your mix</p>
							<p>A track must end With an Ending Track !!!</p>
						</div>
					),
				});
				return;
			}

			let tracks = [];

			// loop through the track list to prepare save item
			this.state.tracksList.map((item, index) => {
				if (item.isDelete !== 1) {
					tracks[index] = {
						containerId: item.containerId,
						id: item.id,
						name: item.name,
						url: item.url,
						mute: false,
						width: item.width,
						position: item.position,
						bgcolor: item.bgcolor,
					};
				}
			});

			let thdata = {
				paleteLength: this.state.paleteLength,
				tracks: tracks,
				tempo: Tone.Transport.bpm.value,
				annotations: this.cleanobject(this.state.annotations),
			};

			// check if mix has min 1 track
			if (this.state.tracksList.length > 0) {
				const saveTracks = postapi("savedtracks", {
					track_name: this.state.trackTitle,
					mix_name: this.state.mix_name,
					uid: this.state.uid,
					track_data: thdata,
				});
				saveTracks.then(data => {
					if (data.status === "success") {
						_this.setState({ tempSaveID: data.data.data._id }, () => {
							reactLocalStorage.set("currenttrack", data.data.data._id);
							reactLocalStorage.set(
								"currenttrackdownloaded",
								data.data.data.downloadCount
							);
						});
						message.success("Track successfully saved.");
					} else {
						message.error("Cannot save track now.");
					}
				});
			} else {
				message.info("No data found to save. Please add track before save.");
			}
		} else {
			message.info("Please type program & team name before save track.");
		}
	};

	// update temp mix tracks
	updateTemp = () => {
		const _this = this;

		if (this.state.trackTitle.length > 0) {
			// check if the last item in the tracklist is an ending track
			if (
				this.state.tracksList.length > 0 &&
				this.state.tracksList.find(x => x.name.includes("ending"))
			) {
				Modal.warning({
					title: "Warning !!!",
					content: (
						<div>
							<p>Please add an ending track to your mix</p>
							<p>A Mix must end With an Ending Track !!!</p>
						</div>
					),
				});
			}

			let tracks = [];

			this.state.tracksList.map((item, index) => {
				if (item.isDelete !== 1) {
					tracks[index] = {
						containerId: item.containerId,
						id: item.id,
						name: item.name,
						url: item.url,
						mute: false,
						width: item.width,
						position: item.position,
						bgcolor: item.bgcolor,
					};
				}
			});

			let thdata = {
				paleteLength: this.state.paleteLength,
				tracks: tracks,
				tempo: Tone.Transport.bpm.value,
				annotations: this.cleanobject(this.state.annotations),
			};

			// check if mix has min 1 track
			if (this.state.tracksList.length > 0) {
				// need to update st_id as int saved track id. its coming as an array [st_id][data]
				const saveTracks = patchapi(`savedtracks/${_this.state.tempSaveID}`, {
					track_name: this.state.trackTitle,
					mix_name: this.state.mix_name,
					st_id: _this.state.tempSaveID,
					uid: this.state.uid,
					track_data: thdata,
				});
				saveTracks.then(data => {
					if (data.status === "success") {
						_this.setState({
							trackTitle: data.data.data.track_name,
						});
						message.success("Track successfully updated.");
						reactLocalStorage.set("currenttrack", data.data.data._id);
						reactLocalStorage.set(
							"currenttrackdownloaded",
							data.data.data.downloadCount
						);
					} else {
						message.error("Cannot update track now.");
					}
				});
			} else {
				message.info("No data found to save. Please add track before save.");
			}
		} else {
			message.info("Please type program & team name before save track.");
		}
	};

	renderTracks(totalmixlength) {
		let mixlength = String(totalmixlength).split(".");
		let mixlengthMinstoSec = parseInt(mixlength[0]) * 60;
		let mixlengthSec = parseInt(mixlength[1]) * 10;
		let calculatedMixLength = mixlengthSec
			? mixlengthMinstoSec + mixlengthSec
			: mixlengthMinstoSec;

		if (totalmixlength) {
			mixlength = calculatedMixLength + 5;
		} else {
			mixlength = false;
		}

		const _this = this;
		_this.setState({ rendering: true });

		// let samplerate = 44100;
		let samplerate = Tone.context.sampleRate;
		if (_this.state.tracksList.length > 0) {
			setTimeout(() => {
				let totallength = samplerate * mixlength; // calculate length by plan limitation
				const playbackRate = Tone.Transport.bpm.value / _this.originalTempo;

				let offlineCtx = new OfflineAudioContext(2, totallength, samplerate);
				const gainNode = offlineCtx.createGain();

				_this.state.tracksList.map((item, index) => {
					if (item.isDelete !== 1) {
						const source = offlineCtx.createBufferSource();
						const buffer = item.track.buffer.get();
						source.playbackRate.value = playbackRate;
						source.buffer = buffer;

						const shifter = new PitchShifter(offlineCtx, buffer, 1024);
						shifter.tempo = playbackRate;
						shifter.pitch = 1;
						shifter.connect(gainNode);

						source.connect(offlineCtx.destination);

						let posleft = parseInt(item.position % 12);
						let thuippos = item.position - posleft;
						let trackposition =
							item.position > 0
								? thuippos /
								  (this.state.step * 2) /
								  (Tone.Transport.bpm.value / 120) /
								  this.state.zoom
								: 0;

						const adjustedPosition = trackposition / playbackRate;

						if (adjustedPosition > 0) {
							source.start(adjustedPosition);
						} else {
							source.start(0);
						}
					}
				});

				offlineCtx
					.startRendering()
					.then(function (renderedBuffer) {
						console.info("Rendering completed successfully");
						// the music length calculated from the plan. user cannot download the track larger than this size which is defined on tha plan
						_this.make_download(renderedBuffer, offlineCtx.length);
					})
					.catch(function (err) {
						_this.setState({ rendering: false });
						console.error("Rendering failed: " + err);
						// Note: The promise should reject when startRendering is called a second time on an OfflineAudioContext
					});
			}, 2000);
		} else {
			_this.setState({ rendering: false });
			message.info("Please add at least one track to render.");
		}
	}

	async make_download(abuffer, total_samples) {
		const _this = this;
		const abuff = toWav(abuffer);
		const aBlob = new Blob([abuff], { type: "audio/wav" });

		let new_file = URL.createObjectURL(aBlob);

		let blob = await fetch(new_file)
			.then(r => r.blob())
			.catch(err => {
				message.error("Sorry! There is an internet issue");
				console.error("bloberr:", err);
				return;
			});
		const frdata = new FormData();
		frdata.append("file", blob, "renderedfile.wav");
		frdata.append("track", _this.state.tempSaveID);
		axios({
			method: "POST",
			url: config.apiurl + "savedtracks/uploadtrack",
			data: frdata,
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + config.authKey,
			},
		})
			.then(function (response) {
				const resdata = response.data;
				_this.setState({ rendering: false });
				if (resdata.status === "success") {
					_this.savepurchasedata(resdata.data.filePath);
					reactLocalStorage.set(
						"currenttrackdownloaded",
						resdata.data.downloadCount
					);
				} else {
					message.error("Cannot save rendered audio.");
				}
			})
			.catch(function (response) {
				//handle error
				console.error(response);
			});
	}

	// save the rendered track for email
	savepurchasedata(renderedfile) {
		const _this = this;
		_this.state.purchasedata.renderedfile = renderedfile;
		_this.state.purchasedata.validatedtill = moment(
			_this.state.purchasedata.validatedtill
		).toISOString();
		_this.state.purchasedata.uid = config.user._id;
		_this.state.purchasedata.mix_id = _this.state.tempSaveID;
		_this.state.purchasedata.phone = config.user.phone_number;
		_this.state.purchasedata.project_name = _this.state.trackTitle;

		const savepurchase = postapi(
			"transactions/new/purchase",
			_this.state.purchasedata
		);
		savepurchase.then(pdata => {
			if (pdata.status === "success") {
				_this.state.purchasedata.pur_id = pdata.data.data._id;
				_this.setState({
					licenseModal: false,
					licensedownloadModal: true,
				});
				message.success(
					"Purchase successfully submitted. Please download the mix and license."
				);
			} else {
				message.error("Cannot save purchase now please try again.");
			}
		});
	}

	// convert
	bufferToWave(abuffer, len) {
		var numOfChan = abuffer.numberOfChannels,
			length = len * numOfChan * 2 + 44,
			buffer = new ArrayBuffer(length),
			view = new DataView(buffer),
			channels = [],
			i,
			sample,
			offset = 0,
			pos = 0;

		// write WAVE header
		setUint32(0x46464952); // "RIFF"
		setUint32(length - 8); // file length - 8
		setUint32(0x45564157); // "WAVE"

		setUint32(0x20746d66); // "fmt " chunk
		setUint32(16); // length = 16
		setUint16(1); // PCM (uncompressed)
		setUint16(numOfChan);
		setUint32(abuffer.sampleRate);
		setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
		setUint16(numOfChan * 2); // block-align
		setUint16(16); // 16-bit (hardcoded in this demo)

		setUint32(0x61746164); // "data" - chunk
		setUint32(length - pos - 4); // chunk length

		// write interleaved data
		for (i = 0; i < abuffer.numberOfChannels; i++)
			channels.push(abuffer.getChannelData(i));

		while (pos < length) {
			for (i = 0; i < numOfChan; i++) {
				// interleave channels
				sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
				sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
				view.setInt16(pos, sample, true); // write 16-bit sample
				pos += 2;
			}
			offset++; // next source sample
		}

		// create Blob
		return new Blob([buffer], { type: "audio/wav" });

		function setUint16(data) {
			view.setUint16(pos, data, true);
			pos += 2;
		}

		function setUint32(data) {
			view.setUint32(pos, data, true);
			pos += 4;
		}
	}

	// call the license generator
	licenseGenerator() {
		this.setState({ licenseModal: true });
	}

	setTutorialModal = state => {
		this.setState({ tutorialmodal: state });
	};

	// updateAndSaveTrackPositions = () => {
	// 	// Create a copy of the tracks list
	// 	let updatedTracks = [...this.state.tracksList];

	// 	// Sort the tracks by their position
	// 	updatedTracks.sort((a, b) => a.position - b.position);

	// 	let overlapDetected = false;

	// 	for (let i = 1; i < updatedTracks.length; i++) {
	// 		const prevTrack = updatedTracks[i - 1];
	// 		const currentTrack = updatedTracks[i];

	// 		// If the current track overlaps with the previous one, update its position
	// 		if (prevTrack.position + prevTrack.width > currentTrack.position) {
	// 			currentTrack.position = prevTrack.position + prevTrack.width;
	// 			overlapDetected = true;
	// 		}
	// 	}

	// 	// If no overlap was detected, return early
	// 	if (!overlapDetected) {
	// 		return;
	// 	}

	// 	console.log("updatedTracks", updatedTracks);
	// 	console.log("this.state.tracksList", this.state.tracksList);

	// 	// Save the updated tracks list in the state
	// 	this.setState({ tracksList: updatedTracks }, () => {
	// 		// Call the addTrack function after the state is updated
	// 		this.reAddAllTracks();
	// 		this.loopSchedule();
	// 	});
	// };
	checkOverlapAndUpdateState = () => {
		// Create a copy of the tracks list
		let updatedTracks = [...this.state.tracksList];

		// Sort the tracks by their position
		updatedTracks
			.filter(track => !track.name.includes("SFX"))
			.sort((a, b) => a.position - b.position);

		let overlapDetected = false;

		for (let i = 1; i < updatedTracks.length; i++) {
			const prevTrack = updatedTracks[i - 1];
			const currentTrack = updatedTracks[i];

			// If the current track overlaps with the previous one, update its position
			if (prevTrack.position + prevTrack.width - 1 > currentTrack.position) {
				overlapDetected = true;
				break;
			}
		}

		// If overlap was detected, update the state
		if (overlapDetected) {
			this.setState({ overlapDetected: true });
		}
	};

	checkOverlapAndUpdatePosition = () => {
		// Create a copy of the tracks list
		let updatedTracks = [...this.state.tracksList];

		// Sort the tracks by their position and filter out the SFX tracks
		updatedTracks = updatedTracks
			.filter(track => !track.name.includes("SFX"))
			.sort((a, b) => a.position - b.position);

		for (let i = 1; i < updatedTracks.length; i++) {
			const prevTrack = updatedTracks[i - 1];
			const currentTrack = updatedTracks[i];

			// If the current track overlaps with the previous one, update its position
			if (prevTrack.position + prevTrack.width - 1 > currentTrack.position) {
				currentTrack.position = prevTrack.position + prevTrack.width - 1;
			}
		}
	};

	reRenderTracks = () => {
		// Check if overlap was detected
		if (!this.state.overlapDetected) {
			return;
		}

		// check and update the track positions
		this.checkOverlapAndUpdatePosition();

		// Call the addTrack function after the state is updated
		this.reAddAllTracks();
		this.loopSchedule();

		// Reset the overlapDetected state
		this.setState({ overlapDetected: false });
	};

	render() {
		let _this = this;

		let guideB = document.querySelector("#guide-b");
		let guideA = document.querySelector("#guide-a");

		var originalIndex;
		var newIndex;
		let totalwidthforall = 0;
		let totalwidth = 0;
		let totalwidthsfx = 0;
		let totalwidthVocals = 0;
		let toAddAll = 0;
		let toAddAllCustom = 0;

		var originalTrack;
		var newTrack;

		this.gallery = $("#only-tracks");
		let trackPaletteInitialWidth =
			this.state.palete * this.state.step * this.state.zoom;
		let bigBarStep = this.state.step * this.state.barStep;
		let trackPaletteWidth =
			trackPaletteInitialWidth - (trackPaletteInitialWidth % bigBarStep);

		return (
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<TutorialModal
					visible={this.state.tutorialmodal}
					setVisible={this.setTutorialModal}
				/>

				{/*annotations*/}
				<AnnotationModal
					onCancel={() => {
						this.setState({
							currentregionid: false,
							visibleannotationmodal: false,
						});
					}}
					onDelete={this.deleteAnnotation}
					onSave={
						this.state.currentregionid
							? this.handleupdateAnnotation
							: this.handleaddAnnotation
					}
					regionData={this.state.currentregion}
					regionId={this.state.currentregionid}
					visible={this.state.visibleannotationmodal}
					setRegionData={data => {
						this.setState({
							currentregion: data,
						});
					}}
				/>

				{/*license generator*/}
				<Modal
					visible={this.state.licenseModal}
					width={700}
					onOk={() => {
						this.setState({ licenseModal: false });
					}}
					onCancel={() => {
						this.setState({ licenseModal: false });
					}}
					footer={null}
				>
					<LicenseGenerator
						key={this.state.mix_length}
						licenseModal={this.state.licenseModal}
						defaultmusiclength={this.state.mixLengthp}
						mixlength={this.state.mix_length}
						mixName={this.state.mix_name}
						trackName={this.state.trackTitle}
						loading={this.state.rendering}
						onChangeplan={length => {
							this.setState({ mixLengthp: length });
						}}
						onSubmit={values => {
							this.setState({ purchasedata: values }, () => {
								this.renderTracks(values.mix_length);
							});
						}}
					/>
				</Modal>

				<Modal
					title={`Congratulation! You just created your own cheer mix!`}
					visible={this.state.licensedownloadModal}
					width={700}
					onOk={() => {
						this.setState({
							licensedownloadModal: false,
						});
					}}
					onCancel={() => {
						this.setState({
							licensedownloadModal: false,
						});
					}}
					closable={false}
					maskClosable={false}
					footer={null}
				>
					<LicensePDF
						license={_this.state.purchasedata}
						onDone={() => {
							_this.setState(
								{
									licensedownloadModal: false,
								},
								() => {
									setTimeout(() => {
										window.location.reload();
									}, 200);
								}
							);
						}}
					/>
				</Modal>

				<StudioHeader
					tracksList={this.state.tracksList}
					isUpdating={this.state.tempSaveID}
					oldTrack={false}
					savetrack={this.saveTemp}
					updatetrack={this.updateTemp}
					savename={this.savename}
					savemix_name={this.savemix_name}
					currentname={this.state.trackTitle}
					mix_name={this.state.mix_name}
					newDocument={() => {
						Modal.confirm({
							title: "Are you sure you want to leave?",
							content: "All of your unsaved work will be lost.",
							okText: "Ok",
							cancelText: "No",
							onOk: () => {
								_this.stop();
								_this.state.tracksList.map((item, index) => {
									item.track.unsync();
								});

								_this.setState(
									{
										// tracks
										tracksList: [],
										trackTitle: "",
										mix_name: "",
									},
									() => {
										reactLocalStorage.remove("currenttrack");
										window.location = "/studio";
									}
								);
							},
						});
					}}
					download={() => {
						_this.stop();

						// _this.renderTracks("1.2");
						if (this.state.tempSaveID) {
							_this.setState({ licenseModal: true });
						} else {
							if (this.state.trackTitle.length > 0) {
								if (this.state.tracksList.length > 0) {
									_this.saveTemp();
									_this.setState({ licenseModal: true });
								} else {
									message.error(
										"Please add minimum one track to the mix for rendering."
									);
								}
							} else {
								_this.saveTemp();
							}
						}
					}}
					updatebpm={() => {
						return true;
					}}
					helpTutorial={() => {
						_this.setTutorialModal(!_this.state.tutorialmodal);
					}}
				/>
				<div className="timeline-bannner">Routine 8 Count Timeline.</div>
				<div id="timeline">
					<div
						className="loaderessentials"
						style={{
							display: _this.state.loadingEssentials ? "flex" : "none",
							// display: "flex",
						}}
					>
						<CsLoader width={80} height={80} />
					</div>

					<div className="sidebar">
						<div className="sidebar-actions">
							<h2 id="timer"></h2>
						</div>
						<div id="sidebar-tracks" className="sidebar-tracks">
							<a
								className="add-new-btn"
								style={{ top: _this.state.tracksList.length * 60 }}
								onClick={() => {
									this.toggleMusicBar();
								}}
							>
								<MusicIcon style={{ fontSize: 18 }} />
								<span>Add Cheer Music Track</span>
							</a>
							<Sortable
								id="list1"
								init={{
									axis: "y",
									placeholder: "track-placeholder",
									start: (e, ui) => {
										originalIndex = ui.item.index();
										originalTrack = this.gallery[0].children[originalIndex];
									},
									update: (e, ui) => {
										newIndex = ui.item.index();
										newTrack = this.gallery[0].children[newIndex];

										if (originalIndex < newIndex) {
											$(newTrack).after(originalTrack);
										} else {
											$(newTrack).before(originalTrack);
										}

										let arr = $(e.target).sortable("toArray").toString();

										_this.setState({
											tracksIndex: arr,
										});
									},
								}}
							>
								{this.state.tracksList
									.sort((a, b) => a.position - b.position)
									.map((item, index) => {
										if (item.name && item.name.includes("SFX")) {
											if (toAddAll === 0) {
												toAddAll = 1;
											}
											let styleIs;
											let theName;
											if (item.name && item.name.includes("SFX")) {
												if (item.isDelete !== 1) {
													styleIs = { backgroundColor: "#ec008c" };
												} else {
													styleIs = { border: "3px solid #d2d2d2" };
												}

												theName = item.name;
											} else if (
												item.url &&
												item.url.includes("custom_vocals")
											) {
												styleIs = { backgroundColor: "#096dd9" };
												theName = item.name;
											} else if (item.name) {
												styleIs = { borderRight: "8px solid " + item.bgcolor };
												theName = item.name;
											}

											if (item.isDelete !== 1) {
												return (
													<div
														key={index}
														id={index}
														className="list1 channel-header"
														style={styleIs}
													>
														<div>
															<Button
																type="danger"
																size="small"
																className="trackremovebtn"
																onClick={() => {
																	this.removeItem(index);
																}}
																icon="close"
															/>
															<div className="track-controls">
																<Input
																	style={{ width: "90%" }}
																	size="small"
																	defaultValue={theName}
																	onChange={event =>
																		this.onChangetrackName(index, event)
																	}
																/>
															</div>
														</div>
													</div>
												);
											}
										}
									})}

								{this.state.tracksList.map((item, index) => {
									if (item.name && item.url.includes("custom_vocals")) {
										if (toAddAllCustom === 0) {
											toAddAllCustom = 1;
										}
										let styleIs;
										let theName;
										if (item.name && item.name.includes("SFX")) {
											styleIs = { backgroundColor: "#ec008c" };

											theName = item.name;
										} else if (item.url && item.url.includes("custom_vocals")) {
											if (item.isDelete !== 1) {
												styleIs = { backgroundColor: "#096dd9" };
											} else {
												styleIs = { border: "3px solid #d2d2d2" };
											}
											theName = item.name;
										} else if (item.name) {
											styleIs = { borderRight: "8px solid " + item.bgcolor };
											theName = item.name;
										}
										if (item.isDelete !== 1) {
											return (
												<div
													key={index}
													id={index}
													className="list1 channel-header"
													style={styleIs}
												>
													{item.isDelete !== 1 && (
														<div>
															<Button
																type="danger"
																size="small"
																className="trackremovebtn"
																onClick={() => {
																	this.removeItem(index);
																}}
																icon="close"
															/>
															<div className="track-controls">
																<Input
																	style={{
																		width: "90%",
																	}}
																	size="small"
																	defaultValue={theName}
																	onChange={event =>
																		this.onChangetrackName(index, event)
																	}
																/>
															</div>
														</div>
													)}
												</div>
											);
										}
									}
								})}

								{this.state.tracksList.map((item, index) => {
									if (
										item.name &&
										!item.name.includes("SFX") &&
										!item.url.includes("custom_vocals")
									) {
										let styleIs;
										let theName;
										if (item.name && item.name.includes("SFX")) {
											styleIs = {
												backgroundColor: "#ec008c",
											};

											theName = item.name;
										} else if (item.url && item.url.includes("custom_vocals")) {
											styleIs = {
												backgroundColor: "#096dd9",
											};
											theName = item.name;
										} else if (item.name) {
											if (item.isDelete !== 1) {
												styleIs = {
													border: "3px solid " + item.bgcolor,
												};
											} else {
												styleIs = {
													border: "3px solid #d2d2d2",
												};
											}

											theName = item.name;
										}
										if (item.isDelete !== 1) {
											return (
												<div
													key={index}
													id={index}
													className="list1 channel-header"
													style={styleIs}
												>
													{item.isDelete !== 1 && (
														<div>
															<Button
																type="danger"
																size="small"
																className="trackremovebtn"
																onClick={() => {
																	this.removeItem(index);
																}}
																icon="close"
															/>
															<div className="track-controls">
																<Input
																	style={{
																		width: "90%",
																	}}
																	size="small"
																	defaultValue={theName}
																	onChange={event =>
																		this.onChangetrackName(index, event)
																	}
																/>
															</div>
														</div>
													)}
												</div>
											);
										}
									}
								})}
							</Sortable>
						</div>
					</div>
					<div id="timeline-content" className="timeline-content">
						{!this.state.tracksList.length > 0 && (
							<div className="dragging-message">
								<h4>Drag Cheer Music Tracks Here</h4>
							</div>
						)}
						<Scrollbar
							scrollLeft={_this.scrollLeftPos()}
							noScrollY={true}
							style={{
								height: "100%",
								scrollBehavior: "smooth",
							}}
							onScroll={e => {
								_this.thsscrollleft = e.scrollLeft;
								_this.state.scrollLeft = e.scrollLeft;
							}}
							className={this.state.scrollOpen === 1 ? "scroll-open" : ""}
						>
							<div
								id="bit-ruler"
								className="bit-ruler"
								style={{ width: trackPaletteWidth }}
							>
								<Draggable
									id="playhead"
									click={() => {}}
									ondblclick={() => {}}
									init={{
										scroll: true,
										axis: "x",
										containment: "parent",
										start: () => {
											Tone.Transport.pause();
										},
										drag: (_, playhead) => {
											const left = playhead.position.left;

											const seconds =
												left /
												(_this.state.step * 2) /
												(Tone.Transport.bpm.value / 120) /
												this.state.zoom;

											Tone.Transport.seconds = seconds;
										},
										stop: (_, playhead) => {
											const seconds =
												playhead.position.left /
												(_this.state.step * 2) /
												(Tone.Transport.bpm.value / 120) /
												this.state.zoom;

											Tone.Transport.seconds = seconds;
											if (this.state.isPlaying) {
												Tone.Transport.start("+0.0");
											}
										},
									}}
									className="mix-editor-playhead icon-playhead"
								>
									<div className="head">
										<svg width="17" height="20">
											<polygon
												points="0,0 17,0 17,10 8.5,20 0,10"
												style={{
													fill: "#c90077",
													strokeWidth: 0,
												}}
											/>
										</svg>
									</div>
								</Draggable>
								<TimelineRuler
									ifclicked={e => {
										let timelineoffset =
											document.getElementById("timeline-content").offsetLeft;
										let thepos = e.x + _this.thsscrollleft - timelineoffset;

										const seconds =
											thepos /
											(_this.state.step * 2) /
											(Tone.Transport.bpm.value / 120) /
											_this.state.zoom;

										Tone.Transport.seconds = seconds;
										if (_this.state.isPlaying) {
											Tone.Transport.start("+0.0");
										}
									}}
									width={_this.state.palete * _this.state.step}
									step={_this.state.step}
									interval={8}
									key="timelineruler"
									zoom={_this.state.zoom}
									playbackRate={Tone.Transport.bpm.value / this.originalTempo}
								/>

								<div className="annotations" style={{ color: "#ffffff" }}>
									<Annotations
										scrollLeft={() => {
											return _this.thsscrollleft;
										}}
										zoom={_this.state.zoom}
										width={trackPaletteWidth}
										onChange={this.addAnnotation}
									/>
									{this.state.annotations.map((item, index) => {
										return (
											<Annotation
												key={index}
												changeVisibilitySensorState={
													_this.changeVisibilitySensorState
												}
												index={index}
												name={item.name}
												width={item.width * _this.state.zoom}
												bg={item.color}
												left={item.left * _this.state.zoom}
												onActivate={this.updateannotation}
												updateWidth={this.updateAnnotaeionWidth}
												uanposition={e => {
													this.state.annotations[index].left =
														e.position.left / _this.state.zoom;
												}}
											/>
										);
									})}
								</div>
							</div>
							<div
								id="trackspalete"
								style={{
									width: trackPaletteWidth,
									height: this.state.paleteHeight,
								}}
							>
								<Scrollbar
									className="tracksholder"
									style={{ height: "100%" }}
									noScrollX={true}
									onScroll={e => {
										let sidebarscroll =
											document.getElementById("sidebar-tracks");
										sidebarscroll.scrollTop = e.scrollTop;
									}}
								>
									<Droppable
										onStart={(e, ui) => {}}
										onAddtrack={track => {
											if (track.isDelete !== 1) {
												if (track.name.includes("SFX")) {
													totalwidthforall = totalwidthsfx;
												} else if (track.url?.includes("custom_vocals")) {
													totalwidthforall = totalwidthVocals;
												} else {
													totalwidthforall = totalwidth;
												}
												this.addTrack(track, true, totalwidthforall);
											}
										}}
										scrollleft={() => {
											return this.thsscrollleft;
										}}
										step={this.state.step}
										zoom={this.state.zoom}
										id="drop-holder"
										className="drop-holder"
										style={{
											width:
												this.state.palete * this.state.step * _this.state.zoom,
											height: this.state.paleteHeight,
										}}
										onChange={() => {}}
									/>

									<svg
										width={
											this.state.palete * this.state.step * _this.state.zoom
										}
										height={
											(this.state.tracksList.length + 1 - _this.state.theSFX) *
											60
										}
										className="chanelGrid"
									>
										<defs>
											<pattern
												id="smallGrid"
												width={this.state.step}
												height="20"
												patternUnits="userSpaceOnUse"
											></pattern>
											<pattern
												id="grid"
												width={this.state.palete * this.state.step}
												height={60}
												patternUnits="userSpaceOnUse"
											>
												<rect
													width="100%"
													height="100%"
													fill="url(#smallGrid)"
												/>
												<path
													d={`M ${
														this.state.palete * this.state.step
													} 0 L 0 0 0 60`}
													fill="none"
													stroke="gray"
													strokeWidth="0.5"
												/>
											</pattern>
										</defs>
										<rect
											width={
												this.state.palete * this.state.step * this.state.zoom
											}
											height={(this.state.tracksList.length + 1) * 60}
											fill="url(#grid)"
										/>
									</svg>
									<div id="guide-b" className="guide"></div>
									<div id="guide-a" className="guide"></div>
									<div
										id="only-tracks"
										style={{
											height: (this.state.tracksList.length + 2) * 60,
										}}
									>
										{/* SFX */}
										{this.state.tracksList.map((item, index) => {
											let trackwave = new Wave(item.track.buffer);

											if (item.name.includes("SFX") && item.isDelete !== 1) {
												return (
													<div key={index} className={`channel-content`}>
														{
															<Draggable
																key={index}
																className="channel-region"
																click={() => {}}
																ondblclick={() => {}}
																init={{
																	scroll: false,
																	containment: "parent",
																	grid: [
																		_this.state.step * _this.state.zoom,
																		60,
																	],
																	start: (event, track) => {
																		guideB.classList.add("active");
																		guideA.classList.add("active");
																	},
																	drag: function (event, track) {
																		let thwidth =
																			item.width * _this.state.zoom + 1;
																		guideB.style.left =
																			track.position.left - 1 + "px";
																		guideA.style.left =
																			track.position.left + thwidth - 1 + "px";
																	},
																	stop: (event, track) => {
																		let thleftposition =
																			track.position.left / _this.state.zoom;

																		guideB.classList.remove("active");
																		guideA.classList.remove("active");

																		let posleft = parseInt(
																			thleftposition % _this.state.step
																		);
																		let thuippos = thleftposition - posleft;
																		_this.state.tracksList[index].position =
																			thuippos;
																		let sync =
																			_this.state.tracksList[index].track;
																		_this.state.tracksList[
																			index
																		].track.unsync();

																		let thpos =
																			thleftposition > 0
																				? thuippos /
																				  (_this.state.step * 2) /
																				  (Tone.Transport.bpm.value / 120)
																				: 0;

																		sync.sync().start(`${thpos}`, 0);
																	},
																}}
																style={{
																	width: item.width * this.state.zoom,
																	height: 60,
																	background: "#fff",
																	left: item.position * this.state.zoom,
																}}
															>
																<svg
																	width={item.width * this.state.zoom}
																	height={60}
																	viewBox="0 -1 6000 2"
																	preserveAspectRatio="none"
																	className="waveform"
																	style={{
																		background: "#ec008c",
																	}}
																>
																	<g>
																		<path
																			id={`waveform-${index}`}
																			d={trackwave.getPath()}
																		/>
																	</g>
																</svg>
															</Draggable>
														}
														<div
															style={{
																display: "none",
															}}
														>
															{(totalwidthsfx = totalwidthsfx + item.width - 1)}
															{
																(totalwidthforall =
																	totalwidthforall + item.width - 1)
															}
														</div>
													</div>
												);
											} else if (item.name.includes("SFX")) {
												totalwidthsfx = totalwidthsfx + item.width - 1;
												totalwidthforall = totalwidthforall + item.width - 1;
											}
										})}

										{/* Custom vocals */}
										{this.state.tracksList.map((item, index) => {
											let trackwave = new Wave(item.track.buffer);

											if (
												item.url.includes("custom_vocals") &&
												item.isDelete !== 1
											) {
												return (
													<div key={index} className={`channel-content `}>
														{item.isDelete !== 1 && (
															<Draggable
																key={index}
																className="channel-region"
																click={() => {}}
																ondblclick={() => {}}
																init={{
																	scroll: false,
																	containment: "parent",
																	grid: [
																		_this.state.step * _this.state.zoom,
																		60,
																	],
																	start: (event, track) => {
																		guideB.classList.add("active");
																		guideA.classList.add("active");
																	},
																	drag: function (event, track) {
																		let thwidth =
																			item.width * _this.state.zoom + 1;
																		guideB.style.left =
																			track.position.left - 1 + "px";
																		guideA.style.left =
																			track.position.left + thwidth - 1 + "px";
																	},
																	stop: (event, track) => {
																		let thleftposition =
																			track.position.left / _this.state.zoom;

																		guideB.classList.remove("active");
																		guideA.classList.remove("active");

																		let posleft = parseInt(
																			thleftposition % _this.state.step
																		);
																		let thuippos = thleftposition - posleft;
																		_this.state.tracksList[index].position =
																			thuippos;
																		let sync =
																			_this.state.tracksList[index].track;
																		_this.state.tracksList[
																			index
																		].track.unsync();

																		let thpos =
																			thleftposition > 0
																				? thuippos /
																				  (_this.state.step * 2) /
																				  (Tone.Transport.bpm.value / 120)
																				: 0;

																		// adjusted pos based on playback rate
																		// const adjPos =
																		//     thpos /
																		//     (Tone
																		//         .Transport
																		//         .bpm
																		//         .value /
																		//         _this.originalTempo);

																		sync.sync().start(`${thpos}`, 0);
																		_this.checkOverlapAndUpdateState();
																		_this.reRenderTracks();
																	},
																}}
																style={{
																	width: item.width * this.state.zoom,
																	height: 60,
																	background: "#fff",
																	left: item.position * this.state.zoom,
																}}
															>
																<svg
																	width={item.width * this.state.zoom}
																	height={60}
																	viewBox="0 -1 6000 2"
																	preserveAspectRatio="none"
																	className="waveform"
																	style={{
																		background: "#096dd9",
																	}}
																>
																	<g>
																		<path
																			id={`waveform-${index}`}
																			d={trackwave.getPath()}
																		/>
																	</g>
																</svg>
															</Draggable>
														)}
														<div
															style={{
																display: "none",
															}}
														>
															{
																(totalwidthVocals =
																	totalwidthVocals + item.width - 1)
															}
															{
																(totalwidthforall =
																	totalwidthforall + item.width - 1)
															}
														</div>
													</div>
												);
											} else if (item.url.includes("custom_vocals")) {
												totalwidthVocals = totalwidthVocals + item.width - 1;
												totalwidthforall = totalwidthforall + item.width - 1;
											}
										})}

										{/* Normal Tracks */}
										{this.state.tracksList.map((item, index) => {
											let trackwave = new Wave(item.track.buffer);

											if (item.isDelete !== 1) {
												return (
													<React.Fragment key={index}>
														{item.name.includes("SFX") === false &&
														item.url.includes("custom_vocals") === false ? (
															<div className="channel-content" key={index}>
																{item.isDelete !== 1 && (
																	<Draggable
																		key={index}
																		className="channel-region"
																		click={() => {}}
																		ondblclick={() => {}}
																		init={{
																			scroll: false,
																			containment: "parent",
																			grid: [
																				_this.state.step * _this.state.zoom,
																				60,
																			],
																			start: (event, track) => {
																				guideB.classList.add("active");
																				guideA.classList.add("active");
																			},
																			drag: function (event, track) {
																				let thwidth =
																					item.width * _this.state.zoom + 1;
																				guideB.style.left =
																					track.position.left - 1 + "px";
																				guideA.style.left =
																					track.position.left +
																					thwidth -
																					1 +
																					"px";
																			},
																			stop: (event, track) => {
																				let thleftposition =
																					track.position.left /
																					_this.state.zoom;

																				guideB.classList.remove("active");
																				guideA.classList.remove("active");

																				let posleft = parseInt(
																					thleftposition % _this.state.step
																				);
																				let thuippos = thleftposition - posleft;

																				_this.state.tracksList[index].position =
																					thuippos;
																				let sync =
																					_this.state.tracksList[index].track;
																				_this.state.tracksList[
																					index
																				].track.unsync();

																				let thpos =
																					thleftposition > 0
																						? thuippos /
																						  (_this.state.step * 2) /
																						  (Tone.Transport.bpm.value / 120)
																						: 0;

																				sync.sync().start(`${thpos}`, 0);
																				_this.checkOverlapAndUpdateState();
																				_this.reRenderTracks();
																			},
																		}}
																		style={{
																			width: item.width * this.state.zoom,
																			height: 60,
																			background: "#fff",
																			left: item.position * this.state.zoom,
																		}}
																	>
																		<svg
																			width={item.width * this.state.zoom}
																			height={60}
																			viewBox="0 -1 6000 2"
																			preserveAspectRatio="none"
																			className="waveform"
																			style={{
																				background: item.bgcolor,
																			}}
																		>
																			<g>
																				<path
																					id={`waveform-${index}`}
																					d={trackwave.getPath()}
																				/>
																			</g>
																		</svg>
																	</Draggable>
																)}
																<div
																	style={{
																		display: "none",
																	}}
																>
																	{(totalwidth = totalwidth + item.width - 1)}

																	{
																		(totalwidthforall =
																			totalwidthforall + item.width - 1)
																	}
																</div>
															</div>
														) : (
															""
														)}
													</React.Fragment>
												);
											} else if (
												item.name.includes("SFX") === false &&
												item.url.includes("custom_vocals") === false
											) {
												totalwidth = totalwidth + item.width - 1;
												totalwidthforall = totalwidthforall + item.width - 1;
											}
										})}
									</div>
								</Scrollbar>
							</div>
						</Scrollbar>
					</div>
				</div>
				<div className="studio-footer">
					<Row type="flex" gutter={20}>
						<Col lg={{ span: 6, order: 1 }}>
							<div className="ad-content">
								<Carousel autoplay dots={false}>
									{_this.state.ads &&
										_this.state.ads.map((item, index) => {
											return (
												<div key={item._id}>
													<a
														href={item.url}
														target="_blank"
														style={{
															display: "flex",
															justifyContent: "center",
															height: 45,
														}}
													>
														<img
															style={{
																height: 45,
																width: "100%",
															}}
															src={item.image}
															alt={item.title}
														/>
													</a>
												</div>
											);
										})}
								</Carousel>
							</div>
						</Col>
						<Col lg={{ span: 6, order: 2 }}>
							<div className="player">
								<Button
									style={{ marginLeft: 50 }}
									type="primary"
									size="large"
									shape="circle"
									icon="reload"
									onClick={() => {
										Modal.confirm({
											title: "Are you sure???",
											content:
												"You want to remove all tracks? You will lost all tracks data.",
											okText: "Yes sure",
											cancelText: "No I don't",
											onOk: () => {
												this.state.tracksList.map(item => {
													item.track.unsync();
												});
												this.setState({
													tracksList: [],
												});
											},
										});
									}}
								/>

								<Button
									className="large-icon"
									size="large"
									shape="circle"
									onClick={() => {
										let sec = Tone.Transport.seconds - 0.5;
										Tone.Transport.seconds =
											sec >= 0 ? Tone.Transport.seconds - 0.5 : 0;
									}}
								>
									<BackwardIcon />
								</Button>

								<Button
									className="large-icon"
									size="large"
									shape="circle"
									onClick={() => {
										this.stop();
									}}
								>
									<StopIcon />
								</Button>

								<Button
									className="large-icon"
									type={this.state.isPlaying ? "primary" : "default"}
									size="large"
									shape="circle"
									onClick={() => {
										{
											_this.state.isPlaying ? this.pause() : this.play();
										}
									}}
									disabled={!_this.state.isLoadedBuffer}
								>
									<PlayIcon />
								</Button>

								<Button
									className="large-icon"
									size="large"
									shape="circle"
									onClick={() => {
										let sec = Tone.Transport.seconds - 0.5;
										Tone.Transport.seconds =
											sec <= Tone.Transport.loopEnd
												? Tone.Transport.seconds + 0.5
												: 0;
									}}
								>
									<FastForwardIcon />
								</Button>
							</div>
						</Col>
						<Col lg={{ span: 6, order: 2 }}>
							<TempoChanger
								tempo={Tone.Transport.bpm.value}
								setTempo={tempo => {
									Tone.Transport.bpm.value = tempo;
									reactLocalStorage.set("tempo", Tone.Transport.bpm.value);
									Tone.Transport.setLoopPoints(
										0,
										this.state.palete * this.state.step * this.state.zoom
									);
									// Tone.Transport.bpm.rampTo(tempo, 0.1);
									this.reAddAllTracks();
									this.loopSchedule();
								}}
							/>
						</Col>
						<Col lg={{ span: 6, order: 2 }}>
							<div className="zoom-control">
								{/* <Button
									disabled={!this.state.overlapDetected}
									// shape="circle"
									onClick={() => {
										this.reRenderTracks();
									}}
									type="primary"
								>
									<Icon type="sliders" />
									Fix Overlapping
								</Button> */}
								<Button
									className="btncs-bg"
									// shape="circle"
									onClick={() => {
										this.zoomInpallete();
									}}
								>
									<Icon type="plus" />
									Zoom In
								</Button>

								<Button
									className="btncs-bg"
									// shape="circle"
									onClick={() => {
										this.zoomOutPallete();
									}}
									// icon="minus"
								>
									Zoom Out
									<Icon type="minus" />
								</Button>
							</div>
						</Col>
					</Row>
				</div>

				<div id="musicBar" className="mix-editor-sidetabs">
					<a
						className="musicBar-toggle"
						onClick={() => {
							this.toggleMusicBar();
						}}
					>
						<HeadPhonesIcon style={{ fontSize: 60 }} />
					</a>
					<div className="sidebar-header">
						<strong>Cheer Music Library</strong>
					</div>
					<div className="musicbar-inner">
						<Tabs defaultActiveKey="1" size="small" style={{ height: "100%" }}>
							<TabPane tab="Cheer Music Tracks" key="1">
								<Select
									style={{ width: "100%" }}
									onChange={this.handleChangeCategory}
									placeholder="Routine Sections"
								>
									{this.state.categories.map((item, index) => {
										return (
											<Select.Option
												key={index}
												value={item._id}
												className={`${
													// if the index is first or last
													// add classname text-bold-red
													index === 0
														? "text-bold-green"
														: index === this.state.categories.length - 1
														? "text-bold-red"
														: ""
												}`}
											>
												{item.cat_name}
											</Select.Option>
										);
									})}
								</Select>
								<Search
									placeholder="Search Tracks"
									onChange={e => {
										let value = e.target.value;
										_this.setState({
											trackssearch: value,
										});

										if (_this.state.currentcat_id.length > 0) {
											_this.searchtracksbycat(_this.state.currentcat_id, value);
										}
									}}
									style={{
										width: "100%",
										marginTop: 10,
										marginBottom: 10,
									}}
								/>
								<Skeleton loading={this.state.isLoadingTracks} title={false}>
									<div className="muscibar-tracks-holder">
										<h5 style={{ textAlign: "center" }}>
											Listen then drag & drop into the music maker.
										</h5>

										<h3
											style={{
												textAlign: "center",
												marginTop: 20,
												color: "#f5222d",
											}}
										>
											The Cheer Music Maker audio watermark will be removed once
											downloaded.
										</h3>
										{this.state.preloadedTracks &&
											this.state.preloadedTracks.map((item, index) => {
												return (
													<Draggable
														init={{
															helper: "clone",
															appendTo: "body",
															scroll: false,
															containment: "body",
															cursorAt: {
																left: 20,
															},
															start: (event, ui) => {
																$(".drop-holder").addClass("active");
																ui.helper.html(
																	'<i aria-label="icon: youtube" class="anticon anticon-youtube"><svg viewBox="64 64 896 896" class="" data-icon="youtube" width="40" height="40" fill="currentColor" aria-hidden="true"><path d="M960 509.2c0-2.2 0-4.7-.1-7.6-.1-8.1-.3-17.2-.5-26.9-.8-27.9-2.2-55.7-4.4-81.9-3-36.1-7.4-66.2-13.4-88.8a139.52 139.52 0 0 0-98.3-98.5c-28.3-7.6-83.7-12.3-161.7-15.2-37.1-1.4-76.8-2.3-116.5-2.8-13.9-.2-26.8-.3-38.4-.4h-29.4c-11.6.1-24.5.2-38.4.4-39.7.5-79.4 1.4-116.5 2.8-78 3-133.5 7.7-161.7 15.2A139.35 139.35 0 0 0 82.4 304C76.3 326.6 72 356.7 69 392.8c-2.2 26.2-3.6 54-4.4 81.9-.3 9.7-.4 18.8-.5 26.9 0 2.9-.1 5.4-.1 7.6v5.6c0 2.2 0 4.7.1 7.6.1 8.1.3 17.2.5 26.9.8 27.9 2.2 55.7 4.4 81.9 3 36.1 7.4 66.2 13.4 88.8 12.8 47.9 50.4 85.7 98.3 98.5 28.2 7.6 83.7 12.3 161.7 15.2 37.1 1.4 76.8 2.3 116.5 2.8 13.9.2 26.8.3 38.4.4h29.4c11.6-.1 24.5-.2 38.4-.4 39.7-.5 79.4-1.4 116.5-2.8 78-3 133.5-7.7 161.7-15.2 47.9-12.8 85.5-50.5 98.3-98.5 6.1-22.6 10.4-52.7 13.4-88.8 2.2-26.2 3.6-54 4.4-81.9.3-9.7.4-18.8.5-26.9 0-2.9.1-5.4.1-7.6v-5.6zm-72 5.2c0 2.1 0 4.4-.1 7.1-.1 7.8-.3 16.4-.5 25.7-.7 26.6-2.1 53.2-4.2 77.9-2.7 32.2-6.5 58.6-11.2 76.3-6.2 23.1-24.4 41.4-47.4 47.5-21 5.6-73.9 10.1-145.8 12.8-36.4 1.4-75.6 2.3-114.7 2.8-13.7.2-26.4.3-37.8.3h-28.6l-37.8-.3c-39.1-.5-78.2-1.4-114.7-2.8-71.9-2.8-124.9-7.2-145.8-12.8-23-6.2-41.2-24.4-47.4-47.5-4.7-17.7-8.5-44.1-11.2-76.3-2.1-24.7-3.4-51.3-4.2-77.9-.3-9.3-.4-18-.5-25.7 0-2.7-.1-5.1-.1-7.1v-4.8c0-2.1 0-4.4.1-7.1.1-7.8.3-16.4.5-25.7.7-26.6 2.1-53.2 4.2-77.9 2.7-32.2 6.5-58.6 11.2-76.3 6.2-23.1 24.4-41.4 47.4-47.5 21-5.6 73.9-10.1 145.8-12.8 36.4-1.4 75.6-2.3 114.7-2.8 13.7-.2 26.4-.3 37.8-.3h28.6l37.8.3c39.1.5 78.2 1.4 114.7 2.8 71.9 2.8 124.9 7.2 145.8 12.8 23 6.2 41.2 24.4 47.4 47.5 4.7 17.7 8.5 44.1 11.2 76.3 2.1 24.7 3.4 51.3 4.2 77.9.3 9.3.4 18 .5 25.7 0 2.7.1 5.1.1 7.1v4.8zM423 646l232-135-232-133z"></path></svg></i>'
																);
															},
															stop: (event, ui) => {
																$(".drop-holder").removeClass("active");
															},
															drag: (event, ui) => {
																ui.helper.css({
																	"z-index": 99,
																	border: "1px solid #f6f6f6",
																});
																ui.position.top = Math.round(event.pageY - 30);
																ui.position.left = Math.round(event.pageX - 30);
															},
														}}
														click={() => {}}
														id={item._id}
														dataid={item._id}
														dataurl={item.track_url}
														dataname={item.track_name}
														key={item._id + index}
														className="trackslistitems"
													>
														<MiniPlayer
															link={item.track_url}
															from="justPlay"
															miniplayer={_this.miniplayer}
														/>
														<div
															title={item.track_name}
															style={{
																width: "70%",
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
																fontSize: 12,
															}}
														>
															{item.track_name}
														</div>
													</Draggable>
												);
											})}
									</div>
								</Skeleton>
							</TabPane>
							<TabPane tab="Requested Vocals" key="2">
								<Skeleton loading={this.state.loadingVocals} title={false}>
									<div className="muscibar-tracks-holder-custom">
										<h5 style={{ textAlign: "center" }}>
											Listen then drag & drop into the music maker.
										</h5>
										{_this.state.vocals &&
											_this.state.vocals.map((item, j) => {
												if (item.track) {
													// only show the tracks name and path
													let thvocalname = decodeURI(item.track)
														.replace(
															"https://firebasestorage.googleapis.com/v0/b/cheer-music-maker-2021.appspot.com/o/custom_vocals%2F",
															""
														)
														.replace(/\?alt=media/, "");

													let thurl = item.track;
													return (
														<Draggable
															init={{
																helper: "clone",
																appendTo: "body",
																scroll: false,
																containment: "body",
																start: (event, ui) => {
																	$(".drop-holder").addClass("active");
																	ui.helper.html(
																		'<i aria-label="icon: youtube" class="anticon anticon-youtube"><svg viewBox="64 64 896 896" class="" data-icon="youtube" width="40" height="40" fill="currentColor" aria-hidden="true"><path d="M960 509.2c0-2.2 0-4.7-.1-7.6-.1-8.1-.3-17.2-.5-26.9-.8-27.9-2.2-55.7-4.4-81.9-3-36.1-7.4-66.2-13.4-88.8a139.52 139.52 0 0 0-98.3-98.5c-28.3-7.6-83.7-12.3-161.7-15.2-37.1-1.4-76.8-2.3-116.5-2.8-13.9-.2-26.8-.3-38.4-.4h-29.4c-11.6.1-24.5.2-38.4.4-39.7.5-79.4 1.4-116.5 2.8-78 3-133.5 7.7-161.7 15.2A139.35 139.35 0 0 0 82.4 304C76.3 326.6 72 356.7 69 392.8c-2.2 26.2-3.6 54-4.4 81.9-.3 9.7-.4 18.8-.5 26.9 0 2.9-.1 5.4-.1 7.6v5.6c0 2.2 0 4.7.1 7.6.1 8.1.3 17.2.5 26.9.8 27.9 2.2 55.7 4.4 81.9 3 36.1 7.4 66.2 13.4 88.8 12.8 47.9 50.4 85.7 98.3 98.5 28.2 7.6 83.7 12.3 161.7 15.2 37.1 1.4 76.8 2.3 116.5 2.8 13.9.2 26.8.3 38.4.4h29.4c11.6-.1 24.5-.2 38.4-.4 39.7-.5 79.4-1.4 116.5-2.8 78-3 133.5-7.7 161.7-15.2 47.9-12.8 85.5-50.5 98.3-98.5 6.1-22.6 10.4-52.7 13.4-88.8 2.2-26.2 3.6-54 4.4-81.9.3-9.7.4-18.8.5-26.9 0-2.9.1-5.4.1-7.6v-5.6zm-72 5.2c0 2.1 0 4.4-.1 7.1-.1 7.8-.3 16.4-.5 25.7-.7 26.6-2.1 53.2-4.2 77.9-2.7 32.2-6.5 58.6-11.2 76.3-6.2 23.1-24.4 41.4-47.4 47.5-21 5.6-73.9 10.1-145.8 12.8-36.4 1.4-75.6 2.3-114.7 2.8-13.7.2-26.4.3-37.8.3h-28.6l-37.8-.3c-39.1-.5-78.2-1.4-114.7-2.8-71.9-2.8-124.9-7.2-145.8-12.8-23-6.2-41.2-24.4-47.4-47.5-4.7-17.7-8.5-44.1-11.2-76.3-2.1-24.7-3.4-51.3-4.2-77.9-.3-9.3-.4-18-.5-25.7 0-2.7-.1-5.1-.1-7.1v-4.8c0-2.1 0-4.4.1-7.1.1-7.8.3-16.4.5-25.7.7-26.6 2.1-53.2 4.2-77.9 2.7-32.2 6.5-58.6 11.2-76.3 6.2-23.1 24.4-41.4 47.4-47.5 21-5.6 73.9-10.1 145.8-12.8 36.4-1.4 75.6-2.3 114.7-2.8 13.7-.2 26.4-.3 37.8-.3h28.6l37.8.3c39.1.5 78.2 1.4 114.7 2.8 71.9 2.8 124.9 7.2 145.8 12.8 23 6.2 41.2 24.4 47.4 47.5 4.7 17.7 8.5 44.1 11.2 76.3 2.1 24.7 3.4 51.3 4.2 77.9.3 9.3.4 18 .5 25.7 0 2.7.1 5.1.1 7.1v4.8zM423 646l232-135-232-133z"></path></svg></i>'
																	);
																},
																stop: (event, ui) => {
																	$(".drop-holder").removeClass("active");
																},
																drag: (event, ui) => {
																	ui.helper.css({
																		"z-index": 99,
																		border: "1px solid #f6f6f6",
																	});
																	ui.position.top = Math.round(
																		event.pageY - 30
																	);
																	ui.position.left = Math.round(
																		event.pageX - 30
																	);
																},
															}}
															click={() => {}}
															id={item._id}
															dataid={item._id}
															dataurl={thurl}
															dataname={thvocalname}
															key={item._id + "_" + j}
															className="trackslistitems"
														>
															<MiniPlayer
																link={thurl}
																miniplayer={_this.miniplayer}
															/>
															<div
																title={thvocalname}
																style={{
																	width: "70%",
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	fontSize: 12,
																}}
															>
																{thvocalname}
															</div>
														</Draggable>
													);
												}
											})}
									</div>
								</Skeleton>
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
}

export default Studio;
