const FastForwardSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 208 208">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M104,9A95,95,0,1,1,9,104,95.11,95.11,0,0,1,104,9m0-9A104,104,0,1,0,208,104,104,104,0,0,0,104,0Z" />
                <path
                    style={{ fill: "currentColor" }}
                    d="M60.5,81.34v51.27c0,4.36,2.83,6.33,5.47,6.33a7.14,7.14,0,0,0,3.75-1.15l32.78-20.07,5.56-3.39v-6.52l-5.56,3.4L66.83,133.05a2.63,2.63,0,0,1-.69.31,3.07,3.07,0,0,1-.08-.75V81.34a4.27,4.27,0,0,1,.09-.86,2.07,2.07,0,0,1,.84.31L102.5,101l5.56,3.17V97.75l-5.56-3.16L69.74,76C65.16,73.34,60.5,76.19,60.5,81.34Z"
                />
                <path
                    style={{ fill: "currentColor" }}
                    d="M102.5,81.34v51.27c0,4.36,2.83,6.33,5.47,6.33a7.15,7.15,0,0,0,3.76-1.15l43.35-26.53a5.91,5.91,0,0,0-.18-10.75L111.75,76C107.17,73.34,102.5,76.19,102.5,81.34Zm5.56,0a3.86,3.86,0,0,1,.1-.86,2.18,2.18,0,0,1,.84.31l43.15,24.56c.53.31.69.57.74.51s-.21.34-.72.66l-43.34,26.53a2.61,2.61,0,0,1-.68.31,2.56,2.56,0,0,1-.09-.75Z"
                />
            </g>
        </g>
    </svg>
);

export default FastForwardSvg;
