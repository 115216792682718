import React, { Component } from "react";
import { Button, Form, Input, Modal } from "antd";
import { postapi } from "../helper";
const { TextArea } = Input;

class CreateSupportWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
        }
    }

    handleSubmit = e => {
        const _this = this;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                _this.setState({
                    sending: true,
                });
                postapi('sendsupportmail', values)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            Modal.success({
                                title: 'Success',
                                content: resp.msg
                            });
                            _this.setState({
                                sending: false,
                            });
                            _this.props.form.resetFields();
                            _this.props.onSuccess();

                        }
                        else {
                            _this.setState({
                                sending: false,
                            });
                            Modal.error({
                                title: 'Sorry',
                                content: resp.msg || resp.message
                            })

                        }
                    })

            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input your name!' }],
                    })(
                        <Input
                            placeholder="Name"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ type: 'email', required: true, message: 'Please input your email!' }],
                    })(
                        <Input
                            placeholder="Email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('message', {
                        rules: [{ required: true, message: 'Please type your message!' }],
                    })(
                        <TextArea
                            placeholder="Let us know what you need help with"
                            autosize={{ minRows: 5, maxRows: 6 }}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={this.state.sending} className="login-form-button">
                        Send
                    </Button>

                    <Button className="login-form-button"
                        onClick={() => {
                            this.props.onCancel();
                        }}

                    >
                        Cancel
                    </Button>

                </Form.Item>
            </Form>
        )
    }
}

const CreateSupport = Form.create({ name: 'createsupport' })(CreateSupportWrapper);

export default CreateSupport;