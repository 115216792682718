/*global FB*/

import React, { Component } from 'react'
import { Menu, Layout } from 'antd';
import { Link } from "react-router-dom";
import { config } from "../../config";
import { reactLocalStorage } from "reactjs-localstorage";
const { Header } = Layout;


class ProfileHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            islogged: config.islogged,
            currentkey: 'login'
        }
    }

    componentDidMount() {
        const _this = this;
        window.fbAsyncInit = function () {
            FB.init({
                appId: '443043659535461',
                cookie: true,  // enable cookies to allow the server to access
                // the session
                xfbml: true,  // parse social plugins on this page
                version: 'v3.0' // use version 2.1
            });
        }.bind(this);

        // Load the SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/all.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

    }

    logOut() {
        reactLocalStorage.clear();
        window.location.href = '/'
    }

    handleClick = (e) => {
        if (e.key === 'logout') {
            this.logOut()
        }
        this.setState({
            currentkey: e.key,
        });
    }

    render() {
        return (
            <Header
                className='profileheader'
            >

                <Link to="/" className='logo-header'>
                    <img src='/CMM-Logo.png' alt='CheerMp3 Studio' />
                </Link>
                <Menu
                    onClick={this.handleClick}
                    selectedKeys={[this.state.currentkey]}
                    className="topMenu"
                    mode="horizontal"
                >
                    {
                        !this.state.islogged &&
                        <Menu.Item key="login">
                            <Link to='/'>Login</Link>
                        </Menu.Item>
                    }

                    {
                        this.state.islogged &&
                        <Menu.Item key="profile">
                            <Link to={`/profile/${config.user._id}`}>Profile</Link>
                        </Menu.Item>
                    }
                    {
                        this.state.islogged &&
                        <Menu.Item key="logout" >
                            Logout
                        </Menu.Item>
                    }

                </Menu>
                {/*<Link className='csbtn'*/}
                {/*      to='/studio'*/}
                {/*>Enter Music Maker</Link>*/}
            </Header>
        )
    }

}

export default ProfileHeader
