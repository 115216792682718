import React from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Radio,
    Row,
    Spin,
} from "antd";
import { config } from "../config";
import { callapi, postapi } from "../helper";
import moment from "moment";
import StripePaymentForm from "../StripePaymentForm";
import { reactLocalStorage } from "reactjs-localstorage";

class LicenseGeneratorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkingCoupon: false,
            savingSig: false,
            signature: false,
            nosignature: false,
            loadingBtn: false,
            totalprice: 0.0,
            actualprice: 0.0,
            upto230: false,
            plans: [],
            mix_length: "",
            coupon: false,
            downloadCount: 0,

            //if template
            mix_template: false,
            template_music_duration: "00:00",

            firstname: "",
            lastname: "",

            // payment
            clientToken: false,
            loadingpayment: false,
            nonce: false,

            // squareup
            loaded: false,
            // stripe payment
            paymentMethod: false,

            name: [],
            phoneext: "",
            phone: "",
            profile_affiliation: "",
            email: "",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeCoupon = this.changeCoupon.bind(this);
    }

    componentWillMount() {
        config.user = reactLocalStorage.getObject("user");

        this.setState({
            name: config.user.full_name.split(" "),
            phoneext: config.user.phone_ext,
            phone: config.user.user_phone,
            profile_affiliation: config.user.profile_affiliation,
            email: config.user.username,
            downloadCount:
                Number(reactLocalStorage.get("currenttrackdownloaded")) || 0,
        });
    }

    componentDidMount() {
        this.getPlans();
        if (this.props.mixlength) {
            this.setState({ mix_template: this.props.mixlength }, () => {
                this.loadMixTemplate(this.props.mixlength);
            });
        }
    }

    // load if have mix template
    loadMixTemplate(value) {
        const _this = this;
        let str = value.split("-");
        let duration = str[0].replace(" ", "");
        let amount = str[1].replace(" ", "");
        _this.setState(
            {
                actualprice: amount,
                mix_length: value,
                template_music_duration: duration,
            },
            () => {
                _this.props.onChangeplan(duration);
                _this.calculatetotal();
            }
        );
    }

    // newHandleSubmit = async (e) => {
    //     e.preventDefault()
    //     const _this = this;
    //     this.setState({
    //         loadingpayment: true,
    //     });

    // }

    handleSubmit = async e => {
        e.preventDefault();
        const _this = this;
        _this.setState({ loadingpayment: true });

        const values = this.props.form.getFieldsValue();

        let str = this.state.mix_length.split("-");
        values.mix_length = str[0];
        values.totalprice = _this.state.totalprice;
        values.signature = "";
        values.area_code = "";
        values.phone_number = "";
        values.licenseKey = Math.floor(1000 + Math.random() * 9000);

        let checkoutata = {
            amount: values.totalprice,
            first_name: values.first_name,
            last_name: values.last_name,
        };

        if (
            _this.state.totalprice > 0 &&
            config.user.plan._id !== "615d7054e556ecddc39f4b1d" &&
            _this.state.downloadCount !== 1
        ) {
            if (
                !_this.state.paymentMethod &&
                !_this.state.paymentMethod.customerId &&
                !_this.state.paymentMethod.token
            ) {
                return;
            }

            const paymentToken = await postapi("transactions/clientsecret", {
                amount: _this.state.totalprice * 100,
            });

            if (!paymentToken.data.clientSecret) {
                _this.setState({ loadingpayment: false });
            }

            _this.state.paymentMethod.stripe
                .confirmCardPayment(paymentToken.data.clientSecret, {
                    payment_method: { card: _this.state.paymentMethod.card },
                })
                .then(result => {
                    if (result.error) {
                        // Show error to your customer
                        message.error(result.error.message);
                        _this.setState({ loadingpayment: false });
                    } else {
                        checkoutata.paymentData = {
                            id: result.paymentIntent.id,
                            created: result.paymentIntent.created,
                            currency: result.paymentIntent.currency,
                            livemode: result.paymentIntent.livemode,
                            payment_method_types:
                                result.paymentIntent.payment_method_types,
                            status: result.paymentIntent.status,
                        };

                        _this.setState(
                            {
                                loadingpayment: false,
                                paymentModal: false,
                                paymentMethod: false,
                            },
                            () => {
                                values.transaction_details =
                                    checkoutata.paymentData;
                                _this.props.onSubmit(values);
                                _this.props.form.resetFields();
                            }
                        );
                    }
                })
                .catch(err => {
                    message.error(err.message);
                });
            return;
        } else if (_this.state.coupon && _this.state.totalprice <= 0) {
            _this.setState(
                {
                    loadingpayment: false,
                    paymentModal: false,
                    paymentMethod: false,
                },
                () => {
                    values.transaction_details = {};
                    _this.props.onSubmit(values);
                    _this.props.form.resetFields();
                }
            );

            return;
        } else if (
            _this.state.totalprice > 0 &&
            config.user.plan._id === "615d7054e556ecddc39f4b1d"
        ) {
            _this.setState(
                {
                    loadingpayment: false,
                    paymentModal: false,
                    paymentMethod: false,
                },
                () => {
                    values.transaction_details = {};
                    _this.props.onSubmit(values);
                    _this.props.form.resetFields();
                }
            );

            return;
        } else if (
            _this.state.downloadCount === 1 &&
            _this.state.totalprice > 0 &&
            config.user.plan._id !== "615d7054e556ecddc39f4b1d"
        ) {
            _this.setState(
                {
                    loadingpayment: false,
                    paymentModal: false,
                    paymentMethod: false,
                },
                () => {
                    values.transaction_details = {};
                    _this.props.onSubmit(values);
                    _this.props.form.resetFields();
                }
            );
        } else {
            message.error("Please select a Plan");
            return;
        }
    };

    // get all plans
    getPlans() {
        //getplans
        const _this = this;
        _this.setState({
            loading: true,
        });
        const plans = callapi("plans/purchaseplan");
        plans.then(data => {
            _this.setState({ plans: data.data.plans });
        });
    }

    // calculate the total payment values
    calculatetotal() {
        let amount = this.state.actualprice;
        let couponval = this.state.coupon
            ? this.calculateCoupon(this.state.coupon.amount, amount)
            : 0;

        let ttotal = parseFloat(amount) - parseFloat(couponval);
        this.setState({
            totalprice: ttotal.toFixed(2),
            paymentMethod: ttotal === 0,
        });
    }

    // On change coupon
    changeCoupon = e => {
        let coup = e.target.value.length;
        this.setState(
            {
                coupon: coup > 0 ? e.target.value : false,
                nonce: e.target.value === "aWovst",
            },
            () => {
                if (coup === 0) {
                    this.calculatetotal();
                }
            }
        );
    };

    // validate coupon
    validateCoupon() {
        this.setState({ checkingCoupon: true });

        postapi("coupons/checkcoupon", { coupon: this.state.coupon }).then(
            response => {
                if (response && response.status === "success") {
                    this.setState({
                        coupon: response.data.coupon,
                        nonce: "false",
                    });

                    this.calculatetotal();
                } else {
                    message.error(
                        "Invalid coupon code. Please re-check the code or contact admin for new code."
                    );
                }

                this.setState({ checkingCoupon: false });
            }
        );
    }

    // Calculate the percentage valy by coupon
    calculateCoupon(couponValue, amount) {
        const value = (amount / 100) * parseInt(couponValue);

        if (value > amount) {
            return amount;
        } else {
            return value;
        }
    }

    handleCancelPayment() {
        this.setState({
            loadingpayment: false,
            // clientToken: false,
            paymentModal: false,
        });
    }

    render() {
        let _this = this;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        setTimeout(() => {
            // this.props.form.setFields({
            //     mix_length: {
            //         value: this.state.mix_length,
            //     },
            // });
        }, 1000);

        if (!config.user.plan) {
            return (
                <div>
                    <h3>
                        Sorry! you did not subscribed to any plan yet please
                        subscribe to plan before purchase this mix.
                    </h3>
                </div>
            );
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <h3 className="text-center">
                    {config.user.plan.plan_duration > 0
                        ? `${Math.abs(
                              moment().diff(moment().add(1, "month"), "days")
                          )} Days License Agreement`
                        : "License Agreement"}
                </h3>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        minHeight: 100,
                        border: "2px solid",
                    }}
                >
                    <img style={{ width: 200 }} src="/CMM-Logo.png" />
                </div>
                <Divider />
                <h2 style={{ textAlign: "center", color: "#2e6da4" }}>
                    PROOF OF PURCHASE & LICENSE
                </h2>
                <p style={{ textAlign: "center" }}>
                    Cheer Music Maker certifies that all recordings provided to
                    the program and team listed below was created with properly
                    licensed music in compliance with U.S copyright law.
                </p>

                <Divider />

                <Form.Item label="Licensee name" style={{ marginBottom: 0 }}>
                    <Form.Item
                        label="First Name"
                        style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                        }}
                    >
                        {getFieldDecorator("first_name", {
                            initialValue: this.state.name[0]
                                ? this.state.name[0]
                                : "",
                            rules: [
                                {
                                    required: true,
                                    message: "Please give your first name!",
                                },
                            ],
                        })(
                            <Input
                                placeholder="First Name"
                                onChange={e => {
                                    _this.setState({
                                        firstname: e.target.value,
                                    });
                                }}
                            />
                        )}
                    </Form.Item>
                    <span
                        style={{
                            display: "inline-block",
                            width: "24px",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                    </span>
                    <Form.Item
                        label="Last Name"
                        style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                        }}
                    >
                        {getFieldDecorator("last_name", {
                            initialValue: this.state.name[1]
                                ? this.state.name[1]
                                : "",
                            rules: [
                                {
                                    required: true,
                                    message: "Please give your last name!",
                                },
                            ],
                        })(
                            <Input
                                placeholder="Last Name"
                                onChange={e => {
                                    _this.setState({
                                        lastname: e.target.value,
                                    });
                                }}
                            />
                        )}
                    </Form.Item>
                </Form.Item>

                <Form.Item label="E-mail">
                    {getFieldDecorator("email", {
                        initialValue: this.state.email ? this.state.email : "",
                        rules: [
                            {
                                required: true,
                                message: "Please type your email!",
                            },
                        ],
                    })(<Input type="email" placeholder="E-mail" />)}
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        label="This audio track is to be used by"
                        style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                        }}
                    >
                        {getFieldDecorator("audiousedby", {
                            initialValue: this.state.profile_affiliation
                                ? this.state.profile_affiliation
                                : "",
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please type who will gonna use this audio track!",
                                },
                            ],
                        })(
                            <Input
                                type="text"
                                placeholder="ex. Program / Gym Name"
                            />
                        )}
                    </Form.Item>
                    <span
                        style={{
                            display: "inline-block",
                            width: "24px",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                    </span>
                    <Form.Item
                        label="For the team named"
                        style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                        }}
                    >
                        {getFieldDecorator("team_name", {
                            initialValue: this.props.mixName,
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please type the team named to be!",
                                },
                            ],
                        })(<Input placeholder="ex. Tigers" />)}
                    </Form.Item>
                </Form.Item>

                <Divider />
                <h2 style={{ textAlign: "center", color: "#2e6da4" }}>
                    License Agreement Terms and Conditions
                </h2>
                <p style={{ textAlign: "center" }}>
                    This license agreement is valid only if you have read and
                    agreed to our licensing terms and conditions listed in the
                    link below.
                </p>
                <Divider />

                <Form.Item>
                    {getFieldDecorator("agreement", {
                        valuePropName: "checked",
                        rules: [
                            {
                                required: true,
                                message: "Please check the Terms & conditions!",
                            },
                        ],
                    })(
                        <Checkbox>
                            I have read and agree to{" "}
                            <a href={require("./terms.pdf")}>
                                terms & conditions
                            </a>
                        </Checkbox>
                    )}
                </Form.Item>

                {config.user.plan.plan_name !==
                    "All-Star Membership - (Paid Monthly)" && (
                    <div>
                        <Form.Item
                            label={`This license is valid for ${
                                config.user.plan.plan_duration > 0
                                    ? Math.abs(
                                          moment().diff(
                                              moment().add(
                                                  config.user.plan
                                                      .plan_duration,
                                                  config.user.plan
                                                      .plan_durationtype
                                              ),
                                              "days"
                                          )
                                      )
                                    : Math.abs(
                                          moment().diff(
                                              moment().add(1, "year"),
                                              "days"
                                          )
                                      )
                            } days and will expire on date listed below`}
                        >
                            {getFieldDecorator("validatedtill", {
                                initialValue:
                                    config.user.plan.plan_duration > 0
                                        ? moment().add(
                                              config.user.plan.plan_duration,
                                              config.user.plan.plan_durationtype
                                          )
                                        : moment().add(1, "year"),
                                rules: [
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ],
                            })(<DatePicker disabled format="MM/DD/YYYY" />)}
                        </Form.Item>

                        <Form.Item label="Select Mix length for purchase">
                            {getFieldDecorator("mix_length", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please check here!",
                                    },
                                ],
                            })(
                                this.state.mix_template ? (
                                    <Button type="primary">
                                        {_this.state.template_music_duration}{" "}
                                        Minutes
                                    </Button>
                                ) : (
                                    <Radio.Group
                                        buttonStyle="solid"
                                        onChange={e => {
                                            let str = e.target.value.split("-");
                                            let duration = str[0].replace(
                                                " ",
                                                ""
                                            );
                                            let amount = str[1].replace(
                                                " ",
                                                ""
                                            );
                                            _this.setState(
                                                {
                                                    actualprice: amount,
                                                    mix_length: e.target.value,
                                                },
                                                () => {
                                                    _this.props.onChangeplan(
                                                        duration
                                                    );
                                                    _this.calculatetotal();
                                                }
                                            );
                                        }}
                                    >
                                        {this.state.plans ? (
                                            this.state.plans.map(
                                                (item, index) => {
                                                    return (
                                                        <Radio.Button
                                                            key={index}
                                                            value={`${item.music_duration}-${item.plan_price}`}
                                                        >
                                                            {
                                                                item.music_duration
                                                            }{" "}
                                                            Minutes
                                                        </Radio.Button>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div>
                                                No plan available for now.
                                                Please check later
                                            </div>
                                        )}
                                    </Radio.Group>
                                )
                            )}
                        </Form.Item>

                        {_this.state.downloadCount === 1 ? (
                            <h1>You cannot use coupon for revision</h1>
                        ) : (
                            <Form.Item label="Enter coupon">
                                {getFieldDecorator("coupon")(
                                    <Input
                                        type="text"
                                        placeholder="Enter coupon if have any"
                                        onChange={_this.changeCoupon}
                                        style={{
                                            display: "inline-block",
                                            width: "calc(50% - 12px)",
                                        }}
                                    />
                                )}
                                <Button
                                    disabled={this.state.checkingCoupon}
                                    type="primary"
                                    style={{
                                        display: "inline-block",
                                        width: "calc(50% - 12px)",
                                    }}
                                    onClick={() => {
                                        this.validateCoupon();
                                    }}
                                >
                                    {this.state.checkingCoupon ? (
                                        <Spin />
                                    ) : (
                                        "Redeem"
                                    )}
                                </Button>
                            </Form.Item>
                        )}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "50%",
                            }}
                        >
                            {_this.state.downloadCount === 1 ? (
                                <>
                                    <h3>Total: </h3>
                                    <h3>Revision</h3>
                                </>
                            ) : (
                                <>
                                    <h3>Total: </h3>
                                    <h3>$ {this.state.totalprice}</h3>
                                </>
                            )}
                        </div>

                        <Divider />

                        <Row style={{ minHeight: 100 }}>
                            {this.state.totalprice > 0 &&
                                this.state.downloadCount !== 1 && (
                                    <StripePaymentForm
                                        price={this.state.totalprice}
                                        onSuccess={paymentMethod => {
                                            _this.setState({
                                                paymentMethod: paymentMethod,
                                                loadingpayment: false,
                                            });
                                        }}
                                        onEnd={() => {
                                            _this.setState({
                                                loadingpayment: false,
                                            });
                                        }}
                                    />
                                )}
                        </Row>
                    </div>
                )}

                <Form.Item>
                    <Button
                        loading={
                            this.state.loadingpayment || this.props.loading
                        }
                        disabled={
                            config.user.plan._id !==
                                "615d7054e556ecddc39f4b1d" &&
                            !_this.state.paymentMethod &&
                            _this.state.downloadCount !== 1
                        }
                        type="primary"
                        htmlType="submit"
                        block
                    >
                        Proceed to Next
                    </Button>
                </Form.Item>
                <Divider />
                <h2 style={{ textAlign: "center", color: "#2e6da4" }}>
                    Contact
                </h2>
                <p style={{ textAlign: "center" }}>
                    If you have any questions regarding this License Agreement
                    in question please contact us at: info@cheer-music-maker.com
                </p>
            </Form>
        );
    }
}

const LicenseGenerator = Form.create({ name: "license_generator" })(
    LicenseGeneratorForm
);

export default LicenseGenerator;
