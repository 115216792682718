import { Button, Col, Row } from "antd";
import React from "react";
import { Fade } from "react-reveal";
import ForwardArrowIcon from "../ForwardArrowIcon";
import headerBg2 from "../images/homepage-hero-2.png";
import BannerBG from "../images/Banner_bg.png";

const HeroBanner = ({ SignInModal }) => {
    return (
        <div
            className="hero-banner"
            style={{ backgroundImage: `url("${BannerBG}")` }}
        >
            <Row>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <Fade cascade left>
                        <div className="header-promo">
                            <h1>
                                Creating your own
                                <br />
                                cheerleading mix
                                <br />
                                has been never easier.
                            </h1>
                            <p>
                                It't as easy as 1, 2, 3. to make a fast great,
                                sounding cheer mix!
                            </p>
                            <div>
                                <ol>
                                    <li>
                                        <span>
                                            Sample the premixed cheer tracks
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Drag and drop the tracks you like
                                            into the Cheer Music Maker
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Press play and listen to the cheer
                                            mix you created
                                        </span>
                                    </li>
                                </ol>
                            </div>
                            <p>No editing skills are required!!!</p>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => SignInModal(true, "2")}
                            >
                                TRY IT FREE <ForwardArrowIcon />
                            </Button>
                        </div>
                    </Fade>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className="header-promo-img">
                        <Fade right>
                            <img
                                src={headerBg2}
                                alt="cheer music maker on a mac"
                            />
                        </Fade>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default HeroBanner;
