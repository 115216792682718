const MusicSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 208 208">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M135.5,58.45,79.41,69.57v51a14.72,14.72,0,1,0,6.46,13.87H86V94.59l43-8.53v21.42a14.72,14.72,0,1,0,6.46,10.61h.09ZM71.23,141a8.19,8.19,0,1,1,8.18-8.18A8.19,8.19,0,0,1,71.23,141Zm49.54-13.09a8.19,8.19,0,1,1,8.18-8.19A8.19,8.19,0,0,1,120.77,127.91ZM129,79.39,86,87.91V75l43-8.53Z" />
                <path d="M104,9A95,95,0,1,1,9,104,95.11,95.11,0,0,1,104,9m0-9A104,104,0,1,0,208,104,104,104,0,0,0,104,0Z" />
            </g>
        </g>
    </svg>
);

export default MusicSvg;
