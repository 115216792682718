import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { config } from './config';

const getToken = () => {
    if (config.authKey) {
        return reactLocalStorage.get('token');
    }
    return null;
};

const setAuthorizationHeader = (enableAuth, token) => {
    if (enableAuth) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

export const callApi = async (url, enableAuth = true, customToken = null) => {
    const token = customToken || getToken();
    setAuthorizationHeader(enableAuth, token);

    try {
        const response = await axios.get(`${config.apiurl}${url}`);
        return response?.data;
    } catch (error) {
        if (error?.response?.status === 401) {
            reactLocalStorage.clear();
            window.location.href = '/';
        }

        return error?.response?.data;
    }
};

export const deleteApi = async (url, enableAuth = true) => {
    const token = getToken();
    setAuthorizationHeader(enableAuth, token);

    try {
        const response = await axios.delete(`${config.apiurl}${url}`);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export const postApi = async (url, data, enableAuth = true) => {
    const token = getToken();
    setAuthorizationHeader(enableAuth, token);

    try {
        const response = await axios.post(`${config.apiurl}${url}`, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export const patchApi = async (url, data, enableAuth = true) => {
    const token = getToken();
    setAuthorizationHeader(enableAuth, token);

    try {
        const response = await axios.patch(`${config.apiurl}${url}`, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export const multipart = async (endpoint, data) => {
    const token = getToken();

    try {
        const response = await fetch(`${config.apiurl}${endpoint}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: data
        });

        const resp = await response.json();
        return resp;
    } catch (err) {
        console.log('Fetch Error:', err);
        return err;
    }
};
