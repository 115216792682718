import React, { Component } from 'react'
import {
    Row, Col, Table, Icon,
    Divider, Modal, Button,
    Drawer, Form, Input, Select, message, Switch
} from 'antd';
import { callapi, deleteapi, patchapi, postapi } from "../helper";

const FormItem = Form.Item;
const Option = Select.Option;

let id = 0;

class PlansWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addPlan: false,
            plans: [],
            plan_name: '',
            plan_for_purchase: false,
            plan_duration: '',
            plan_durationtype: 'month',
            music_duration: '',
            music_durationtype: 'minutes',
            plan_price: '',
            features: [],

            isSingle: false,
            single_id: '',
        };
        this.handleChangepd = this.handleChangepd.bind(this);
        this.handleChangemd = this.handleChangemd.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }

    componentWillMount() {
        // get all plans
        this.getPlans()
    }

    // get all plans
    getPlans() {
        // set loading state when getting plans
        const _this = this;
        _this.setState({
            loadingplans: true
        });

        // call the api
        const plans = callapi('plans');
        plans.then((data) => {
            // set plans data
            _this.setState({
                plans: data.data.data
            }, () => {
                _this.setState({
                    loadingplans: false
                })
            });
        })
    }

    // show add/update drawer
    showDrawer = () => {
        this.setState({
            addPlan: true,
        });
    };

    // update initials after closing the drawer
    onClose = () => {
        this.props.form.resetFields();
        // set the initial states
        this.setState({
            addPlan: false,
            isSingle: false,
            single_id: '',

            plan_name: '',
            plan_duration: '',
            plan_for_purchase: "0",
            plan_durationtype: 'weeks',
            music_duration: '',
            music_durationtype: 'minutes',
            plan_price: '',
        });
    };

    // if input chnaged
    handleChangepd(value) {
        this.setState({
            plan_durationtype: value
        })
    }

    handleChangemd(value) {
        this.setState({
            music_durationtype: value
        })
    }

    handleChange(name, event) {
        this.setState({
            [name]: event.target.value
        })
    }

    // save plans
    saveplan() {
        const _this = this;
        this.props.form.validateFieldsAndScroll((err, values) => {

            // check if there is no error on fields
            if (!err) {

                var filteredFeatures = values.features && values.features.filter(function (el) {
                    return el != null;
                });

                // gather the essential data to upload on db and call api
                const addplan = postapi('plans', {
                    plan_name: this.state.plan_name,
                    plan_for_purchase: this.state.plan_for_purchase,
                    plan_duration: this.state.plan_duration,
                    plan_durationtype: this.state.plan_durationtype,
                    music_duration: this.state.music_duration,
                    music_durationtype: this.state.music_durationtype,
                    plan_price: this.state.plan_price,
                    totalmix: this.state.totalmix,
                    features: filteredFeatures || [],
                });

                addplan.then((data) => {
                    // check if api returns error or success then show corresponds messages
                    if (data.status === 'error' || data.status === 'failed') {
                        message.error(data.message);
                        _this.setState({
                            loading: false
                        })
                    }
                    else {
                        _this.setState({
                            addPlan: false,
                            loading: false,
                        });
                        message.success(data.msg);
                        _this.getPlans();
                        _this.props.form.resetFields();
                        _this.onClose()
                    }
                })
                    .catch((err) => {
                        message.error(err.msg || err.message);
                        _this.setState({
                            loading: false
                        })
                    })

            }
        });
    }

    // update the plan
    updateplan() {
        const _this = this;
        _this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var filteredFeatures = values.features && values.features.filter(function (el) {
                    return el != null;
                });

                const addplan = patchapi(`plans/${this.state.single_id}`, {
                    plan_name: this.state.plan_name,
                    plan_for_purchase: this.state.plan_for_purchase,
                    plan_duration: this.state.plan_duration,
                    plan_durationtype: this.state.plan_durationtype,
                    music_duration: this.state.music_duration,
                    music_durationtype: this.state.music_durationtype,
                    plan_price: this.state.plan_price,
                    totalmix: this.state.totalmix,
                    features: filteredFeatures || [],
                });

                addplan.then((data) => {
                    if (data.status === 'error' || data.status === 'failed') {
                        message.error(data.message);
                        _this.setState({
                            loading: false
                        })
                    }
                    else {
                        _this.props.form.resetFields();
                        _this.setState({
                            addPlan: false,
                            loading: false,
                        });
                        message.success(data.msg);
                        _this.getPlans();
                    }
                })
                    .catch((err) => {
                        message.error(err.msg || err.message);
                        _this.setState({
                            loading: false
                        })
                    })

            }
        });
    }

    deleteplan() {
        const _this = this;

        // confirming to delete a plan
        Modal.confirm({
            title: 'Do you want to delete this plan?',
            content: 'You cannot retrieve the plan once you deleted.',
            onOk() {
                // call appi to delete plan
                const deleteplan = deleteapi(`plans/${_this.state.single_id}`);

                // promise after called api and check for the api status
                deleteplan.then((data) => {

                    _this.setState({
                        addPlan: false,
                        loading: false,
                    });
                    message.success(data.msg);
                    _this.getPlans();
                    _this.props.form.resetFields()
                })
                    .catch((err) => {
                        message.error(err.msg || err.message);
                        _this.setState({
                            loading: false
                        })
                    })
            },
            onCancel() { },
        });
    }

    // dynamic features list
    remove = k => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        // if (keys.length === 0) {
        //     return;
        // }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    add = () => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    render() {
        let _this = this;
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const columns = [
            {
                title: 'Plan Name',
                dataIndex: 'plan_name',
                key: 'plan_name',
            },
            {
                title: 'Duration',
                render: (text, record) => {
                    return (
                        <span>{record.plan_for_purchase ? record.music_duration : record.plan_duration} {record.plan_for_purchase ? record.music_durationtype : record.plan_durationtype}(s)</span>
                    )
                }
            },
            {
                title: 'Plan for',
                render: (text, record) => {
                    return (
                        <span>{record.plan_for_purchase ? 'Purchase' : 'Subscription'}</span>
                    )
                }
            },
            {
                title: 'Price',
                dataIndex: 'plan_price',
                key: 'plan_price',
                render: (text, record) => {
                    return (
                        <span>$ {text}</span>
                    )
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return (
                        <span>
                            <Divider type="vertical" />
                            <Button onClick={() => {
                                // set the single item on click edit
                                _this.setState({
                                    isSingle: true,
                                    single_id: record._id,
                                    plan_name: record.plan_name,
                                    plan_for_purchase: record.plan_for_purchase,
                                    plan_duration: record.plan_duration,
                                    plan_durationtype: record.plan_durationtype,
                                    music_duration: record.music_duration,
                                    music_durationtype: record.music_durationtype,
                                    plan_price: record.plan_price,
                                    totalmix: record.totalmix,
                                    features: record.features,
                                }, () => {
                                    let featurecounter = (record.features && record.features).length > 0 ? record.features.length : 0;
                                    id = featurecounter;
                                    for (let i = 0; i < featurecounter; i++) {
                                        const { form } = _this.props;
                                        // can use data-binding to get
                                        const keys = form.getFieldValue('keys');
                                        const nextKeys = keys.concat(i);
                                        // can use data-binding to set
                                        // important! notify form to detect changes
                                        form.setFieldsValue({
                                            keys: nextKeys,
                                        });
                                    }

                                    _this.showDrawer();
                                });
                            }} size='small' type="primary">
                                <Icon type="edit" />
                            </Button>
                            <Divider type="vertical" />
                            <Button onClick={() => {
                                _this.setState({
                                    single_id: record._id,
                                }, () => {
                                    _this.deleteplan()
                                })

                            }} size='small' type="danger">
                                <Icon type="delete" />
                            </Button>
                        </span>
                    )
                },
            }
        ];

        const musicduraationprefixSelector = getFieldDecorator('prefix', {
            initialValue: this.state.plan_durationtype,
        })(
            <Select
                onChange={this.handleChangepd}
                style={{ width: 120 }}>
                <Option value="week">week(s)</Option>
                <Option value="month">Month(s)</Option>
                <Option value="year">Year(s)</Option>
            </Select>
        );

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k, index) => (
            <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? 'Features' : ''}
                required={false}
                key={k}
            >
                {getFieldDecorator(`features[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: "Please input features name or delete this field.",
                        },
                    ],
                    initialValue: this.state.features && this.state.features[k]
                })(<Input placeholder="Feature" style={{ width: '60%', marginRight: 8 }} />)}
                {keys.length > 0 ? (
                    <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.remove(k)}
                    />
                ) : null}
            </Form.Item>
        ));

        return (
            <div>
                <Row>
                    <Col lg={24}>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                padding: '20px 0'
                            }}>
                            <Button type="primary" onClick={this.showDrawer}>Add new plan</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div style={{ backgroundColor: '#fff', padding: 20 }}>
                        <Table rowKey={record => record._id} loading={this.state.loadingplans} columns={columns} dataSource={this.state.plans} />
                    </div>

                </Row>

                <Drawer
                    title={this.state.isSingle ? 'Update plan' : 'Add new plan'}
                    width={520}
                    className="add_new_plan"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.addPlan}
                >
                    <div>
                        <Form>
                            <FormItem
                                label="Plan Name"
                            >
                                {getFieldDecorator('planname', {
                                    rules: [
                                        {
                                            required: true, message: 'You must type plan name!',
                                        }
                                    ],
                                    initialValue: this.state.plan_name
                                })(
                                    <Input
                                        onChange={(e) => this.handleChange('plan_name', e)}
                                        type='text' />
                                )}
                            </FormItem>

                            <FormItem
                                label="Plan Price"
                            >
                                {getFieldDecorator('plan_price', {
                                    rules: [
                                        {
                                            required: true, message: 'You must give plan price!',
                                        }
                                    ],
                                    initialValue: this.state.plan_price
                                })(
                                    <Input
                                        addonBefore="$"
                                        onChange={(e) => this.handleChange('plan_price', e)}
                                        type='text' />
                                )}
                            </FormItem>

                            {formItems}
                            <Form.Item {...formItemLayoutWithOutLabel}>
                                <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                                    <Icon type="plus" /> Add feature
                                </Button>
                            </Form.Item>


                            <FormItem
                                label="Total mix project can use"
                                extra="Please put 0 for unlimited"
                            >
                                {getFieldDecorator('totalmix', {
                                    rules: [
                                        {
                                            required: true, message: 'You must type total mix quantity for this plan!',
                                        }
                                    ],
                                    initialValue: this.state.totalmix
                                })(
                                    <Input
                                        onChange={(e) => this.handleChange('totalmix', e)}
                                        type='text' />
                                )}
                            </FormItem>


                            <Form.Item label="Is this plan for purchase">
                                {getFieldDecorator('notificationState', {
                                    valuePropName: 'checked',
                                    initialValue: (_this.state.plan_for_purchase),
                                }
                                )(
                                    <Switch onChange={(checked) => {
                                        _this.setState({
                                            plan_for_purchase: checked ? true : false
                                        });
                                    }} />
                                )}
                            </Form.Item>

                            {
                                (this.state.plan_for_purchase) ?
                                    <FormItem
                                        label="Mix Duration"
                                    >
                                        {getFieldDecorator('musicduration', {
                                            rules: [{
                                                required: true, message: 'Please add music duration!'
                                            }],
                                            initialValue: this.state.music_duration
                                        })(
                                            <Input
                                                onChange={(e) => this.handleChange('music_duration', e)}
                                                addonBefore={<span>Minute(s) - ex: 1:00</span>} style={{ width: '100%' }} />
                                        )}
                                    </FormItem> :
                                    <FormItem
                                        label="Plan Duration"
                                    >
                                        {getFieldDecorator('plan_duration', {
                                            rules: [{
                                                required: true, message: 'Add plan duration!'
                                            }],
                                            initialValue: this.state.plan_duration
                                        })(
                                            <Input
                                                onChange={(e) => this.handleChange('plan_duration', e)}
                                                addonBefore={musicduraationprefixSelector} style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                            }

                            <FormItem>
                                <Button
                                    onClick={() => {
                                        this.state.isSingle ?
                                            this.updateplan() :
                                            this.saveplan()

                                    }}
                                    type="primary" htmlType="submit">{this.state.isSingle ? 'Update plan' : 'Save plan'}</Button>
                            </FormItem>
                        </Form>
                    </div>
                </Drawer>

            </div>
        )
    }

}

const Plans = Form.create()(PlansWrapper);

export default Plans
