import React, { Component } from 'react'
import { Row, Col } from 'antd';
import { config } from "../config";
import PasswordUpdate from "./PasswordUpdate";


class ProfileUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            paletteHeight: window.innerHeight - 100,
        }
    }


    render() {
        return (
            <div id="thstudiomiddle">
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <h2 className='text-center'>Update password</h2>
                        <PasswordUpdate user={config.user} />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}></Col>
                </Row>
            </div>
        )
    }

}

export default ProfileUpdate
