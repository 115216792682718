import { getApps, initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyBtHAPgUngrF0-5qSfPiIiVSGKUCUrVxWk",
  authDomain: "cheer-music-maker-2021.firebaseapp.com",
  projectId: "cheer-music-maker-2021",
  storageBucket: "cheer-music-maker-2021.appspot.com",
  messagingSenderId: "742110331865",
  appId: "1:742110331865:web:f82ebc032b9eab51254fc8",
  measurementId: "G-D6SY8DFQWV"
};

// Initialize Firebase
const app = getApps().length > 1 ? getApps()[0] : initializeApp(firebaseConfig);


// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);



const uploadFile = (file) => {
  if (!file) return;

  const noticesRef = ref(storage, `notices/${file.name}`);

  // upload the file, make it public and return the URL
  return uploadBytes(noticesRef, file).then(async (snapshot) => {

    const url = await getDownloadURL(snapshot.ref);
    return url;
  });
}


export { uploadFile };