import React, { useCallback, useEffect, useState } from 'react';
import { Col, DatePicker, Row } from 'antd';
import StatsCard from './StatsCard';
import { callapi } from '../helper';
import ReportBarChart from './ReportBarChart';
import ReportLineChart from './ReportLineChart';
import moment from 'moment';

const { RangePicker } = DatePicker;

const Dashboard = props => {
    const [loadingUserTotal, setLoadingTotalUser] = useState(false);
    const [primaryStats, setPrimaryStats] = useState([]);
    const [dateRange, setDateRange] = useState([
        moment(new Date(new Date().setMonth(new Date().getMonth() - 4))).format(
            'YYYY-MM-DD'
        ),
        moment(new Date()).format('YYYY-MM-DD'),
    ]);
    const [data, setData] = useState([]);

    useEffect(() => {
        // loadTotalUser();
        loadPrimaryStats();
        loadReport();
    }, []);

    const loadReport = useCallback(async () => {
        const data = await callapi(
            `stats/reportbyrange?start_date=${dateRange[0]}&end_date=${dateRange[1]}`
        );

        if (data.status === 'success') {
            const chartData = data.data.map(item => {
                return {
                    ...item,
                    sales: item?.sales[0]?.total || 0,
                };
            });

            setData(chartData);
        }
    }, [dateRange]);

    const loadPrimaryStats = async () => {
        setLoadingTotalUser(true);
        try {
            const resp = await callapi('stats/primary');

            if (resp.status === 'error' || resp.status === 'failed') {
                setLoadingTotalUser(false);
                return;
            }

            setPrimaryStats(resp.data);
            setLoadingTotalUser(false);
        } catch (error) {
            console.log('log-err', err);
            setLoadingTotalUser(false);
        }
    };

    return (
        <div id='thstudiomiddle'>
            <Row gutter={16}>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <StatsCard
                        loading={loadingUserTotal}
                        title='Total Mix Purchased'
                        data={primaryStats?.purchase?.total_purchases}
                        value={primaryStats?.purchase?.total_purchases}
                        color='rgb(23, 198, 113)'
                        className='increased'
                    />
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <StatsCard
                        loading={loadingUserTotal}
                        title='Total Mix Purchased This Week'
                        data={primaryStats?.purchase?.purchase_this_week}
                        value={primaryStats?.purchase?.purchase_this_week}
                        color='rgb(23, 198, 113)'
                        className='increased'
                    />
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <StatsCard
                        loading={loadingUserTotal}
                        title='New Mixes (last 15 days)'
                        value={primaryStats?.mixes?.new_mixes}
                        data={primaryStats?.mixes?.new_mixes}
                        color='rgb(23, 198, 113)'
                        className='increased'
                    />
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <StatsCard
                        title='New Users (last 15 days)'
                        loading={loadingUserTotal}
                        data={primaryStats?.users?.new_users}
                        value={primaryStats?.users?.new_users}
                        color='rgb(23, 198, 113)'
                        className='increased'
                        percentage={primaryStats?.users?.new_user_percent}
                        showParcentage
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={{ span: 12 }} lg={{ span: 24 }}>
                    <div className='dash-card'>
                        <div className='dash-card-inner-large'>
                            <div className='dash-card-toolbar'>
                                <RangePicker
                                    size='small'
                                    onChange={(dates, dateStrings) => {
                                        setDateRange(dateStrings);
                                        loadReport();
                                    }}
                                    defaultValue={[
                                        moment(
                                            new Date(
                                                new Date().setMonth(
                                                    new Date().getMonth() - 4
                                                )
                                            )
                                        ),
                                        moment(new Date()),
                                    ]}
                                />
                            </div>
                            {/* <SalesReport /> */}
                            <ReportBarChart data={data} />
                        </div>
                    </div>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={{ span: 12 }} lg={{ span: 24 }}>
                    <div className='dash-card'>
                        <div className='dash-card-inner-large'>
                            <ReportLineChart />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;
