import axios from "axios";
import { config } from "./config";


function getHeaders() {
    // preparing headers with access token
    return {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${config.stripeSecretkey}`
    };
}

export const createStripeCustomer = async (email, name, token) => {
    const headers = await getHeaders();
    const body = new URLSearchParams();
    body.append("email", email);
    body.append("name", name);
    body.append("source", token);
    return await axios.post("https://api.stripe.com/v1/customers", body, { headers })
        .then((response) => response.data)
        .catch((response) => response.response.data);
};

export const handleCustomerCard = async (stripeCustomerId, cardValidationToken) => {
    const headers = await getHeaders();
    const body = new URLSearchParams();
    body.append("source", cardValidationToken);
    return await axios.post("https://api.stripe.com/v1/customers/" + stripeCustomerId + "/sources", body, { headers })
        .then((response) => response.data)
        .catch((response) => response.response.data);
}

export const handleChargeCustomer = async (price, stripeCustomerId, customerCardId, message) => {
    const headers = await getHeaders();
    const body = new URLSearchParams();
    body.append("amount", 100 * price);
    body.append("currency", "USD");
    body.append("source", customerCardId);
    body.append("capture", true);
    body.append("description", message);
    body.append("customer", stripeCustomerId);
    return await axios.post("https://api.stripe.com/v1/charges", body, { headers })
        .then((response) => response.data)
        .catch((err) => err.response.data);
}

export const handleCreateSubscription = async (stripeCustomerId, plan_name) => {
    const headers = await getHeaders();
    const body = new URLSearchParams();
    body.append("customer", stripeCustomerId);
    body.append("items[0][price]", config.plan_id);
    body.append("metadata[user_id]", config.user._id);
    let sub = await axios.post("https://api.stripe.com/v1/subscriptions", body, { headers })
        .then((response) => response.data)
        .catch((response) => response.response.data);


    const body1 = new URLSearchParams();
    body1.append("customer", stripeCustomerId);
    body1.append("limit", 1);
    let paymentIntent = await axios.get("https://api.stripe.com/v1/payment_intents?customer=" + stripeCustomerId, { headers })
        .then((response) => response.data)
        .catch((response) => response.response.data);

    const body2 = new URLSearchParams();
    body2.append("description", plan_name);
    let update_intent = await axios.post("https://api.stripe.com/v1/payment_intents/" + paymentIntent.data[0].id, body2, { headers })
        .then((response) => response.data)
        .catch((response) => response.response.data);

    return paymentIntent.data[0];

}

export const handleUpdatePaymentIntent = async (paymentIntent, plan_name) => {
    const headers = await getHeaders();
    const body = new URLSearchParams();

    body.append("description", plan_name);

    let update_intent = await axios.post("https://api.stripe.com/v1/payment_intents/" + paymentIntent, body, { headers })
        .then((response) => response.data)
        .catch((response) => response.response.data);

    return true;

}
