import { Slider, InputNumber, Row, Col, Typography } from "antd";
import { useState } from "react";
const { Text } = Typography;

const TempoChanger = ({ tempo, setTempo }) => {
	const [inputValue, setInputValue] = useState(tempo);

	const onChange = value => {
		setInputValue(value);
	};

	const onAfterChange = value => {
		setInputValue(value);
		setTempo(value);
	};

	return (
		<div
			style={{
				marginTop: "0.3rem",
				marginBottom: "0.3rem",
			}}
		>
			<Row>
				<Col span={12}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Text
							style={{
								marginLeft: "6px",
							}}
						>
							Music Speed
						</Text>
						<Slider
							min={135}
							max={155}
							onChange={onChange}
							onAfterChange={onAfterChange}
							value={typeof inputValue === "number" ? inputValue : 0}
							step={5}
							style={{
								marginTop: "0.3rem",
								marginBottom: "0.3rem",
							}}
						/>
					</div>
				</Col>
				<Col span={4}>
					<InputNumber
						style={{ marginLeft: 16 }}
						value={inputValue}
						// onChange={onChange}
						readOnly
					/>
				</Col>
			</Row>
		</div>
	);
};

export default TempoChanger;
