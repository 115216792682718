import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { callapi } from '../helper';

const ReportLineChart = () => {
    const [data, setData] = useState([
        {
            id: 'sales',
            color: 'hsl(208, 70%, 50%)',
            data: [],
        },
    ]);

    const loadSales = useCallback(async () => {
        const twelveMonthsAgo = moment(
            new Date(new Date().setMonth(new Date().getMonth() - 12))
        ).format('YYYY-MM-DD');

        const today = moment(new Date()).format('YYYY-MM-DD');

        const data = await callapi(
            `stats/reportbyrange?start_date=${twelveMonthsAgo}&end_date=${today}`
        );

        if (data.status === 'success') {
            const chartData = data.data.map(item => {
                return {
                    x: item.month,
                    y: item?.sales[0]?.total || 0,
                };
            });

            setData([
                {
                    id: 'sales',
                    color: 'hsl(208, 70%, 50%)',
                    data: chartData,
                },
            ]);
        }
    }, []);

    useEffect(() => {
        loadSales();
    }, []);

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
            }}
            curve='cardinal'
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Months',
                legendOffset: 36,
                legendPosition: 'middle',
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Sales Count $',
                legendOffset: -40,
                legendPosition: 'middle',
            }}
            axisTop={{
                orient: 'top',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Sales for the year',
                legendOffset: -40,
                legendPosition: 'middle',
            }}
            colors={{ scheme: 'dark2' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            enableArea={true}
            areaOpacity={0.1}
            useMesh={true}
            tooltip={({ point }) => (
                // a simple tooltip what says salef for month and the value
                <div
                    style={{
                        backgroundColor: '#072227',
                        padding: '10px',
                        borderRadius: '2px',
                        color: '#fff',
                        width: '200px',
                        textAlign: 'center',
                    }}
                >
                    <div>
                        <span style={{ textTransform: 'capitalize' }}>
                            {point.serieId} made on
                        </span>
                        <br />
                        <span>the month of {`${point.data.x}`}</span>
                    </div>
                    <div>
                        <span>is {`${point.data.y}$`}</span>
                    </div>
                </div>
            )}
        />
    );
};

export default ReportLineChart;
