import React, { Component } from "react";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { Button, message } from "antd";
import { config } from "./config";
import { createStripeCustomer } from "./StripApis";

class StripePaymentFormWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carderror: false,
            processing: false,
            cardProcessed: false,
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // this.props.form.setFieldsValue({
        //     name: config.user.full_name,
        //     email: config.user.username,
        // });
    }

    onChange = e => {
        if (e.error) {
            this.setState({
                carderror: e.error.message,
            });
        } else {
            this.setState({
                carderror: false,
            });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const _this = this;
        _this.setState({
            processing: true,
        });
        try {
            const { stripe, elements } = _this.props;
            let billing_details = {};
            if (config.user.username !== "" && config.user.username !== null) {
                billing_details.email = config.user.username;
                billing_details.name = config.user.full_name
                    ? config.user.full_name
                    : config.user.username;
            }
            if (
                config.user.user_phone !== "" &&
                config.user.user_phone !== null
            ) {
                billing_details.phone = config.user.user_phone;
            }
            // const {error, paymentMethod} = await stripe.createPaymentMethod({
            //     type: 'card',
            //     card: elements.getElement(CardElement),
            //     billing_details: billing_details
            // });
            // if (error) {
            //     _this.setState({
            //         carderror: error.message,
            //         processing: false,
            //     });
            // } else {

            const cardValidation = await stripe.createToken(
                elements.getElement(CardElement)
            );

            if (cardValidation.error) {
                message.error(cardValidation.error.message);
                return;
            }

            let customer = await createStripeCustomer(
                config.user.username,
                config.user.full_name,
                cardValidation.token.id
            );

            if (customer.error) {
                if (customer.error.code === "email_invalid") {
                    // prompt the user to enter email
                    // thake that email and create a customer again
                    // if the customer is created successfully, then proceed to create the payment method

                    const email = prompt(
                        "Please enter your valid email address"
                    );
                    if (email) {
                        customer = await createStripeCustomer(
                            email,
                            config.user.full_name,
                            cardValidation.token.id
                        );
                    }
                } else {
                    message.error(customer.error.message);
                    return;
                }
            }

            _this.props.onSuccess({
                paymentToken: "paymentToken.clientSecret",
                stripe: stripe,
                card: elements.getElement(CardElement),
                token: cardValidation.token.id,
                customerId: customer.id,
            });
            _this.setState({
                processing: false,
                cardProcessed: true,
            });
        } catch (error) {
            _this.props.onEnd();
        } finally {
            _this.setState({
                processing: false,
            });
            _this.props.onEnd();
        }
    };

    render() {
        const _this = this;
        return (
            <form onSubmit={_this.handleSubmit}>
                <div className="stripe-element">
                    <CardElement
                        options={{
                            iconStyle: "solid",
                            style: {
                                base: {
                                    iconColor: "#000",
                                    color: "#000",
                                    fontWeight: 500,
                                    fontFamily:
                                        "Roboto, Open Sans, Segoe UI, sans-serif",
                                    fontSize: "16px",
                                    fontSmoothing: "antialiased",
                                    ":-webkit-autofill": {
                                        color: "#000",
                                    },
                                    "::placeholder": {
                                        color: "#000",
                                    },
                                },
                                invalid: {
                                    iconColor: "#ff0049",
                                    color: "#ff0049",
                                },
                            },
                        }}
                        onChange={this.onChange}
                    />
                </div>
                {_this.state.carderror && (
                    <div className="paymentform-error-message">
                        {_this.state.carderror}
                    </div>
                )}

                <Button
                    loading={_this.state.processing}
                    disabled={_this.state.cardProcessed}
                    htmlType="submit"
                    type="primary"
                    block
                >
                    Verify Payment Method
                </Button>
                {_this.state.cardProcessed && (
                    <div className="paymentform-success-message">
                        Card successfully verified. Please click Proceed to
                        complete your order.
                    </div>
                )}
            </form>
        );
    }
}

const StripePaymentForm = props => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <StripePaymentFormWrapper
                {...props}
                stripe={stripe}
                elements={elements}
            />
        )}
    </ElementsConsumer>
);

export default StripePaymentForm;
