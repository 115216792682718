import React, { Component } from 'react'
import { Button, Switch, Table, Row, message, Input } from 'antd';
import { callapi, patchapi } from "../helper";
import moment from "moment";


class Subscription extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subscriptions: [],
            filteredSubscriptions: [],
            searchText: '',
        }
    }

    componentWillMount() {
        this.getSubscription()
    }

    getSubscription() {
        //getplans
        const _this = this
        _this.setState({
            loading: true
        })
        const subscriptions = callapi('transactions/plans')
        subscriptions.then((data) => {

            // extract user_id and plan_id object and put it into main object,
            // so that we can use it in the table
            let subscriptions = data.data.data.map(item => {
                return {
                    ...item,
                    user_id: item.user_id?._id,
                    plan_id: item.plan_id?._id,
                    user_name: item.user_id?.username,
                    plan_name: item.plan_id?.plan_name,
                    plan_price: item.plan_id?.price,
                    key: item?._id,
                    created_at: moment(item.created_at).format('DD/MM/YYYY'),
                    full_name: item.user_id?.full_name,
                    user_plan_type: item.user_id?.user_plan_type,
                }
            })

            _this.setState({
                subscriptions: subscriptions,
                filteredSubscriptions: subscriptions,
                loading: false
            })
        })
    }

    _onSearch(value) {
        this.setState({
            searchText: value
        });
        let searchvalue = value.toLowerCase();
        if (searchvalue && searchvalue.length > 0) {
            let filteredSubscriptions = this.state.subscriptions.filter(item => (item.user_name && item.user_name.toLowerCase().includes(searchvalue)) || (item.plan_name && item.plan_name.toLowerCase().includes(searchvalue)));
            this.setState({
                filteredSubscriptions: filteredSubscriptions,
            });
        }
        else {
            this.setState({
                filteredSubscriptions: this.state.subscriptions,
            });
        }
    }

    onChangeStatus(item, status) {
        const _this = this
        let updatesubs = patchapi(`transactions/plan/${item._id}`, {
            is_activated: status
        });
        updatesubs.then((data) => {
            if (data.status === 'success') {
                _this.getSubscription()
                message.success(`${item.plan_name} has been successfully ${status === 1 ? "activated" : "inactivated"}`)
            }
            else {
                message.error(`${item.plan_name} cannot be updated at this time.`)
            }

        })
            .catch(err => {
                message.error(`${item.plan_name} cannot be updated at this time.`)
            })
    }

    onDelete(item, status) {
        const _this = this;
        let updatesubs = patchapi(`transactions/plan/${item._id}`, {
            status: status
        });
        updatesubs.then((data) => {
            if (data.status === 'success') {
                _this.getSubscription()
                message.success(`${item.plan_name} has been successfully deleted`)
            }
            else {
                message.error(`${item.plan_name} cannot be deleted at this time.`)
            }

        })
            .catch(err => {
                message.error(`${item.plan_name} cannot be deleted at this time.`)
            })
    }

    render() {

        let _this = this
        const columns = [
            {
                title: 'Full name',
                dataIndex: 'full_name',
                key: 'full_name',
            },
            {
                title: 'User',
                dataIndex: 'user_name',
                key: 'user_name',
            },
            {
                title: 'Plan Name',
                dataIndex: 'plan_name',
                key: 'plan_name',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (text) => {
                    return (
                        <span>$ {text}</span>
                    )
                }
            },
            {
                title: 'Date',
                dataIndex: 'added_at',
                key: 'added_at',
                render: (text) => {
                    return moment(text).format("MM-DD-YYYY")
                }
            },
            {
                title: 'Subscription',
                dataIndex: 'plan_for_purchase',
                key: 'plan_for_purchase',
                render: (text) => {
                    return text === 1 ? "No" : "Yes";
                }
            },
            {
                title: 'Active Subscriber',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (record.user_id.user_plan_type !== record.plan_id._id) {
                        return <div>Previous Plan</div>;
                    }
                    return (
                        <div>
                            <Switch
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                                defaultChecked={(record.is_activated)}
                                onChange={(e) => {
                                    this.onChangeStatus(record, (record.is_activated ? false : true))
                                }}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => {
                    return (
                        <div>
                            <Button type='danger' size="small" icon='delete' onClick={() => {
                                this.onDelete(record, (record.status === 1 ? 0 : 1))
                            }} />
                        </div>
                    )
                }
            },
        ];

        return (
            <div id="thstudiomiddle">
                <Row>
                    <div style={{ backgroundColor: '#fff', padding: 20 }}>
                        <div className="searchbar">
                            <Input
                                className="searchbox"
                                placeholder="Search by users or plan name"
                                value={this.state.searchText}
                                onChange={(e) => {
                                    this._onSearch(e.target.value);
                                }}
                                suffix={<Button type="primary" icon="close" onClick={() => {
                                    this.setState({
                                        filteredSubscriptions: this.state.subscriptions,
                                        searchText: ''
                                    });
                                }} />}
                            />
                        </div>
                        <Table loading={this.state.loading} columns={columns} dataSource={this.state.filteredSubscriptions} />
                    </div>

                </Row>
            </div>
        )
    }

}

export default Subscription
