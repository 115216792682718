import React, { Component } from "react";
import $ from "jquery";
import VisibilitySensor from "react-visibility-sensor";
import Draggable from "./Draggable";

class Annotation extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $("#sampler, #trackspalete").on("click", e => {
            $(".annotation").removeClass("active");
        });
    }

    renameAnnotation(e) {
        this.activateAnnotation(e);
        this.props.onActivate(e.currentTarget.dataset.id);
        // $('.annotation').removeClass('active');
    }

    activateAnnotation(e) {
        $(".annotation").removeClass("active");
        e.currentTarget.classList.add("active");
    }

    render() {
        const _this = this;

        return (
            <Draggable
                click={e => this.activateAnnotation(e)}
                ondblclick={e => this.renameAnnotation(e)}
                key={this.props.index}
                id={"annotation-" + this.props.index}
                dataid={this.props.index}
                resize={{
                    containment: "parent",
                    handles: "e, w",
                    start: (e, ui) => {
                        this.activateAnnotation(e);
                    },
                    stop: (e, ui) => {
                        _this.props.updateWidth(
                            ui.helper[0].dataset.id,
                            ui.size.width
                        );
                    },
                }}
                init={{
                    containment: "parent",
                    start: (e, ui) => {
                        _this.activateAnnotation(e);
                    },
                    stop: (en, annotonation) => {
                        _this.props.uanposition(annotonation);
                    },
                }}
                style={{
                    width: this.props.width,
                    left: this.props.left,
                    background: this.props.bg,
                }}
                className="annotation"
            >
                {_this.props.name === "Ending Track" ? (
                    <VisibilitySensor
                        onChange={_this.props.changeVisibilitySensorState}
                    >
                        <span>{_this.props.name}</span>
                    </VisibilitySensor>
                ) : (
                    <span>{_this.props.name}</span>
                )}
            </Draggable>
        );
    }
}

export default Annotation;
