import React, { Component } from 'react';
import {
    Layout,
    Row,
    Col,
    Icon,
    message,
    Button,
    List,
    Modal,
    Card,
    Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import FooterSection from '../FooterSection';
import { config } from '../../config';
import { reactLocalStorage } from 'reactjs-localstorage';
import ProfileHeader from './ProfileHeader';
import { callapi, deleteapi } from '../../helper';
import moment from 'moment';
import renderHTML from 'react-render-html';
import parser from 'html-react-parser';
import ModalVideo from 'react-modal-video';
import { callApi } from '../../api';

const { Content } = Layout;
const confirm = Modal.confirm;

class FrontEnd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 'profile',
            islogged: config.islogged,
            uid: '',
            user: [],
            savedTracks: [],
            loadingsavedtracks: false,
            isplanover: false,
            notice: [],
            loadingnotice: false,
            plans: [],
            loading: false,
            isOpen: false,
        };

        this.openModal = this.openModal.bind(this);
    }
    minTommss(minutes) {
        const sign = minutes < 0 ? '-' : '';
        const min = Math.floor(Math.abs(minutes));
        const sec = String(minutes).split('.')[1];
        const secs = sec ? `${sec}0` : '00';
        return `${sign} ${min}:${secs}`;
    }

    openModal() {
        this.setState({
            isOpen: true,
        });
    }

    loagout() {
        reactLocalStorage.clear();
        window.location.reload();
        this.setState({
            islogged: false,
        });
    }

    componentDidMount() {
        this.loadSavedTracks();
        this.getNotices();
        this.getuserInfo();
        this.getPlans();
    }

    // get all plans
    getPlans() {
        //getplans
        const _this = this;
        _this.setState({
            loading: true,
        });
        const plans = callApi('plans/purchaseplan');
        plans.then(data => {
            _this.setState({
                plans: data.data.plans && data.data.plans.reverse(),
                loading: false,
            });
        });
    }

    // get all notices
    getNotices() {
        //getNotices
        const _this = this;
        _this.setState({
            loadingnotice: true,
        });
        const notice = callapi('notices');
        notice.then(data => {
            _this.setState({
                notice: data.data.data[0],
                loadingnotice: false,
            });
        });
    }

    getuserInfo() {
        //getuserInfo
        const _this = this;
        const notice = callapi(`users/${config.user._id}`);
        notice.then(data => {
            _this.setState(
                {
                    user: data?.data?.user,
                },
                () => {
                    // this.checkuserPlanDuration(data.data);
                    reactLocalStorage.setObject('user', data.data.user);
                }
            );
        });
    }

    // checking user plan duration which is subscribed
    checkuserPlanDuration(user) {
        // dummy
        // plan_duration: "2"
        // plan_durationtype: "weeks"
        let currentdate = moment();

        if (config.user && config.user.tran_details) {
            let gettran = callapi(
                `transactions/plan/${config.user.tran_details}`
            );

            gettran.then(data => {
                let subscribedat = moment(data.data.data.added_at).add(
                    user.plan.plan_duration,
                    user.plan.plan_durationtype
                );

                if (currentdate.isAfter(subscribedat)) {
                    this.setState({
                        isplanover: true,
                    });
                    Modal.confirm({
                        centered: true,
                        title: 'Hey! your subscription limit has been exceeds.',
                        content: (
                            <div>
                                <p>Please upgrade your plan</p>
                            </div>
                        ),
                        okText: 'Yes I want to Upgrade',
                        onOk() {
                            window.location = '/subscribe';
                        },
                        cancelText: 'No let me decide later.',
                    });
                }
            });
        }
    }

    loadSavedTracks() {
        const _this = this;
        _this.setState({
            loadingsavedtracks: true,
        });
        const getSavedTracks = callApi(
            `users/${config.user._id}/getsavedtracks`
        );

        getSavedTracks.then(data => {
            _this.setState({
                savedTracks: data?.data?.savedTracks || [],
                loadingsavedtracks: false,
            });
        });
    }

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        if (!isJPG) {
            message.error('You can only upload JPG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJPG && isLt2M;
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            console.log('log-info', info);
            // Get this url from response in real world.
            if (info.fileList[0].response.results.data) {
                message.success('Profile picture successfully updated.');
                reactLocalStorage.setObject(
                    'user',
                    info.fileList[0].response.results.data
                );
                this.setState({
                    imageUrl:
                        info.fileList[0].response.results.data.user_profile_pic,
                    loading: false,
                });
            } else {
                message.error(info.fileList[0].response.results.msg);
            }
        }
    };

    editTrack(id, paleteLength) {
        reactLocalStorage.set('currenttrack', id);
        let mixLength = paleteLength ? `/?template=${paleteLength}` : '';
        window.location = '/studio' + mixLength;
    }

    deleteSavedtrack(id, name) {
        const _this = this;
        confirm({
            title: `Are you sure to delete "${name}"?`,
            content: 'You cannot retrieve again once you deleted.',
            okText: 'Delete',
            okType: 'danger',
            icon: (
                <Icon
                    style={{ fontSize: '25px', color: '#ff0000' }}
                    type='delete'
                />
            ),
            onOk() {
                const getSavedTracks = deleteapi(`savedtracks/${id}`);
                getSavedTracks
                    .then(data => {
                        _this.loadSavedTracks();
                        message.success('Track successfully deleted.');
                    })
                    .catch(err => {
                        message.error('Cannot delete track now.');
                    });
            },
            onCancel() { },
        });
    }

    render() {
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className='ant-upload-text'>Upload Image</div>
            </div>
        );
        const imageUrl = this.state.imageUrl
            ? this.state.imageUrl
            : config.user.user_profile_pic;

        // This is the notice without the Iframe, Because I want to use a modal for rendrering the youtube video
        let renderedNotice =
            this.state.notice.notice &&
            renderHTML(`${this.state.notice.notice}`);

        const renderedNoticeWithoutIframe =
            renderedNotice &&
            (Array.isArray(renderedNotice)
                ? renderedNotice.filter(item => item.type !== 'iframe')
                : renderedNotice.replace(/<iframe.*?>.*?<\/iframe>/g, ''));

        //Extracting the Youtube Video ID from the notice
        const renderedYoutube =
            renderedNotice &&
            renderHTML(`${renderedNotice}`)?.filter(
                item => item.type === 'iframe'
            );

        let renderedYoutubeId = '';
        if (renderedYoutube && renderedYoutube.length > 0) {
            const renderedYoutubeUrl = renderedYoutube[0]?.props?.src;
            renderedYoutubeId = renderedYoutubeUrl?.match(/embed\/([^\?]+)/)[1];
        }

        return (
            <Layout className='body-front'>
                <ProfileHeader />
                <Content>
                    <div style={{ padding: 20 }} className='prbg'>
                        <div className='overlay'></div>

                        <div className='profile-ui'>
                            <h3 className='whitetext prWelcome'>Welcome</h3>
                            <h2 className='whitetext pruserName'>
                                {config.user && config.user.full_name
                                    ? config.user.full_name
                                    : 'Update name'}
                            </h2>
                            <h3 className='whitetext prSubtitle'>
                                Making a cheerleading mix has never been easier!
                                Here you will find the latest Cheer Music Maker
                                notifications and saved cheer mix projects.
                                <br />{' '}
                                <span>
                                    {' '}
                                    Start creating your new cheer mix by using
                                    the mix links below!
                                </span>
                            </h3>

                            <div className='profile-actions'>
                                <Tooltip title='Edit Profile'>
                                    <Button
                                        ghost
                                        size='large'
                                        className='largeButton'
                                        shape='circle'
                                        type='default'
                                    >
                                        <Link
                                            to='/edit_profile'
                                            style={{ color: '#fff' }}
                                        >
                                            <Icon type='edit' />
                                        </Link>
                                    </Button>
                                </Tooltip>

                                <Tooltip title='Subscriptions'>
                                    <Button
                                        ghost
                                        size='large'
                                        className='largeButton'
                                        type='dashed'
                                        shape='circle'
                                    // disabled
                                    >
                                        <Link
                                            to='/subscribe'
                                            style={{ color: '#fff' }}
                                        >
                                            <Icon type='usergroup-add' />
                                        </Link>
                                    </Button>
                                </Tooltip>

                                <Tooltip title='Order Custom Vocals'>
                                    <Button
                                        ghost
                                        size='large'
                                        className='largeButton'
                                        type='dashed'
                                        shape='circle'
                                    >
                                        <Link
                                            to='/profile/requested-vocals'
                                            style={{ color: '#fff' }}
                                        >
                                            <Icon type='customer-service' />
                                        </Link>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Row type='flex' justify='center'>
                            <Col xs={{ span: 24 }} lg={{ span: 15 }}>
                                <div style={{ padding: 20 }}>
                                    <Card
                                        className='card-shadow'
                                        loading={this.state.loadingnotice}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <h2 style={{ textAlign: 'center' }}>
                                            {this.state.notice.notice_title}
                                        </h2>
                                        {parser(
                                            `${renderedNoticeWithoutIframe}`
                                        )}

                                        {renderedYoutubeId && (
                                            <>
                                                <div className='thumbnail'>
                                                    <img
                                                        src={`https://img.youtube.com/vi/${renderedYoutubeId}/0.jpg`}
                                                        alt='Youtube Thumbnail'
                                                    />
                                                    <button
                                                        onClick={this.openModal}
                                                        className='video_button'
                                                    >
                                                        <span>
                                                            <Icon type='play-circle' />
                                                        </span>
                                                    </button>
                                                </div>

                                                <ModalVideo
                                                    channel='youtube'
                                                    isOpen={this.state.isOpen}
                                                    videoId={renderedYoutubeId}
                                                    onClose={() =>
                                                        this.setState({
                                                            isOpen: false,
                                                        })
                                                    }
                                                />
                                            </>
                                        )}


                                    </Card>
                                </div>
                                <h2 className='section-header profileSectionHeader'>
                                    Start Creating Your Mix By Choosing Your Mix
                                    Length
                                </h2>
                                <div style={{ padding: 30 }}>
                                    <Row
                                        gutter={10}
                                        type='flex'
                                        justify='space-between'
                                    >
                                        {this.state.plans ? (
                                            this.state.plans.map(
                                                (item, index) => {
                                                    return (
                                                        <Col
                                                            key={index}
                                                            xs={{ span: 24 }}
                                                            md={{ span: 4 }}
                                                            lg={{ span: 4 }}
                                                        >
                                                            <Link
                                                                className='length-btn'
                                                                to={`/studio/?template=${item._id}`}
                                                            >
                                                                {this.minTommss(
                                                                    item.music_duration
                                                                )}
                                                                &nbsp;Mix
                                                            </Link>
                                                        </Col>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div>
                                                No plan available for now.
                                                Please check later
                                            </div>
                                        )}
                                    </Row>
                                </div>
                                <h2 className='section-header profileSectionHeader'>
                                    My Saved Mixes
                                </h2>
                                <div style={{ padding: 30 }}>
                                    <List
                                        itemLayout='vertical'
                                        size='large'
                                        pagination={{
                                            pageSize: 5,
                                        }}
                                        loading={this.state.loadingsavedtracks}
                                        dataSource={this.state.savedTracks}
                                        renderItem={item => {
                                            let trackData = item.track_data;
                                            return (
                                                <List.Item
                                                    actions={[
                                                        <Button
                                                            onClick={() =>
                                                                this.editTrack(
                                                                    item._id,
                                                                    trackData.paleteLength
                                                                )
                                                            }
                                                        >
                                                            Edit
                                                        </Button>,
                                                        <Button
                                                            type='danger'
                                                            onClick={() =>
                                                                this.deleteSavedtrack(
                                                                    item._id,
                                                                    item.track_name
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </Button>,
                                                    ]}
                                                >
                                                    <h4
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <span>
                                                            {item.track_name}
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '1.2rem',
                                                                fontWeight:
                                                                    '500',
                                                                marginLeft:
                                                                    '15px',
                                                                color: '#610094',
                                                            }}
                                                        >
                                                            {item.mix_length}{' '}
                                                            min
                                                        </span>
                                                    </h4>
                                                    <h4>{item.mix_name}</h4>
                                                    <span>
                                                        Created At:{' '}
                                                        {moment(
                                                            item.created_at
                                                        ).format('LLL')}
                                                    </span>
                                                </List.Item>
                                            );
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
                <FooterSection />
            </Layout>
        );
    }
}

export default FrontEnd;
