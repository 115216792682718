import React from "react";
import FooterBG from "../images/footer_bg_top_Asset.png";
import { Button, Col, Divider, Layout, Row } from "antd";
import { config } from "../../config";
import { Fade } from "react-reveal";
const { Footer } = Layout;

const HomeFooter = ({ SignInModal, toggle, sidemenu }) => {
    return (
        <>
            <div className="footer-section">
                <Fade bottom>
                    <Row type="flex" justify="space-between">
                        <Col xs={{ span: 12 }} md={{ span: 3 }}>
                            <div>
                                <img
                                    className="header-logo"
                                    src="/CMM-Logo.png"
                                />
                            </div>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 6 }}>
                            <div className="float-right">
                                <div className="header-dflex-right">
                                    <div className="d-inline" id="header-menu">
                                        <a
                                            onClick={() => SignInModal(true)}
                                            className="color-dark ant-btn-link"
                                        >
                                            LOG IN
                                        </a>
                                        <Divider
                                            style={{
                                                backgroundColor: "#404040",
                                            }}
                                            type="vertical"
                                        />
                                        <a
                                            onClick={() =>
                                                SignInModal(true, "2")
                                            }
                                            className="ant-btn-link"
                                        >
                                            SIGN UP
                                        </a>
                                    </div>

                                    <Button
                                        id="header-menu-toggle"
                                        type="link"
                                        onClick={toggle}
                                        icon={
                                            sidemenu
                                                ? "menu-unfold"
                                                : "menu-fold"
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Fade>
            </div>

            <Footer
                className="footer-main"
                style={{
                    backgroundImage: `url("${FooterBG}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <Fade bottom>{config.copyright}</Fade>

                <Fade bottom>
                    <p style={{ display: "block" }}>
                        <em>
                            Cheer Music Make is a desktop web application and is
                            not Mobile phone compatible
                        </em>
                    </p>
                </Fade>
            </Footer>
        </>
    );
};

export default HomeFooter;
