import React, { Component } from "react";
import { Button, Input, Typography } from "antd";
import ReactAudioPlayer from 'react-audio-player';

class VocalReqCartItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
        };
    }

    render() {
        const { plan } = this.props;
        return (
            <React.Fragment>
                <Typography.Title level={3} type="danger">
                    {plan.plan_name}
                </Typography.Title>
                {plan.has_note && (
                    <Input.TextArea
                        title="Note"
                        placeholder="Add a custom note to you order"
                        defaultValue={this.state.description}
                        onChange={e => {
                            this.setState({
                                description: e.target.value.trim(),
                            });
                        }}
                    />
                )}
                <div className="request-vocal-action">
                    <Typography.Title code>${plan.plan_price}</Typography.Title>

                    {plan.example_audio && (<ReactAudioPlayer
                        src={plan.example_audio}
                        controls
                    />)}

                    <Button
                        size="large"
                        type="primary"
                        onClick={() => {
                            this.props.onSubmit({
                                name: plan.plan_name,
                                description: this.state.description,
                                key: plan._id,
                                price: plan.plan_price,
                                has_note: plan.has_note,
                                stripe_product_id: plan.stripe_product_id,
                                stripe_price_id: plan.stripe_price_id,
                            });
                        }}
                    >
                        Add to cart
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default VocalReqCartItem;
