const HeadPhonesSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 208 208">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M104,9A95,95,0,1,1,9,104,95.11,95.11,0,0,1,104,9m0-9A104,104,0,1,0,208,104,104,104,0,0,0,104,0Z" />
                <path d="M151.71,109V99a47.71,47.71,0,0,0-95.42,0v10A9.51,9.51,0,0,0,50,118v12.67a9.52,9.52,0,0,0,9.5,9.5h3.71a9.5,9.5,0,0,0,9,6.33h6.34A9.52,9.52,0,0,0,88,137V111.67a9.52,9.52,0,0,0-9.5-9.5H72.12a9.49,9.49,0,0,0-9,6.33h-.55V99a39.81,39.81,0,0,1,.27-4.66A9.32,9.32,0,0,0,68,95.83a9.45,9.45,0,0,0,8.9-6.27,28.8,28.8,0,0,1,54.2,0,9.45,9.45,0,0,0,8.9,6.27,9.32,9.32,0,0,0,5.11-1.49,39.81,39.81,0,0,1,.27,4.66v9.5h-.55a9.49,9.49,0,0,0-9-6.33h-6.34a9.52,9.52,0,0,0-9.5,9.5V137a9.52,9.52,0,0,0,9.5,9.5h6.34a9.5,9.5,0,0,0,9-6.33h3.71a9.52,9.52,0,0,0,9.5-9.5V118A9.51,9.51,0,0,0,151.71,109ZM69,111.67a3.17,3.17,0,0,1,3.16-3.17h6.34a3.17,3.17,0,0,1,3.16,3.17V137a3.17,3.17,0,0,1-3.16,3.17H72.12A3.17,3.17,0,0,1,69,137Zm-6.34,22.16H59.46a3.17,3.17,0,0,1-3.17-3.16V118a3.17,3.17,0,0,1,3.17-3.17h3.16ZM140,89.5a3.1,3.1,0,0,1-2.93-2.06,35.13,35.13,0,0,0-66.14,0A3.1,3.1,0,0,1,68,89.5a3.2,3.2,0,0,1-2.93-4.37,41.41,41.41,0,0,1,77.86,0A3.2,3.2,0,0,1,140,89.5ZM139,137a3.17,3.17,0,0,1-3.16,3.17h-6.34a3.17,3.17,0,0,1-3.16-3.17V111.67a3.17,3.17,0,0,1,3.16-3.17h6.34a3.17,3.17,0,0,1,3.16,3.17Zm12.67-6.33a3.17,3.17,0,0,1-3.17,3.16h-3.16v-19h3.16a3.17,3.17,0,0,1,3.17,3.17Z" />
            </g>
        </g>
    </svg>
);

export default HeadPhonesSvg;
