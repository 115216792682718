import React, { Component } from "react";
import { Button, Form, Input, Modal } from "antd";
import { patchapi, postapi } from "../helper";

const FormItem = Form.Item;

class AddPostFormWrapper extends Component {
    constructor(props) {
        super(props);
        // set initial states
        this.state = {
            loading: false,
            posts: []
        }
    }

    // handle submit the form
    handleSubmit = (e) => {
        e.preventDefault();
        let _this = this;
        // validate the form
        _this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                _this.setState({
                    loading: true
                });

                // check if updating the post
                if (_this.props.single) {

                    if (
                        _this.props.single.post_title === values.post_title &&
                        _this.props.single.post_featured_embed === values.post_video_url
                    ) {
                        _this.setState({
                            loading: false,
                        });
                        Modal.info({
                            title: "Success",
                            content: "You didn't made any changes!"
                        });
                    }
                    else {

                        const updatepost = patchapi(`posts/${_this.props.single._id}`, {
                            post_title: values.post_title,
                            post_featured_embed: values.post_video_url,
                        });

                        updatepost.then((data) => {
                            // check the update status
                            if (data.status === 'error' || data.status === 'failed') {
                                Modal.error({
                                    title: "Sorry!",
                                    content: data.msg
                                });
                                _this.setState({
                                    loading: false
                                }, () => {
                                    // call the handle submit from the props
                                    _this.props.handleSubmit(data.data);
                                })
                            }
                            else if (data.status === 'info') {
                                Modal.warning({
                                    title: "Check it out please!",
                                    content: data.msg
                                });
                                _this.setState({
                                    loading: false
                                }, () => {
                                    _this.props.handleSubmit(data);
                                })
                            }
                            else {
                                _this.setState({
                                    loading: false,
                                }, () => {
                                    _this.props.handleSubmit(data);
                                });
                                Modal.success({
                                    title: "Success",
                                    content: data.msg
                                });
                                _this.props.form.resetFields();
                            }
                        })
                    }
                }
                else {
                    // if creating a new post
                    const createpost = postapi('posts', {
                        post_title: values.post_title,
                        post_featured_embed: values.post_video_url,
                    });

                    createpost.then((data) => {
                        if (data.status === 'error' || data.status === 'failed') {
                            Modal.error({
                                title: "Sorry!",
                                content: data.message
                            });
                            _this.setState({
                                loading: false
                            }, () => {
                                _this.props.handleSubmit(data.data);
                            })
                        }
                        else if (data.status === 'info') {
                            Modal.warning({
                                title: "Check it out please!",
                                content: data.msg
                            });
                            _this.setState({
                                loading: false
                            }, () => {
                                _this.props.handleSubmit(data.data);
                            })
                        }
                        else {
                            _this.setState({
                                loading: false,
                            }, () => {
                                _this.props.handleSubmit(data.data);
                            });
                            Modal.success({
                                title: "Success",
                                content: data.msg
                            });
                            _this.props.form.resetFields();
                        }
                    })
                }
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        // set the form layout
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        // set the input layout
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <Form onSubmit={this.handleSubmit}>

                <FormItem
                    {...formItemLayout}
                    label="Post Title"
                >
                    {getFieldDecorator('post_title', {
                        initialValue: this.props.single ? this.props.single.post_title : "",
                        rules: [{
                            required: true, message: 'Please input post title!',
                        }],
                    })(
                        <Input />
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="Post Video URL"
                >
                    {getFieldDecorator('post_video_url', {
                        initialValue: this.props.single ? this.props.single.post_featured_embed : "",
                        rules: [{
                            required: true, message: 'Please input post video url!',
                        }],
                    })(
                        <Input />
                    )}
                </FormItem>

                <FormItem {...tailFormItemLayout}>
                    <Button loading={this.state.loading} type="primary" htmlType="submit">
                        {this.props.single ? "Update" : "Create"} Post
                    </Button>
                </FormItem>
            </Form>
        )
    }

}


const ddPostForm = Form.create()(AddPostFormWrapper);


export default ddPostForm;
