import React, { Component } from "react";
import {
    Layout,
    Row,
    Col,
    Button,
    Modal,
    Table,
    Form,
    Badge,
    Card,
    Collapse,
    Typography,
    message,
} from "antd";
import { Link } from "react-router-dom";

import FooterSection from "../FooterSection";
import { config } from "../../config";
import ProfileHeader from "./ProfileHeader";
import { callapi } from "../../helper";
import moment from "moment";
import VocalReqCartItem from "./VocalReqCartItem";

const { Content } = Layout;

// create the vocal request form
const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
    // eslint-disable-next-line
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                cartitems: [],
            };

            this.cartdesc = this.cartdesc.bind(this);
        }

        addtoCart(name, key, value) {
            this.setState({
                cartitems: {
                    ...this.state.cartitems,
                    [key]: value,
                },
            });
        }

        cartdesc(e, key, price) {
            this.setState({
                cartitems: {
                    ...this.state.cartitems,
                    [key]: {
                        ...this.state.cartitems[key],
                        description: e.target.value,
                        price: price.toFixed(2),
                    },
                },
            });
        }

        ifExistsCart(arr, key) {
            return arr.some(function (el) {
                return el.key === key;
            });
        }

        sumArrayValues(obj) {
            let val = 0;
            for (const x in obj) {
                val += parseFloat(obj[x].price);
            }
            return val.toFixed(2);
        }

        removeCart(key) {
            delete this.state.cartitems[key];
            this.setState({
                cartitems: this.state.cartitems,
            });
        }

        renderCartItems() {
            let items = [];
            for (let i in this.state.cartitems) {
                items.push(
                    <div key={i} className="justify-content-space">
                        <span style={{ width: "70%" }} className="truncate">
                            {this.state.cartitems[i].name}:{" "}
                        </span>
                        <span>${this.state.cartitems[i].price}</span>
                        <Button
                            size="small"
                            type="link"
                            icon="delete"
                            onClick={() => {
                                this.removeCart(i);
                            }}
                        />
                    </div>
                );
            }
            return items;
        }

        render() {
            let _this = this;
            const { cartitems } = this.state;
            const { visible, loading, onCancel, onCreate, form, plans } =
                this.props;
            const { getFieldDecorator } = form;

            return (
                <Modal
                    width="80%"
                    visible={visible}
                    closable={false}
                    maskClosable={false}
                    title={
                        <div className="text-center">
                            <h1 className="text-center">Order Custom Vocals</h1>
                            <small>
                                Order custom vocals to add to your mix before
                                your purchase it.
                            </small>
                        </div>
                    }
                    onCancel={onCancel}
                    footer={[
                        <div key="helptext" className="text-center">
                            <p>
                                All vocals will be recorded by our in house male
                                vocalist. Your recorded vocals will be located
                                in the cheer music library "requested vocals".
                                You will be notified via email when your custom
                                vocals are ready.
                            </p>
                        </div>,
                        <Button key="back" onClick={onCancel}>
                            Cancel
                        </Button>,
                        <Link
                            key="checkout"
                            className="ant-btn ant-btn-primary"
                            to={{
                                pathname: "/profile/checkout-vocal-request",
                                state: {
                                    cartitems: cartitems,
                                    total: this.sumArrayValues(cartitems),
                                },
                            }}
                        >
                            Checkout
                        </Link>,
                    ]}
                >
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                            <Collapse defaultActiveKey={["1"]}>
                                {plans.map((pitem, pindex) => {
                                    return (
                                        <Collapse.Panel
                                            key={pindex + 1}
                                            header={pitem.plan_name}
                                        >
                                            <VocalReqCartItem
                                                index={pindex}
                                                plan={pitem}
                                                onSubmit={cartata => {
                                                    this.addtoCart(
                                                        "cartitems",
                                                        pitem._id,
                                                        cartata
                                                    );
                                                }}
                                            />
                                        </Collapse.Panel>
                                    );
                                })}
                            </Collapse>

                            <Row className="mb-3">
                                <div className="text-center">
                                    Please allow 3-5 Business days to receive
                                    your custom vocals
                                </div>
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                            <div className="pl-5 pr-5">
                                <Card
                                    size="small"
                                    title={
                                        <h4 className="text-center">Cart</h4>
                                    }
                                    actions={[
                                        <h4 className="text-center">
                                            Total:{" "}
                                            {this.sumArrayValues(cartitems)}
                                        </h4>,
                                    ]}
                                >
                                    <div>{this.renderCartItems()}</div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Modal>
            );
        }
    }
);

// the base wrapper class
class RequestedVocals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: "profile",
            islogged: config.islogged,

            requestmodal: false,
            requesting: false,

            loadingVocals: false,
            vocals: [],
            carttotal: 0,

            vocalrequestplan: [],
        };
    }

    componentWillMount() {
        this.getuserVocals();
    }

    loadVocalreqPlan() {
        this.setState({
            loadingvrp: true,
        });
        callapi("vocalplans").then(resp => {
            this.setState(
                {
                    vocalrequestplan: resp.data.data.reverse(),
                },
                () => {
                    this.setState({
                        loadingvrp: false,
                    });
                }
            );
        });
    }

    getuserVocals() {
        this.setState({
            loadingVocals: true,
        });

        const getVocals = callapi(`vocalrequests/${config.user._id}/vocalsrequests`);

        getVocals.then(resp => {
            if (resp.status === "success") {
                this.setState({
                    loadingVocals: false,
                    vocals: resp.data.vocalRequests,
                });
            } else if (resp.status === "error" || resp.status === "failed") {
                this.setState({
                    loadingVocals: false,
                });

                message.warn(resp.message)
            }
            else {
                this.setState({
                    loadingVocals: false,
                });
            }
        });
    }

    toggleRequestmodal = () => {
        const { form } = this.formRef.props;
        this.loadVocalreqPlan();
        this.setState(
            {
                requestmodal: !this.state.requestmodal,
            },
            () => {
                form.resetFields();
            }
        );
    };

    cartTotal(total) {
        this.setState({
            carttotal: total,
        });
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Layout className="body-front">
                <ProfileHeader
                    className="topMenu"
                    onClick={this.handleClick}
                    selectedKeys={[this.state.current]}
                    mode="horizontal"
                />
                <Content>
                    <div style={{ marginBottom: 100 }}>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <div style={{ padding: 30 }}>
                                    <Link to={`profile/${config.user._id}`}>
                                        Back to profile
                                    </Link>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <div
                                    style={{ padding: 30, textAlign: "center" }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={this.toggleRequestmodal}
                                    >
                                        Order Custom Vocals
                                    </Button>
                                    <CollectionCreateForm
                                        cartTotal={total => {
                                            this.cartTotal(total);
                                        }}
                                        plans={this.state.vocalrequestplan}
                                        wrappedComponentRef={this.saveFormRef}
                                        visible={this.state.requestmodal}
                                        loading={this.state.requesting}
                                        onCancel={this.toggleRequestmodal}
                                        onCreate={this.handleCreateVocalRequest}
                                    />
                                    <h2>My Vocals</h2>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <div
                                    style={{ padding: 30, textAlign: "right" }}
                                ></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                <div
                                    style={{ padding: 30, background: "#fff" }}
                                >
                                    <Table
                                        loading={this.state.loadingVocals}
                                        dataSource={this.state.vocals}
                                        rowKey="_id"
                                        columns={[
                                            {
                                                title: "Requested for",
                                                dataIndex: "requested_for",
                                                key: "requested_for",
                                                render: (text, record) => {
                                                    let thedata = record.requested_for
                                                    return (
                                                        <div>
                                                            <Typography.Title
                                                                level={3}
                                                            >
                                                                Vocal Requested:{" "}
                                                                {thedata.name}
                                                            </Typography.Title>
                                                            <Typography.Text type="secondary">
                                                                Status:{" "}
                                                                {
                                                                    thedata.description
                                                                }
                                                            </Typography.Text>
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: "Added at",
                                                dataIndex: "added_at",
                                                key: "added_at",
                                                render: (text, record) => {
                                                    return moment(text).format(
                                                        "LLL"
                                                    );
                                                },
                                            },
                                            {
                                                title: "Status",
                                                dataIndex: "status",
                                                key: "status",
                                                render: (text, record) => {
                                                    if (text === 1) {
                                                        return (
                                                            <Badge
                                                                count={
                                                                    "Track Uploaded"
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        "#52c41a",
                                                                }}
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <Badge
                                                                count={
                                                                    "Processing"
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        "rgba(255,73,11,0.7)",
                                                                }}
                                                            />
                                                        );
                                                    }
                                                },
                                            },
                                            {
                                                title: "Updated at",
                                                dataIndex: "updated_at",
                                                key: "updated_at",
                                                render: (text, record) => {
                                                    return (
                                                        text &&
                                                        moment(text).format(
                                                            "LLL"
                                                        )
                                                    );
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
                <FooterSection />
            </Layout>
        );
    }
}

export default RequestedVocals;
