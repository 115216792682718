import React, { useState } from "react";
import { Form, Icon, Input, Button, Checkbox, message } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { postApi } from "../api";

const FormItem = Form.Item;

const SignInForm = ({ form, islogged, SignInModal }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);

                const dologin = postApi(
                    "users/login",
                    {
                        username: values.userName,
                        password: values.password,
                    },
                    false
                );

                dologin.then(data => {
                    if (data.status === "failed" || data.status === "error") {
                        message.error(data.message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        reactLocalStorage.setObject("user", data.data.user);
                        reactLocalStorage.set("islogged", true);
                        reactLocalStorage.set("token", data.token);
                        islogged(true);
                        SignInModal(false);
                        window.location = "/profile/" + data.data.user._id;
                    }
                });
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <div>
            <div className="logo-container">
                <img src="/CMM-Logo.png" alt="Cheer Music Maker Logo" />
            </div>
            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="login-form"
            >
                <FormItem>
                    {getFieldDecorator("userName", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your username!",
                            },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="mail"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                />
                            }
                            placeholder="Email / Username"
                        />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator("password", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                />
                            }
                            type="password"
                            placeholder="Password"
                        />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator("remember", {
                        valuePropName: "checked",
                        initialValue: true,
                    })(<Checkbox className="dispBlock">Remember me</Checkbox>)}
                    <a className="login-form-forgot dispBlock" href="">
                        Forgot password
                    </a>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        Log in
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
};

const SignIn = Form.create()(SignInForm);

export default SignIn;
