import React, { Component } from 'react'
import { Row, Col, Button, } from 'antd';

class AdminLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signature: false,
            signatureadmin: false,
            pdflicense: false,
            signatureuesr: false,
            license: [],
            savingSig: false,
            generatingLicense: false,
            numPages: null,
        };

        this.downloadPDFfromURL = this.downloadPDFfromURL.bind(this);
        this.downloadWavFromURL = this.downloadWavFromURL.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);

    }

    componentDidMount() {
        const _this = this;
        // load initials
    }

    componentWillUnmount() {
        const _this = this;

        const abortController = new AbortController();
        abortController.abort();
    }

    downloadPDFfromURL(url) {
        const _this = this;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status == 200) {
                var blob = new Blob([this.response], { type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'license.pdf';
                link.click();
            }
        };
        xhr.send();
    }

    downloadWavFromURL(url) {
        const _this = this;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status == 200) {
                var blob = new Blob([this.response], { type: 'audio/wav' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'audio.wav';
                link.click();
            }
        };
        xhr.send();
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }


    render() {
        let _this = this;

        const { license } = this.props;
        console.log('log-license', license);

        return (
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Button type='primary' target='_blank'
                        onClick={() => {
                            this.downloadWavFromURL(license.renderedfile);
                        }}
                    >Download Mix</Button>

                    {license.license_link && <Button style={{
                        marginTop: '10px'
                    }} type='primary' target='_blank'
                        onClick={() => {
                            this.downloadPDFfromURL(license.license_link);
                        }}
                    >Download License</Button>}
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                    <div id='previewpane' style={{
                        height: '100vh'
                    }}>
                        {license.license_link ?
                            // render the pdf from the url
                            (<div style={{
                                height: '100%'
                            }}>
                                <iframe src={encodeURI(license.license_link)}
                                    title='license'
                                    width='100%'
                                    height='100%'
                                    onLoad={() => {
                                        this.setState({ pdflicense: true });
                                    }}
                                />
                            </div>)
                            :
                            (
                                <p>
                                    No license found
                                </p>
                            )
                        }
                    </div>
                </Col>
            </Row>
        )
    }

}

export default AdminLicense
