import React, { Component } from 'react'
import { Layout, Menu, Icon, Button, Input, Modal, message, Upload, Spin, Pagination } from 'antd';
import { config } from "../config";
import { reactLocalStorage } from "reactjs-localstorage";
import { callapi } from "../helper";
import CreateSupport from "./CreateSupport";
import cmm8countPdf from '../assets/CMM-8countsheet.pdf';
import { Document, Page } from 'react-pdf';

const { Header } = Layout;


class StudioHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: [],
            loadingUser: false,
            supportmodal: false,
            countSheetModal: false,
            numOfPages: 1,
            pageNumber: 1,
            pdfFile: reactLocalStorage.get('8countsheet') || cmm8countPdf,
            isUploading: false,
        }

        this.renderLoader = this.renderLoader.bind(this);
        this.setTotalPage = this.setTotalPage.bind(this);
    }

    componentDidMount() {
        this.getuserInfo();
    }

    renderLoader() {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    }

    setTotalPage(totalPage) {
        this.setState({
            numOfPages: totalPage,
        });
    }

    async getuserInfo() {
        this.setState({
            loadingUser: true,
        });
        //getuserInfo
        const _this = this;
        const notice = callapi(`users/${config.user._id}`,);
        await notice.then((data) => {
            _this.setState({
                user: data.data.user,
                loadingUser: false,
            }, () => {
                reactLocalStorage.setObject('user', data.data.user);
            })
        })
    }

    // initiate the exit studio
    exitStudio() {
        let _this = this;
        // check if user is logged in
        if (config.user && _this.props?.tracksList?.length > 0) {
            const isadmin = !!(config.user && config.user.user_type === 'admin')

            Modal.confirm({
                title: 'Leaving so soon? Please make sure to save your  mix first!',
                content: 'If you have not saved your mix, you will lose all your unsaved changes. Please save your mix before exiting the Cheer Music Maker Studio.',
                okText: 'Already saved it!, Exit Cheer Music Maker Studio',
                cancelText: "Okay, I'll save my mix first.",
                onOk() {
                    reactLocalStorage.set('currenttrack', undefined);
                    window.location = isadmin ? 'admin/#/tracks' : `/profile/${config.user._id}`;
                },
                onCancel() {
                },
                maskClosable: true,
                width: '55%',
            });
        } else {
            if (_this.props.tracksList && _this.props.tracksList.length > 0) {
                Modal.warning({
                    title: 'Are you sure you want to exit studio?',
                    content: 'All current data will be missing.',
                    onOk() {
                        window.location = `/profile/${config.user._id}`;
                    },
                    onCancel() {
                    },
                });
            } else {
                window.location = `/profile/${config.user._id}`;
            }
        }

    }

    // handle menu click
    handleClick = (e) => {
        // if create new item clicked
        if (e.key === 'createnew') {
            this.props.newDocument()
        }

        // if save clicked
        if (e.key === 'save') {
            this.getuserInfo()
                .then(() => {
                    if (this.state.user.plan.totalmix !== 0 && this.state.user.totalsavedtrack === this.state.user.plan.totalmix) {
                        message.error('You have exceeded your mix project limit.')
                    } else {
                        if (this.props.isUpdating) {
                            this.props.updatetrack()
                        } else {
                            this.props.savetrack()
                        }
                    }
                })
        }

        // if download clicked
        if (e.key === 'download') {
            this.props.download()
        }

        // if how-to clicked
        if (e.key === 'how-to') {
            this.props.helpTutorial()
        }

        // if support clicked
        if (e.key === 'support') {
            this.setState({
                supportmodal: true
            })
        }

        //if court sheet clicked
        if (e.key === 'count-sheet') {
            this.setState({
                countSheetModal: true
            })
        }

    }

    handleOrderCustomVocalClick = (e) => {
        const currentTrack = reactLocalStorage.get('currenttrack');

        // if user logged in or not
        if (config.user) {
            if (currentTrack || this.props.tracksList.length > 0) {
                // if track is not saved promt user to save the track
                Modal.confirm({
                    title: 'Please make sure to save your music mix first!',
                    content: 'If you have not saved your mix, you will lose all your unsaved changes. Please save your mix before ordering custom vocals.',
                    onOk() {
                        window.location = '/profile/requested-vocals';
                    },
                    onCancel() {
                    },
                    cancelText: 'Okay, I will save my track first',
                    okText: 'Saved! Let’s order some custom vocals',
                    maskClosable: true,
                    width: '50%',
                });
            } else {
                window.location = '/profile/requested-vocals';
            }
        } else {
            window.location = '/';
        }

    }

    render() {
        // check if logged in user is an admin or not
        const isadmin = !!(config.user && config.user.user_type === 'admin')

        return (
            <Header className='studioheader'>
                <div className='studioLogo'>
                    <img src='/CMM-Logo.png' alt='CheerMp3 Studio' />
                </div>
                <Menu
                    onClick={this.handleClick}
                    mode="horizontal"
                >
                    <Menu.Item key="support" className='studio-menu'>
                        Support
                    </Menu.Item>
                    <Menu.Item
                        key="how-to"
                        className='studio-menu'
                    >
                        <a>How-To Tutorials</a>
                    </Menu.Item>
                    <Menu.Item key="count-sheet">
                        8 Count Sheet
                    </Menu.Item>
                </Menu>
                <div className='btngroup'>

                    <Input
                        style={{ marginRight: 20 }}
                        value={this.props.currentname}
                        onChange={(e) => {
                            this.props.savename(e.target.value)
                        }} className='songtitle' placeholder="Program Name" />
                    <Input
                        style={{ marginRight: 20 }}
                        value={this.props.mix_name}
                        onChange={(e) => {
                            this.props.savemix_name(e.target.value)
                        }} className='songtitle' placeholder="Team Name" />

                    <Button
                        type="primary"
                        style={{ marginRight: 20 }}
                        onClick={() => {
                            this.getuserInfo()
                                .then(() => {
                                    // check if plan mix quota over or not
                                    // if (!this.state.user.plan){
                                    //     Modal.confirm({
                                    //         title: "Sorry currently you doesn't have any subscribed plan",
                                    //         content: "Click 'Ok' to go subscription page.",
                                    //         onCancel() {},
                                    //         onOk() {
                                    //             window.location = '/subscribe'
                                    //         },
                                    //     });
                                    //     return;
                                    // }

                                    if (this.props.isUpdating) {
                                        this.props.updatetrack()
                                    } else {

                                        this.props.savetrack()
                                    }
                                })
                        }}
                    >Save</Button>

                    {
                        !isadmin &&
                        <Button
                            type="primary"
                            icon='download'
                            loading={this.state.loadingUser}
                            disabled={this.state.loadingUser}
                            // disabled
                            onClick={() => {
                                this.props.download()
                            }}
                        >Download</Button>
                    }
                    <Button
                        loading={this.state.loadingUser}
                        disabled={this.state.loadingUser}
                        // disabled
                        type='primary'
                        style={{ marginLeft: 20, color: "#fff" }}
                        onClick={this.handleOrderCustomVocalClick}
                    >
                        Order Custom Vocals
                    </Button>
                </div>

                <div className='extraAction' style={{ textAlign: 'center', padding: '0 10px' }}>
                    <Button type='primary' onClick={() => {
                        this.exitStudio()
                    }}><Icon type="logout" />Exit Music Maker</Button>
                </div>

                <Modal
                    title={`Contact Support`}
                    visible={this.state.supportmodal}
                    width="50%"
                    onOk={() => {
                        this.setState({
                            supportmodal: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            supportmodal: false,
                        });
                    }}
                    maskClosable={false}
                    footer={null}
                >
                    <h4 style={{ textAlign: 'center' }}>Have questions about using the Cheer Music Maker?</h4>
                    <h4 style={{ textAlign: 'center' }}>Please let us know how we can help. We would love to hear from you!</h4>
                    <CreateSupport
                        onSuccess={() => {
                            this.setState({
                                supportmodal: false
                            });
                        }}
                        onCancel={() => {
                            this.setState({
                                supportmodal: false
                            });
                        }}
                    />
                </Modal>

                <Modal
                    visible={this.state.countSheetModal}
                    width="80%"
                    onOk={() => {
                        this.setState({
                            countSheetModal: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            countSheetModal: false,
                        });
                    }}
                    maskClosable
                    footer={null}
                >
                    <h2 style={{ textAlign: 'center' }}>Upload your own 8 count sheets or use ours here</h2>
                    <h4 style={{ textAlign: 'center' }}>
                        <a target="_blank" href="https://8countsheets.com/users/CheerMusicMaker">Cheer Music Maker 8 count sheet</a>
                    </h4>

                    <h4 style={{ textAlign: 'center' }}>Once you have created your 8 count sheet upload it below</h4>

                    {/* getting error: err_blocked_by_client */}
                    {this.state.pdfFile &&
                        <Document
                            error="Failed to load PDF. If you have adblock enabled, Please disable it and try again later!!"
                            className="pdf-document"
                            loading={this.renderLoader}
                            file={this.state.pdfFile}
                            onLoadError={console.error}
                            onLoadSuccess={({ numPages }) => this.setTotalPage(numPages)}
                        >
                            <Page
                                pageNumber={this.state.pageNumber}
                                onLoadSuccess={(page) => this.setState({ pageNumber: page.pageNumber })}
                                className="pdf-page"
                            />
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <p>Page {this.state.pageNumber} of {this.state.numOfPages}</p>
                                {this.state.numOfPages > 1 &&
                                    <Pagination
                                        pageSize={1}
                                        total={this.state.numOfPages}
                                        defaultCurrent={this.state.pageNumber}
                                        onChange={(page, pageSize) => this.setState({
                                            pageNumber: page,
                                        })}
                                    />}
                            </div>
                        </Document>
                    }


                    {/* Meed to make this upload option work. When uploaded a post request will be sent to the action URL, Set up that url in the backend so that it recieves the PDF and saves the PDF on the server!! 
                    Then returns a direct link of that PDF file, which can be set as a state pdf file */}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem" }}>
                        <Upload
                            action={`${config.apiurl}savedTracks/upload8countsheet`} //A URL that receives the file data
                            method="POST"
                            accept="application/pdf"
                            key="pdfFile"
                            multiple={false}
                            showUploadList={false}
                            headers={
                                {
                                    'Authorization': `Bearer ${config.authKey}`
                                }
                            }
                            name="file"
                            beforeUpload={(file) => {
                                this.setState({
                                    isUploading: true,
                                });

                                const isPDF = file.type === 'application/pdf';
                                if (!isPDF) {
                                    message.error('You can only upload PDF file!');

                                    this.setState({
                                        isUploading: false,
                                    });
                                }
                                return isPDF;
                            }}
                            onChange={(info) => {
                                const status = info.file.status;
                                if (status !== 'uploading') {
                                    console.log(status);
                                }

                                if (status === 'done') {
                                    const resp = info.file.response;
                                    if (resp.status === 'success') {
                                        reactLocalStorage.set('8countsheet', resp.data.filePath);
                                        this.setState({
                                            pdfFile: resp.data.filePath,
                                            isUploading: false,
                                        })

                                        message.success(`${info.file.originFileObj.name} file uploaded successfully.`);
                                    }
                                    else {
                                        this.setState({
                                            isUploading: false
                                        })
                                        message.error(`${info.file.originFileObj.name} - ${resp.message}.`);
                                    }
                                } else if (status === 'error' || status === 'failed') {
                                    this.setState({
                                        isUploading: false
                                    })
                                    message.error(`${info.file.originFileObj.name} file upload failed.`);
                                }
                            }}

                        >
                            <Button type="primary" loading={this.state.isUploading}>
                                <Icon type="upload" /> Click or Drag Here to Upload PDF
                            </Button>
                        </Upload>
                    </div>



                </Modal>

            </Header>
        )
    }

}

export default StudioHeader
