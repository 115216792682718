import React, { Component } from 'react'
import { Layout, Icon, Button, Menu } from 'antd';

import {
    HashRouter as Router,
    Route,
    Link
} from "react-router-dom";
import Dashboard from "./Dashboard";
import UploadTracks from "./UploadTracks";
import { reactLocalStorage } from 'reactjs-localstorage'
import Plans from "./Plans";
import Categories from "./Categories";
import Subscription from "./Subscription";
import Tracks from "./Tracks";
import Notices from "./Notices";
import VocalRequests from "./VocalRequests";
import Users from "./Users";
import ProfileUpdate from "./ProfileUpdate";
import Coupon from "./Coupon";
import Ads from "./Ads";
import Reports from "./Reports";
import SubscriptionNotices from "./SubscriptionNotices";
const { Header, Footer, Sider, Content } = Layout;


const routes = [
    {
        path: '/',
        exact: true,
        menuName: 'Dashboard',
        menuIcon: <Icon type="appstore" />,
        sidebar: () => <div>Dashboard</div>,
        content: () => <Dashboard />
    },
    {
        path: '/upload_tracks',
        menuName: 'Upload Tracks',
        menuIcon: <Icon type="to-top" />,
        sidebar: () => <div>UploadTracks</div>,
        content: () => <UploadTracks />
    },
    {
        path: '/plans',
        menuName: 'Plans',
        menuIcon: <Icon type="switcher" />,
        sidebar: () => <div>Plans</div>,
        content: () => <Plans />
    },
    {
        path: '/coupons',
        menuName: 'Coupons',
        menuIcon: <Icon type="switcher" />,
        sidebar: () => <div>Coupons</div>,
        content: () => <Coupon />
    },
    {
        path: '/categories',
        menuName: 'Categories',
        menuIcon: <Icon type="switcher" />,
        sidebar: () => <div>Categories</div>,
        content: () => <Categories />
    },
    {
        path: '/subscriptions',
        menuName: 'Subscriptions',
        menuIcon: <Icon type="wallet" />,
        sidebar: () => <div>Subscriptions</div>,
        content: () => <Subscription />
    },
    {
        path: '/subscription_notices',
        menuName: 'Subscription Notices',
        menuIcon: <Icon type="wallet" />,
        sidebar: () => <div>Subscription Notices</div>,
        content: () => <SubscriptionNotices />
    },
    {
        path: '/tracks',
        menuName: 'User mixes',
        menuIcon: <Icon type="sliders" />,
        sidebar: () => <div>User mixes</div>,
        content: () => <Tracks />
    },
    {
        path: '/vocal_requests',
        menuName: 'Vocal Requests',
        menuIcon: <Icon type="file-sync" />,
        sidebar: () => <div>Vocal Requests</div>,
        content: () => <VocalRequests />
    },
    {
        path: '/reports',
        menuName: 'Reports',
        menuIcon: <Icon type="file-sync" />,
        sidebar: () => <div>Reports</div>,
        content: () => <Reports />
    },
    {
        path: '/users',
        menuName: 'Users',
        menuIcon: <Icon type="team" />,
        sidebar: () => <div>Users</div>,
        content: () => <Users />
    },
    {
        path: '/notices',
        menuName: 'Manage Notices',
        menuIcon: <Icon type="alert" />,
        sidebar: () => <div>Manage notices</div>,
        content: () => <Notices />
    },
    {
        path: '/ads',
        menuName: 'Manage Ads',
        menuIcon: <Icon type="carry-out" />,
        sidebar: () => <div>Manage Ads</div>,
        content: () => <Ads />
    },
    {
        path: '/profile',
        menuName: 'Profile',
        menuIcon: <Icon type="user" />,
        sidebar: () => <div>Profile Update</div>,
        content: () => <ProfileUpdate />
    },
];

class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
            openKeys: ['sub1']
        }
    }

    componentWillMount() {
        //console.log(reactLocalStorage.get('user'))
    }

    onOpenChange = (openKeys) => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    }

    logout() {

        //console.log('cl')
        reactLocalStorage.clear('user');
        window.location = '/login'
    }

    render() {
        return (
            <Router>
                <Layout>
                    <Sider
                        className="sidebar-admin">
                        <div className='sidebarheader'></div>
                        <Menu
                            mode="inline"
                            openKeys={this.state.openKeys}
                            onOpenChange={this.onOpenChange}
                            style={{ width: "100%" }}
                        >
                            {routes.map((route, index) => (
                                <Menu.Item key={index}>
                                    <Link to={route.path} >
                                        {route.menuIcon}
                                        <span className="nav-text">{route.menuName}</span>
                                    </Link>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Sider>
                    <Layout style={{ marginLeft: 200 }}>
                        <Header className='header-admin'>
                            <div style={{
                                float: 'right'
                            }}>
                                <Button type="primary" shape="circle" icon="logout"
                                    onClick={() => {
                                        this.logout()
                                    }}
                                />
                            </div>
                        </Header>
                        <Content style={{ padding: 20 }}>
                            {
                                routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.content}
                                    />
                                ))
                            }
                        </Content>
                        <Footer style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}><span>@ Copyright - All rights reserved to Cheer Music Maker</span>
                        </Footer>
                    </Layout>
                </Layout>
            </Router>
        )
    }

}

export default Admin
