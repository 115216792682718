import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const ReportBarChart = ({ data /* see data tab */ }) => {
    const startDate = moment(data[0]?.month_date).format('MMM-YYYY');
    const endDate = moment(data[data?.length - 1]?.month_date).format(
        'MMM-YYYY'
    );

    return (
        <ResponsiveBar
            data={data}
            keys={[
                'sales',
                'tracksMade',
                'users',
                'purchase',
                'vocalsRequested',
            ]}
            indexBy='month'
            margin={{ top: 50, right: 150, bottom: 100, left: 60 }}
            padding={0.2}
            colors={{ scheme: 'paired' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Month',
                legendPosition: 'middle',
                legendOffset: 40,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: `Report From ${startDate} to ${endDate}`,
                legendPosition: 'middle',
                legendOffset: -50,
            }}
            groupMode='stacked'
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            label={({ value, color }) => <tspan fill={color}>{value}</tspan>}
            tooltip={({ id, value, color, indexValue }) => (
                <div
                    style={{
                        backgroundColor: '#072227',
                        padding: '10px',
                        borderRadius: '2px',
                        color,
                    }}
                >
                    {/* display a grid like layout */}
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '50% 50%',
                            gridGap: '10px',
                            gridColumnGap: '10px',
                            width: '250px',
                        }}
                    >
                        <div>
                            <span style={{ textTransform: 'capitalize' }}>
                                {id}
                            </span>
                            <br />
                            <span>Month</span>
                        </div>
                        <div>
                            <span>{`${
                                id === 'sales' ? `${value}$` : value
                            }`}</span>
                            <br />
                            <span>{indexValue}</span>
                        </div>
                    </div>
                </div>
            )}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            role='application'
            ariaLabel='Bar chart for sales data'
            barAriaLabel={function (e) {
                return (
                    e.id +
                    ': ' +
                    e.formattedValue +
                    ' in country: ' +
                    e.indexValue
                );
            }}
        />
    );
};

export default ReportBarChart;
