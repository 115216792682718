import React, { Component } from 'react'


class Annotations extends Component {
    constructor(props) {
        super(props)

        this.state = {
            draggingannotation: false
        }
    }

    componentDidMount() {
        this.createSampler()
    }


    createSampler() {
        const _this = this;
        const sampler = document.getElementById('sampler');
        const middleheader = document.getElementById('timeline-content');
        let offsetleft = middleheader.offsetLeft;

        var div = document.getElementById('samplerpop'),
            x1 = 0,
            x2 = 0;

        let start = 0;
        let end = 0;

        const reCalc = () => {
            var x3 = Math.min(x1, x2);
            var x4 = Math.max(x1, x2);

            let lefto = Math.round(x3 - offsetleft + this.props.scrollLeft());
            let widtho = Math.round(x4 - x3);

            if (widtho > 12) {
                div.style.left = lefto + 'px';
                div.style.width = widtho + 'px';
                div.style.height = '20px';
                start = lefto;
                end = widtho / _this.props.zoom;
            }
        }

        sampler.addEventListener('mousedown', (e) => {
            e.preventDefault();
            if (e.button === 0) {
                _this.setState({
                    draggingannotation: true
                })
                div.style.display = 'block';
                x1 = e.clientX;
                if (_this.state.draggingannotation) {
                    reCalc();
                }
            }
        });

        sampler.addEventListener('mousemove', (e) => {
            e.preventDefault();
            x2 = e.clientX;
            if (e.button === 0) {
                if (_this.state.draggingannotation) {
                    reCalc();
                }
            }
        });

        sampler.addEventListener('mouseup', (e) => {
            e.preventDefault();
            if (e.button === 0) {
                div.style.display = 'none';
                div.style.width = 0;
                if (_this.state.draggingannotation) {
                    if (start > 2) {
                        _this.props.onChange(start, end);
                        start = 0;
                    }
                }
                _this.setState({
                    draggingannotation: false,
                });
            }
        });
    }

    render() {
        return (
            <div id='sampler' className='sampler-selection' style={{ width: this.props.width + 15 }}>
                <div id='samplerpop' className='samplerpop'></div>
            </div>
        )
    }

}

export default Annotations
