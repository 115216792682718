import React, { Component } from 'react'
import { Button, Card, Col, Form, Icon, Input, Layout, message, Modal, Row, Tooltip } from 'antd';
import { Link } from "react-router-dom";

import FooterSection from "../FooterSection";
import { config } from "../../config";
import ProfileHeader from "./ProfileHeader";
import { postapi } from "../../helper";
import { loadStripe } from "@stripe/stripe-js";

const { Content } = Layout;
const stripePromise = loadStripe(config.stripeApikey);


class CheckoutVocalwrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isloadingbrain: false,
            clientToken: false,
            // squareup
            loaded: false,
            nonce: false,
            // stripe payment
            paymentMethod: false,
            creatingPayment: false,

        };

        // this.handlePayment = this.handlePayment.bind(this);
        this.handleNewPayment = this.handleNewPayment.bind(this);
    }

    componentDidMount() {
        let thename = config.user.full_name;
        if (!thename) {
            Modal.confirm({
                title: 'Sorry! You are missing your full name.',
                content: 'Please update your profile with full name to complete the purchase.',
                onOk() {
                    window.location = '/edit_profile';
                },
                onCancel() {
                    window.location = '/profile/' + config.user._id;
                },
            });
        }

        const that = this;
    }

    handleCancelPayment() {
        const _this = this;
        // profile/requested-vocals
        Modal.confirm({
            title: "Are you sure?",
            content: "You really wants to cancel this payment?",
            onOk() {
                _this.setState({
                    single: false,
                    loadingpayment: false,
                    clientToken: false,
                    paymentModal: false,
                    paymentMethod: false
                });
                window.location = "/profile/requested-vocals";
            },
            onCancel() {
            },
        });
    }

    async handleNewPayment(e) {
        e.preventDefault();
        const _this = this;

        _this.setState({
            creatingPayment: true
        });

        const values = this.props.form.getFieldsValue();
        let thename = config.user ? config.user.full_name : values.full_name;

        const item = {
            uid: config.user ? config.user._id : -1,
            full_name: thename,
            email: values.email,
            items: _this.props.location.state.cartitems,
            paymentData: {}
        };

        const { data } = await postapi(`transactions/stripe_checkout_session`, {
            item,
            type: 'vocal_request',
            url: window.location.href
        })

        // continue to checkout page
        if (data?.session) {
            stripePromise.then(async stripe => {
                const { session } = data;
                await stripe.redirectToCheckout({
                    sessionId: session,
                });
            });
        }

        _this.setState({
            creatingPayment: false,
        });

    }

    renderCartItems() {
        let crats = this?.props?.location?.state?.cartitems;
        let items = [];
        for (let i in crats) {
            items.push(
                <div key={i} className='justify-content-space'>
                    <span style={{ width: '70%' }} className='truncate'>{crats[i]?.name}: </span>
                    <span>${crats[i]?.price}</span>
                </div>);
        }

        return items;
    }

    render() {
        const _this = this;
        const { getFieldDecorator } = this.props?.form;
        return (
            <Layout className='body-front'>
                <ProfileHeader
                    className='topMenu'
                    onClick={this.handleClick}
                    selectedKeys={[this.state.current]}
                    mode="horizontal"
                />
                <Content>
                    <div style={{ marginBottom: 100 }}>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <div style={{ padding: 30 }}>
                                    {
                                        config.user &&
                                        <Link to={`/profile/requested-vocals`}>Back to Requested vocals</Link>
                                    }
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <div style={{ padding: 30 }}>
                                    <h2 style={{ textAlign: 'center' }}>Checkout now</h2>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>

                            </Col>
                        </Row>
                        <Row type="flex" justify="center">
                            <Col xs={{ span: 24 }} lg={8}>
                                <Card size="small" title={<h4 className='text-center'>Cart</h4>}
                                    actions={[<h4
                                        className='text-center'>Total: {this.props?.location?.state?.total}</h4>]}
                                >
                                    <div>
                                        {
                                            this.renderCartItems()
                                        }
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} lg={6}>
                                <Card size="small" title={<h4 className='text-center'>Payment</h4>}>
                                    {/* <StripePaymentForm price={_this.props.location.state.total}
                                        onSuccess={(paymentMethod) => {
                                            _this.setState({
                                                paymentMethod: paymentMethod
                                            });
                                        }} /> */}
                                    <form onSubmit={this.handleNewPayment}>
                                        {
                                            !config.user &&
                                            <Form.Item
                                                label={(
                                                    <span>Full Name&nbsp;
                                                        <Tooltip title="What do you want others to call you?">
                                                            <Icon type="question-circle-o" />
                                                        </Tooltip>
                                                    </span>
                                                )}
                                            >
                                                {getFieldDecorator('full_name', {
                                                    rules: [{
                                                        required: true,
                                                        message: 'Please give your full name',
                                                        whitespace: true
                                                    }]
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        }

                                        <Form.Item
                                            label='Email'
                                        >
                                            {getFieldDecorator('email', {
                                                rules: [{
                                                    type: 'email',
                                                    required: true,
                                                    message: 'Please give your email where to send the requested vocal.',
                                                    whitespace: true
                                                }]
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>

                                        <Form.Item>
                                            <Button loading={this.state.creatingPayment}
                                                type='primary' block
                                                htmlType='submit'>Checkout</Button>

                                        </Form.Item>
                                    </form>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Content>
                <FooterSection />
            </Layout>
        )
    }

}

const CheckoutVocal = Form.create({ name: 'CheckoutVocalpurchaseform' })(CheckoutVocalwrapper);

export default CheckoutVocal
