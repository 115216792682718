const StopSvg = props => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 208 208">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M104,9A95,95,0,1,1,9,104,95.11,95.11,0,0,1,104,9m0-9A104,104,0,1,0,208,104,104,104,0,0,0,104,0Z" />
                <rect
                    style={{
                        fill: "none",
                        stroke: "currentColor",
                        strokeMiterlimit: 10,
                        strokeWidth: 9,
                    }}
                    x="75.5"
                    y="72.5"
                    width="57"
                    height="63"
                    rx="7.82"
                />
            </g>
        </g>
    </svg>
);

export default StopSvg;
