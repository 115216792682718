import React, { Component } from 'react';
import { Form, Input, InputNumber, Button, Switch, notification, Icon, } from "antd";
import { patchapi, postapi } from "../helper";
import { Upload } from 'antd';
import { config } from '../config';

const { Dragger } = Upload;
class VocalRequestPlanFormWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingplan: false,
            fileList: []
        };

        this.handleChangeDraggable = this.handleChangeDraggable.bind(this);
    }

    handleSubmit = e => {
        e.preventDefault();
        let _this = this;
        _this.setState({
            savingplan: true
        });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.has_note = values.has_note ? true : false;
                if (_this.props.single) {
                    values.id = _this.props.single._id;
                }

                const formData = new FormData();
                formData.append('plan_name', values.plan_name);
                formData.append('plan_price', values.plan_price);
                formData.append('has_note', values.has_note);
                formData.append('example', values.example.fileList[0].originFileObj, values.example.fileList[0].name);

                let request;

                _this.props.single ? request = patchapi(`vocalplans/${_this.props.single._id}`, formData) : request = postapi('vocalplans', formData);

                request
                    .then((resp) => {
                        if (resp.status === "success") {
                            notification.success({
                                message: 'Success',
                                description: resp.msg,
                            });
                            _this.setState({
                                fileList: []
                            })

                            _this.props.onSubmit();
                        }
                        else {
                            notification.error({
                                message: 'Error',
                                description: resp.message,
                            });
                        }
                        _this.setState({
                            savingplan: false
                        });
                    }).catch((err) => {
                        notification.error({
                            message: 'Error',
                            description: err.message,
                        });
                        _this.setState({
                            savingplan: false
                        });
                    });
            }
            else {
                _this.setState({
                    savingplan: false
                });
            }
        });
    };

    beforeUpload(file) {
        const isWav = file.type === 'audio/mp3' || file.type === 'audio/wav' || file.type === 'audio/mpeg';
        if (!isWav) {
            message.error('You can only upload Wav file!');
        }
        return isWav;
    };

    handleChangeDraggable(info) {
        let fileList = info.fileList;
        this.setState({
            fileList: fileList
        });
    };

    dummyRequest({ file, onSuccess }) {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { single } = this.props;

        const _this = this;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item
                    label="Plan Name"
                >
                    {getFieldDecorator('plan_name', {
                        initialValue: single ? single.plan_name : "",
                        rules: [{ required: true, message: 'Please type plan name!' }],
                    })(
                        <Input placeholder="Plan Name"
                        />,
                    )}
                </Form.Item>

                <Form.Item
                    label="Plan Price"
                >
                    {getFieldDecorator('plan_price', {
                        initialValue: single ? single.plan_price : "",
                        rules: [{ required: true, message: 'Please type plan price!' }],
                    })(
                        <InputNumber style={{ width: '100%' }} min={1} step={0.1} placeholder="Plan Price" />,
                    )}
                </Form.Item>

                <Form.Item
                    label="Plan has note"
                >
                    {getFieldDecorator('has_note', {
                        initialValue: !!(single && single.has_note),
                        valuePropName: 'checked',
                    })(
                        <Switch />,
                    )}
                </Form.Item>

                <Form.Item
                    label="Example File"
                >
                    {getFieldDecorator('example', {
                        initialValue: [],
                        valuePropName: 'file',
                    })(
                        <Dragger
                            accept='audio/wav'
                            multiple={false}
                            headers={{
                                'Authorization': `Bearer ${config.authKey}`
                            }}
                            name="example"
                            customRequest={_this.dummyRequest}
                            beforeUpload={_this.beforeUpload}
                            onChange={_this.handleChangeDraggable}
                            fileList={_this.state.fileList}
                        >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>,
                    )}
                </Form.Item>

                <Form.Item>
                    <Button
                        block type="primary"
                        loading={this.state.savingplan}
                        disabled={this.state.savingplan}
                        htmlType="submit">
                        {
                            this.props.single ? 'Update plan' : 'Save plan'
                        }
                    </Button>
                    <Button block type="danger" onClick={() => {
                        this.props.form.resetFields();
                        this.props.onClose();
                    }} disabled={this.state.savingplan} >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        )
    }

}

const VocalRequestPlanForm = Form.create({ name: 'vocalrequestplanformwrapper' })(VocalRequestPlanFormWrapper);

export default VocalRequestPlanForm;