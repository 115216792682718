import React, { Component } from 'react'
import { DatePicker, Spin, Form } from 'antd';
import { callapi, postapi } from "../helper";
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt";
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-responsive-dt';
import JSZip from "jzip";
import { config } from "../config";
import moment from "moment";

window.JSZip = JSZip;

const { RangePicker, } = DatePicker;

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingUsers: false,
            reports: [],
            formater: [],
            columns: [
                {
                    title: "Full Name",
                },
                {
                    title: "User Name",
                },
                {
                    title: "User Created",
                },
                {
                    title: "Monthly",
                },
                {
                    title: "Saved Tracks",
                },
                {
                    title: "Purchased",
                },
                {
                    title: "Vocal Requests",
                },
            ]
        }
    }

    componentDidMount() {
        $.fn.dataTable.ext.classes.sPageButton = 'ant-btn';
        // this.loadReports();
        this.loadDt({ all: true });
    }

    loadDt(params) {
        const _this = this;
        _this.dttable = $(_this.table)
            .on('processing.dt', function (e, settings, processing) {
                _this.setState({
                    loadingUsers: processing
                })
            })
            .DataTable({
                ajax: {
                    url: `${config.apiurl}stats/reports`,
                    type: "GET",
                    data: params,
                    headers: {
                        "Authorization": `Bearer ${config.authKey}`
                    },
                },
                order: [],
                dom: '<"toolbar">Bfrtip',
                responsive: true,
                buttons: {
                    buttons: [
                        {
                            extend: 'excelHtml5',
                            title: 'Reports_' + Date.now(),
                        },
                        'colvis',
                    ],
                    dom: {
                        button: {
                            tag: "button",
                            className: "ant-btn ant-btn-primary"
                        },
                        buttonLiner: {
                            tag: null
                        }
                    }
                },
                "columns": [
                    { "data": "full_name" },
                    { "data": "username" },
                    { "data": "user_created_at" },
                    { "data": "plan_name" },
                    { "data": "saved_tracks" },
                    { "data": "purchased" },
                    { "data": "vocal_requests" },
                ],
                "columnDefs": [
                    {
                        "width": "20%",
                        "targets": 0
                    },
                    {
                        "width": "20%",
                        "targets": 1
                    },
                    {
                        "width": "20%",
                        "render": function (data, type, row) {
                            return moment(data).format("LLL");
                        },
                        "targets": 2
                    },
                    {
                        "render": function (data, type, row) {
                            return data ? "Yes" : "No";
                        },
                        "targets": 3
                    },
                ]
            });
        console.log('log-_this.dttable', _this.dttable);
        return _this.dttable;
    }

    componentWillUnmount() {
        $(this.table).DataTable().destroy(true);
    }

    loadReports() {
        this.setState({
            loadingUsers: true
        });
        const reports = callapi('stats/reports');

        reports.then((resp) => {
            console.log('log-resp', resp);
            this.setState({
                reports: resp.data,
                loadingUsers: false,
            });
        })

    }

    loadFormaterData() {
        const reports = callapi('getformaterdata');

        reports.then((resp) => {
            console.log('log-resp', resp);
            this.setState({
                formater: resp,
            });
        })
    }

    initFormater() {
        let maindata = this.state.formater;
        let formatedData = [];

        for (let i in maindata) {
            let thedata = maindata[i];
            thedata.added_at = moment(thedata.added_at).format("YYYY-MM-DD HH:mm:ss");
            // thedata.updated_at = moment(thedata.updated_at).format("YYYY-MM-DD HH:mm:ss");
            formatedData.push(thedata);
        }

        postapi('saveformateddata', formatedData)
            .then((resp) => {
                console.log('log-resp', resp);
            });

    }

    renderRowdata = (data) => {
        let ret = [];
        for (let i in data) {
            ret.push(<td key={i}>{data[i]}</td>)
        }
        return ret;
    }

    render() {
        const _this = this;

        return (
            <div id="thstudiomiddle" style={{ backgroundColor: '#fff', padding: 20 }}>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        padding: "20px 0",
                    }}
                >
                    <Form.Item
                        labelCol={{
                            xs: { span: 24 },
                            sm: { span: 8 },
                        }}
                        wrapperCol={{
                            xs: { span: 24 },
                            sm: { span: 16 },
                        }}
                        label="Filter by date: ">
                        <RangePicker onChange={(date, dateString) => {
                            // console.log('log-dateString', dateString);
                            _this.dttable.clear().destroy();
                            _this.loadDt({
                                start: dateString[0],
                                end: dateString[0]
                            });
                            console.log('log-_this.dttable', _this.dttable);
                        }} />
                    </Form.Item>
                </div>
                <Spin spinning={this.state.loadingUsers}>
                    <table className="display" ref={el => _this.table = el}>
                        <thead>
                            <tr>
                                {
                                    this.state.columns ?
                                        this.state.columns.map((item, ci) => {
                                            return (<th key={ci}>{item.title}</th>)
                                        }) :
                                        <th>ID</th>
                                }
                            </tr>
                        </thead>
                    </table>
                </Spin>
            </div>
        )
    }

}

export default Dashboard
