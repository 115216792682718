import React, { Component } from 'react'
import { Form, Icon, Input, Button, Checkbox, Layout, Row, Col, message } from 'antd';
import { postapi } from "../helper";
import { reactLocalStorage } from 'reactjs-localstorage'
import { config } from "../config";
const { Header, Footer } = Layout;
const FormItem = Form.Item;


class LoginForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    handleSubmit = (e) => {
        const _this = this
        _this.setState({
            loading: true,
            islogged: true
        })

        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                const dologin = postapi('users/login', {
                    username: values.userName,
                    password: values.password
                })

                dologin.then((data) => {
                    if (data.status === 'error' || data.status === 'failed') {
                        message.error(data.message)
                        _this.setState({
                            loading: false
                        })
                    }
                    else {
                        reactLocalStorage.setObject('user', data.data.user)
                        reactLocalStorage.set('token', data.token)
                        _this.setState({
                            loading: false,
                        }, () => {
                            window.location = '/admin'
                        })
                    }
                })

                _this.setState({
                    loading: false
                })
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const isadmin = (config.user && config.user.user_type === 'admin') ? true : false;

        if (isadmin) {
            window.location = '/admin'
        }

        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <Header style={{ backgroundColor: '#fff' }} />
                <Row>
                    <Col xs={{ span: 5 }} lg={{ span: 9 }}></Col>
                    <Col xs={{ span: 11 }} lg={{ span: 6 }}>
                        <div className='admin-login-header'>
                            <img src='/CMM-Logo.png' />
                        </div>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <FormItem>
                                {getFieldDecorator('userName', {
                                    rules: [{ required: true, message: 'Please input your username!' }],
                                })(
                                    <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }],
                                })(
                                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('remember', {
                                    valuePropName: 'checked',
                                    initialValue: true,
                                })(
                                    <Checkbox className='dispBlock'>Remember me</Checkbox>
                                )}
                                <a className="login-form-forgot dispBlock" href="">Forgot password</a>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Log in
                                </Button>

                            </FormItem>
                        </Form>
                    </Col>
                    <Col xs={{ span: 5 }} lg={{ span: 9 }}></Col>
                </Row>
                <Footer style={{
                    background: '#fff',
                    display: 'flex',
                    justifyContent: 'center'
                }}><span>@ Copyright - All rights reserved by Cheer Music Maker. </span>
                </Footer>
            </Layout>
        );
    }

}

const Login = Form.create()(LoginForm);

export default Login
