import React, { Component } from 'react'
import {
    Row, Col, Table, Icon,
    Divider, Button,
    Drawer, Form, Input, message
} from 'antd';
import { callapi, deleteapi, patchapi, postapi } from "../helper";
import moment from 'moment'
const FormItem = Form.Item;

class CategoriesWrapper extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addPlan: false,
            categories: [],
            addCategory: false,

            cat_name: '',
            loading: false,
            isSingle: false,
            single_id: ''
        }
    }

    componentWillMount() {
        this.getCategories()
    }

    getCategories() {
        //getcategories
        const _this = this
        _this.setState({
            loading: true
        })
        const categories = callapi('categories')
        categories.then((data) => {
            // Map each categories and add a key property
            const categories = data.data.data.map(cat => {
                return {
                    ...cat,
                    key: cat._id
                }
            })

            _this.setState({
                categories: categories,
                loading: false
            })
        })
    }

    showDrawer = () => {
        this.setState({
            addCategory: true,
        });
    };

    onClose = () => {
        this.setState({
            addCategory: false,
            isSingle: false,
            single_id: '',
        });
    };

    handleChange(name, event) {
        this.setState({
            [name]: event.target.value
        })
    }

    saveecategory() {
        const _this = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const addcat = postapi('categories', {
                    cat_name: this.state.cat_name,
                })

                _this.setState({
                    loading: true
                })

                addcat.then((data) => {
                    if (data.status === 'error') {
                        message.error(data.message)
                        _this.setState({
                            loading: false
                        })
                    }
                    else {
                        _this.setState({
                            addCategory: false,
                            loading: false,
                        })
                        message.success(data.msg)
                        _this.getCategories()
                        _this.props.form.resetFields()
                    }
                })
                    .catch((err) => {
                        message.error(err)
                        _this.setState({
                            loading: false
                        })
                    })

            }
        });
    }

    updatecategory() {
        const _this = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                _this.setState({
                    loading: true
                })

                const updatecet = patchapi(`categories/${this.state.single_id}`, {
                    cat_name: this.state.cat_name,
                })

                updatecet.then((data) => {
                    if (data.status === 'error') {
                        message.error(data.message)
                        _this.setState({
                            loading: false
                        })
                    }
                    else {
                        _this.setState({
                            addCategory: false,
                            loading: false,
                        })
                        message.success(data.msg)
                        _this.getCategories()
                        _this.props.form.resetFields()
                    }
                })
                    .catch(err => {
                        message.error(err)
                        _this.setState({
                            loading: false
                        })
                    })

            }
        });
    }

    deletecategory() {
        const _this = this;
        const deleteplan = deleteapi(`categories/${this.state.single_id}`)
        _this.setState({
            loading: true
        })
        deleteplan.then((data) => {
            if (data.status === 'error') {
                message.error(data.message)
                _this.setState({
                    loading: false
                })
            }
            else {
                _this.setState({
                    addCategory: false,
                    loading: false,
                })
                message.success(data.msg)
                _this.getCategories()
                _this.props.form.resetFields()
            }
        })
            .catch(err => {
                message.error(err)
                _this.setState({
                    loading: false
                })
            })
    }

    render() {
        let _this = this
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: 'Category Name',
                dataIndex: 'cat_name',
                key: 'cat_name',
            },
            {
                title: 'Added At',
                dataIndex: 'added_at',
                key: 'added_at',
                render: (text, record) => {
                    return <span>{moment(record.added_at).format('MMMM Do YYYY, h:mm:ss a')}</span>;
                }
            },
            {
                title: 'Updated At',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (text, record) => {
                    return <span>{record.updated_at ? moment(record.updated_at).format('MMMM Do YYYY, h:mm:ss a') : '-'}</span>;
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return (
                        <span>
                            <Divider type="vertical" />
                            <Button onClick={() => {
                                _this.setState({
                                    isSingle: true,
                                    single_id: record._id,
                                    cat_name: record.cat_name,
                                }, () => {
                                    _this.showDrawer()
                                })

                            }} size='small' type="primary">
                                <Icon type="edit" />
                            </Button>
                            <Divider type="vertical" />
                            <Button onClick={() => {
                                _this.setState({
                                    single_id: record._id,
                                }, () => {
                                    _this.deletecategory()
                                })

                            }} size='small' type="danger">
                                <Icon type="delete" />
                            </Button>
                        </span>
                    )
                },
            }
        ];

        return (
            <div>
                <Row>
                    <Col lg={24}>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                padding: '20px 0'
                            }}>
                            <Button type="primary" onClick={this.showDrawer}>Add new Category</Button>
                        </div>
                        <Drawer
                            title={this.state.isSingle ? 'Update category' : 'Add new category'}
                            width={520}
                            className="add_new_category"
                            closable={false}
                            onClose={this.onClose}
                            visible={this.state.addCategory}
                        >
                            <div>
                                <Form>

                                    <FormItem
                                        label="Category Name"
                                    >
                                        {getFieldDecorator('categoryname', {
                                            rules: [
                                                {
                                                    required: true, message: 'You must type category name!',
                                                }
                                            ],
                                            initialValue: this.state.cat_name
                                        })(
                                            <Input
                                                onChange={(e) => this.handleChange('cat_name', e)}
                                                type='text' />
                                        )}
                                    </FormItem>

                                    <FormItem>
                                        <Button
                                            onClick={() => {
                                                this.state.isSingle ?
                                                    this.updatecategory() :
                                                    this.saveecategory()

                                            }}
                                            loading={this.state.loading}
                                            type="primary" htmlType="submit">{this.state.isSingle ? 'Update category' : 'Save category'}</Button>
                                    </FormItem>
                                </Form>
                            </div>
                        </Drawer>
                    </Col>
                </Row>
                <Row>
                    <div style={{ backgroundColor: '#fff', padding: 20 }}>
                        <Table columns={columns} dataSource={this.state.categories} loading={this.state.loading} />
                    </div>

                </Row>
            </div>
        )
    }

}

const Categories = Form.create()(CategoriesWrapper);

export default Categories
