import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import { CirclePicker } from "react-color";

const AnnotationModal = ({
    visible,
    regionId,
    regionData,
    setRegionData,
    onSave,
    onCancel,
    onDelete,
}) => {
    const handleSave = () => {
        if (regionId) {
            onSave(regionId);
        } else {
            onSave();
        }
    };

    return (
        <Modal
            title="Give a name"
            visible={visible}
            onOk={handleSave}
            onCancel={onCancel}
            footer={[
                <Button
                    key="delete"
                    disabled={!regionId}
                    type="danger"
                    onClick={onDelete}
                >
                    Delete
                </Button>,
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSave}>
                    Save
                </Button>,
            ]}
        >
            <Input
                placeholder="Type region Name"
                value={regionData.name}
                onChange={e =>
                    setRegionData({
                        ...regionData,
                        name: e.target.value,
                    })
                }
            />
            <div
                style={{
                    padding: 10,
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                }}
            >
                <CirclePicker
                    color={regionData.color}
                    onChangeComplete={colordata =>
                        setRegionData({
                            ...regionData,
                            color: colordata.hex,
                        })
                    }
                />
            </div>
        </Modal>
    );
};

export default AnnotationModal;
