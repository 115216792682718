import React, { Component } from "react";

class TimelineRuler extends Component {
  componentDidMount() {
    const elmsvg = document.getElementById("timeline-ruler");
    elmsvg.addEventListener("click", (e) => {
      this.props.ifclicked(e);
    });
  }

  // render bar with minutes indicator
  renderBar(i, zoom, children) {
    return (
      <g
        key={`tm-${i}`}
        className="timeline-tick"
        transform={`translate(${i * zoom},0)`}
        style={{ opacity: 1, zIndex: 999 }}
      >
        <text
          dy="0em"
          y="-25"
          x="0"
          className="tmmarker"
          style={{ textAnchor: "middle" }}
        >
          {children()}
        </text>
      </g>
    );
  }

  renderScale() {
    let lines = [];
    const newWidth = this.props.width * this.props.playbackRate;
    const width = this.props.width;


    // collect the initials
    let stepval = this.props.step ? this.props.step : 10;
    const interval = this.props.interval ? this.props.interval : 0;
    const tickheight = this.props.tickheight ? this.props.tickheight : 10;
    const zoom = this.props.zoom ? this.props.zoom : 0;
    let counter = 0;
    let miniCount = 1


    // let aheadtime = Math.round(stepval * this.props.playbackRate)
    let aheadtime = stepval
    let halfMinuteEquivBar = 9;
    let minuteInterval = 30;
    let palleteWidth = width * zoom;
    let cond = interval * aheadtime;
    // const totalPaletteWidth = palleteWidth - (palleteWidth % cond);
    const totalPaletteWidth = palleteWidth - (palleteWidth % cond);


    // loop through the total width of the main timeline
    for (let i = 0; i <= totalPaletteWidth; i += aheadtime) {
      // create condition based on interval time and ahead time to get the bits

      const maxBar = parseInt(palleteWidth / cond)
      const loopBar = i / cond;


      if (loopBar > maxBar) break;

      // const epsilon = 1e-5; // Define a small epsilon value

      // if (Math.abs(i / cond - parseInt(i / cond)) < epsilon) {
      if (i / cond === parseInt(i / cond)) {


        // else push the regular lines
        lines.push(
          <g
            key={i}
            className="timeline-tick"
            transform={`translate(${i * zoom},0)`}
            style={{ opacity: 1 }}
          >
            <line
              y2={`-${tickheight}`}
              x2="0"
              fill="none"
              strokeWidth={1}
              stroke="#f1f1f1"
            />
            <text dy="0em" y="-15" x="0" style={{ textAnchor: "right" }}>
              {counter + 1} - 8ct
            </text>
          </g>
        );

        // increase the counter every time in loop
        counter++;
        miniCount = 1
      } else {
        // push the small lines

        lines.push(
          <g
            key={i}
            className="timeline-tick"
            transform={`translate(${i * zoom},0)`}
            style={{ opacity: 1 }}
          >
            <line
              y2={`-${tickheight / 2}`}
              x2="0"
              fill="none"
              strokeWidth={1}
              stroke="#f1f1f1"
            />


            {/* 
            if the zoom is greater than or equalt to 3, return text element with a counter from 1 to untill the loop ends*/
            }
            {zoom >= 3 &&
              <text dy="0em" y="-10" x="0" style={{ textAnchor: "middle", fontSize: "0.7rem" }}>
                {miniCount + 1}
              </text>
            }

          </g>
        );
        // increase the Mini counter every time in loop
        miniCount++
      }
    }
    return lines;
  }

  render() {
    const height = 40;
    const cond = this.props.interval * Math.round(this.props.step);
    const newWidth = this.props.width * this.props.playbackRate;
    const width = this.props.width;
    let totalPaletteWidth = width - (this.props.width % cond);
    totalPaletteWidth += 5;
    // create the svg lines
    return (
      <svg
        width={totalPaletteWidth * this.props.zoom}
        height={height}
        id="timeline-ruler"
        className="timeline-ruler"
      >
        <g className="axis" transform={`translate(1,${height})`}>
          {this.renderScale()}
          <path
            className="domain"
            d={`M0,0V0H${totalPaletteWidth * this.props.zoom}V0`}
          />
        </g>
      </svg>
    );
  }
}

export default TimelineRuler;
