import React, { Component } from "react";
import {
    Layout,
    Row,
    Col,
    Card,
    Button,
    Modal,
    Spin,
    message,
    Divider,
    notification,
} from "antd";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";

import cardImg from "../images/prbg.jpg";

import FooterSection from "../FooterSection";
import { config } from "../../config";
import ProfileHeader from "./ProfileHeader";
import { callapi, postapi } from "../../helper";
import { loadStripe } from "@stripe/stripe-js";
import { reactLocalStorage } from "reactjs-localstorage";
import StripePaymentForm from "../../StripePaymentForm";
import { Fade } from "react-reveal";

const stripePromise = loadStripe(config.stripeApikey);
const { Content } = Layout;

class Upgrade extends Component {
    instance;

    constructor(props) {
        super(props);
        this.state = {
            current: "profile",
            loading: false,
            islogged: config.islogged,
            uid: "",
            user: [],
            plans: [],
            paymentModal: false,
            single: false,
            isloadingbrain: false,
            clientToken: false,
            loadingpayment: false,

            hasPlan: false,
            // stripe payment
            paymentMethod: false,
            creatingPayment: false,

            // notices
            notices: [],
            loadingnotice: false,
        };
        // this.handlePayment = this.handlePayment.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
    }

    componentDidMount() {
        this.getNotices();

        // get the query string from url

        const query = new URLSearchParams(this.props.location.search);
        const success = query.get("success");

        // if success is true then show the success message and redirect to profile page
        if (success === "true") {
            notification.success({
                message: "Success",
                duration: 5,
                placement: "topRight",
                description:
                    "Payment Successful! You will be notified with Email once your subscription is activated. If you don't recieve the email, or Your plan does not gets updated, please contact us.",
                onClose: () => {
                    this.props.history.push(`/profile/${config.user._id}`);
                },
            });
        } else if (success === "false") {
            message.error("Payment Failed! Please try again.");
            this.getuserInfo();
        }
    }

    // load all notice data
    getNotices() {
        //getNotices
        const _this = this;
        _this.setState({ loadingnotice: true });
        const notice = callapi("subnotices");
        notice.then(data => {
            _this.setState({
                notices: data.data.data,
                loadingnotice: false,
            });
        });
    }

    handleCancelPayment() {
        this.setState({
            single: false,
            loadingpayment: false,
            clientToken: false,
            paymentMethod: false,
            paymentModal: false,
        });
    }

    async handleNewPayment(item) {
        const _this = this;
        _this.setState({
            creatingPayment: true,
            single: item,
        });

        if (item._id === "615d6f75115a8bdb9de3728f") {
            // if the check is true that means this plan is free to subscribe, so we won't need to call stripe api, we can just change the plan type and reload the user details

            // first give the user a prompt to confirm the change
            return Modal.confirm({
                title: "Confirm",
                content: `Are you sure you want to change your plan to ${item.plan_name}?
                    This will cancel your current subscription plan.                `,
                onOk() {
                    const checkout = postapi(`transactions/new/plan`, {
                        user_id: config.user._id,
                        plan_id: item._id,
                        amount: item.plan_price,
                        paymentData: {},
                    })
                        .then(data => {
                            _this.setState({ creatingPayment: false }, () => {
                                if (data.status === "success") {
                                    _this.getPlans();
                                    _this.getuserInfo();
                                    _this.checkExistingplan();
                                    // reset the users session details
                                    _this.reloadUserDetails(config.user._id);
                                    message.success(
                                        "You have successfully subscribed to this plan."
                                    );
                                } else {
                                    message.error(
                                        "Sorry! Cannot proceed your request now. Please try again later."
                                    );
                                }
                            });
                        })
                        .finally(() => {
                            _this.setState({ creatingPayment: false });
                        });
                    return;
                },
                onCancel() {
                    _this.setState({
                        creatingPayment: false,
                    });
                    return;
                },
            });
        }

        // create a new checkout session from here
        const { data } = await postapi(`transactions/stripe_checkout_session`, {
            item,
            type: "subscription",
        });

        // continue to checkout page
        if (data.session) {
            stripePromise.then(async stripe => {
                const { session } = data;
                await stripe.redirectToCheckout({ sessionId: session });
            });
        }

        _this.setState({ creatingPayment: false });
    }

    cancelSubscription() {
        const _this = this;

        const checkout = postapi(`transactions/cancelSubscription`, {})
            .then(data => {
                _this.setState({ creatingPayment: false }, () => {
                    if (data.status === "success") {
                        _this.getPlans();
                        _this.getuserInfo();
                        _this.checkExistingplan();
                        // reset the users session details
                        _this.reloadUserDetails(config.user._id);
                        message.success(
                            "You have successfully cancelled your subscription."
                        );
                    } else {
                        message.error(
                            "Sorry! Cannot proceed your request now. Please try again later."
                        );
                    }
                });
            })
            .finally(() => {
                _this.setState({ creatingPayment: false });
            });
        return;
    }

    // reload the loagged in user details with plans after plans updated
    reloadUserDetails(uid) {
        // getusersinfo
        callapi(`users/${uid}`).then(resp => {
            if (resp.status === "success") {
                // update the session with updated user data
                reactLocalStorage.setObject("user", resp.data.user);
            }
        });
    }

    componentWillMount() {
        this.getPlans();
        this.getuserInfo();
        let thename = config.user.full_name;
        if (!thename) {
            Modal.confirm({
                title: "Sorry! You are missing your full name.",
                content:
                    "Please update your profile with full name to complete the purchase.",
                onOk() {
                    window.location = "/edit_profile";
                },
                onCancel() {
                    window.location = "/profile/" + config.user._id;
                },
            });
        }
    }

    getuserInfo() {
        //getuserInfo
        const _this = this;
        const notice = callapi(`users/${config.user._id}`);
        notice.then(data => {
            _this.setState(
                {
                    user: data.data.user,
                },
                () => {
                    _this.checkExistingplan(
                        data.data.user._id,
                        data.data.user_plan_type
                    );
                }
            );
        });
    }

    // get plans from api
    getPlans() {
        //getplans
        const _this = this;
        _this.setState({ loading: true });
        const plans = callapi("plans/subscriptionplans");
        plans
            .then(data => {
                if (data.data?.plans?.length) {
                    const filteredPlans = data.data.plans.filter(
                        plan => plan._id !== "615d7054e556ecddc39f4b1d"
                    );

                    _this.setState({ plans: filteredPlans });
                    setTimeout(() => {
                        _this.setState({ loading: false });
                    }, 1000);
                }
            })
            .catch(err => {
                console.error(err);
                _this.setState({ loading: false });
            })
            .finally(() => {
                _this.setState({ loading: false });
            });
    }

    paymentModal(paymentModal, item) {
        const _this = this;
        _this.setState(
            {
                paymentModal: paymentModal,
                isloadingbrain: true,
                paymentMethod: !parseInt(item.plan_price) > 0,
            },
            () => {
                // checking if user already has a plan
                _this.checkExistingplan(config.user._id, item._id);

                _this.setState({ single: item });
            }
        );
    }

    // check if user already has a plan
    checkExistingplan(uid, planid) {
        const _this = this;
        const checkExisting = postapi(`users/${uid}/checkifhasplan`, {
            plan_id: planid,
        });

        checkExisting.then(data => {
            if (data.status === "success") {
                _this.setState({ hasPlan: data.data.plan });
            }
        });
    }

    render() {
        const _this = this;
        return (
            <Layout className="body-front">
                <ProfileHeader
                    className="topMenu"
                    onClick={this.handleClick}
                    selectedKeys={[this.state.current]}
                    mode="horizontal"
                />
                <Content>
                    <div style={{ marginBottom: 100 }}>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <div style={{ padding: 30 }}>
                                    <Link to={`profile/${config.user._id}`}>
                                        Back to profile
                                    </Link>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <div style={{ padding: 30 }}>
                                    <h2 style={{ textAlign: "center" }}>
                                        Plans and Subscriptions
                                    </h2>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                {/* add a button to cancel current subscription */}
                                {this.state.hasPlan &&
                                    config?.user?.plan?._id ===
                                        "615d7054e556ecddc39f4b1d" && (
                                        <div style={{ padding: 30 }}>
                                            <Button
                                                type="danger"
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: "Are you sure?",
                                                        content:
                                                            "Are you sure you want to cancel your current subscription?",
                                                        onOk() {
                                                            _this.cancelSubscription();
                                                        },
                                                        onCancel() {},
                                                    });
                                                }}
                                            >
                                                Cancel Subscription
                                            </Button>
                                        </div>
                                    )}
                            </Col>
                        </Row>
                        <Row type="flex" justify="center">
                            {
                                //plans cards
                                this.state.plans &&
                                    this.state.plans.map((item, index) => {
                                        let ifcurrent =
                                            this.state.user.plan &&
                                            this.state.user.plan._id ===
                                                item._id;
                                        let features = item.features;
                                        return (
                                            <Col
                                                key={item.plan_id}
                                                xs={{ span: 24 }}
                                                sm={{ span: 20 }}
                                                md={{ span: 10, offset: 1 }}
                                                lg={{ span: 8, offset: 1 }}
                                            >
                                                <div className="listing-item">
                                                    <figure className="image">
                                                        <img
                                                            src={cardImg}
                                                            alt={`Card ${item.plan_name}`}
                                                        />
                                                        <figcaption>
                                                            <div className="caption">
                                                                <h1>
                                                                    $
                                                                    {
                                                                        item.plan_price
                                                                    }
                                                                </h1>
                                                                <p>
                                                                    {
                                                                        item.plan_name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </figcaption>
                                                    </figure>
                                                    <div className="listing">
                                                        {features &&
                                                            features.length &&
                                                            features.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <h4
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {`${item}`}
                                                                        </h4>
                                                                    );
                                                                }
                                                            )}

                                                        <div className="card_action">
                                                            <h3>
                                                                ${" "}
                                                                {
                                                                    item.plan_price
                                                                }
                                                            </h3>
                                                            ,
                                                            {ifcurrent ? (
                                                                <strong>
                                                                    Current Plan
                                                                </strong>
                                                            ) : (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() => {
                                                                        this.handleNewPayment(
                                                                            item
                                                                        );
                                                                    }}
                                                                    loading={
                                                                        this
                                                                            .state
                                                                            .creatingPayment
                                                                    }
                                                                >
                                                                    Upgrade Now
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })
                            }
                        </Row>
                        <Divider>Terms</Divider>
                        <Spin spinning={_this.state.loadingnotice}>
                            <Row type="flex" justify="center">
                                {_this.state.notices &&
                                    _this.state.notices.map(item => {
                                        const notice = renderHTML(item.notice);
                                        return (
                                            <Col
                                                key={item._id}
                                                xs={{ span: 24 }}
                                                sm={{ span: 20 }}
                                                md={{ span: 10, offset: 1 }}
                                                lg={{ span: 8, offset: 1 }}
                                            >
                                                <Fade>
                                                    <Card>
                                                        <Card.Meta
                                                            title={
                                                                item.notice_title
                                                            }
                                                            description={renderHTML(
                                                                notice
                                                            )}
                                                        />
                                                    </Card>
                                                </Fade>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Spin>
                    </div>
                </Content>
                <FooterSection />

                <Modal
                    title={this.state.single ? this.state.single.plan_name : ""}
                    centered
                    closable={false}
                    maskClosable={false}
                    visible={this.state.paymentModal}
                    onOk={() => this.paymentModal(false)}
                    onCancel={() => this.paymentModal(false)}
                    footer={[
                        <Button
                            key="cancel"
                            disabled={_this.state.loadingpayment}
                            onClick={() => _this.handleCancelPayment()}
                        >
                            cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={_this.state.creatingPayment}
                            disabled={!_this.state.paymentMethod}
                            onClick={() =>
                                _this.handlePayment(
                                    _this.state.single.plan_price,
                                    _this.state.single._id
                                )
                            }
                        >
                            Proceed
                        </Button>,
                    ]}
                >
                    <h4>
                        Plan price: $
                        {_this.state.single
                            ? _this.state.single.plan_price
                            : ""}
                    </h4>

                    {_this.state.hasPlan && this.state.user.tran_details && (
                        <h3 className="existing-plan-alert">
                            You have already subscribed to a plan (
                            {_this.state.hasPlan.plan_name}). This will cancel
                            your previous subscription.
                        </h3>
                    )}

                    {_this.state.single._id === "615d6f75115a8bdb9de3728f" && (
                        <h3 className="existing-plan-alert">
                            You have to verify your card to subscribe to this
                            plan. But you won't be charged until you create and
                            download your mix
                        </h3>
                    )}

                    {_this.state.single.plan_price > 0 && (
                        <StripePaymentForm
                            price={_this.state.single.plan_price}
                            onSuccess={paymentMethod => {
                                _this.setState({
                                    paymentMethod: paymentMethod,
                                });
                            }}
                        />
                    )}
                </Modal>
            </Layout>
        );
    }
}

export default Upgrade;
