import { Modal } from "antd";
import VideoTutorial from "../../VideoTutorial";

const TutorialModal = ({ visible, setVisible }) => {
    return (
        <Modal
            title={null}
            width="50%"
            visible={visible}
            onOk={() => {
                // toggle tutorial modal
                // this.setState({
                //     tutorialmodal: !this.state.tutorialmodal,
                // });

                setVisible(false);
            }}
            onCancel={() => {
                // toggle tutorial modal
                // this.setState({
                //     tutorialmodal: !this.state.tutorialmodal,
                // });

                setVisible(false);
            }}
            footer={null}
        >
            <VideoTutorial key="videotutorial" />
        </Modal>
    );
};

export default TutorialModal;
