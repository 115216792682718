import React, { Component } from "react";
import { Button, Spin, Icon, message } from "antd";
import Play from "react-icons/lib/fa/play";
import Stop from "react-icons/lib/fa/stop";
import Watermark from "../assets/watermark-230.wav";
const antIconload = (
    <Icon type="loading" style={{ fontSize: 24, color: "#0087fc" }} spin />
);

const sample =
    "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

class MiniPlayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            loading: false,
            miniplayer: null,
        };
    }

    componentDidMount() {
        const _this = this;
        const miniplayer = _this.props.miniplayer;

        if (!miniplayer) {
            _this.setState({
                miniplayer: new Audio(),
            });
        } else {
            _this.setState({
                miniplayer,
            });
        }

        // _this.playmini = new Audio();
        // _this.playmini.autoplay = true;
        // _this.playmini.src = sample;
        // _this.playmini = _this.props.miniplayer;
    }

    doplay() {
        const _this = this;
        _this.setState({
            loading: true,
            playing: true,
        });

        // const { miniplayer, link } = _this.props;
        const { link } = _this.props;

        const miniplayer = _this.state.miniplayer;

        console.log("miniplayer", miniplayer);

        // _this.playmini.src = this.props.link;
        miniplayer.src = link;

        miniplayer.onloadeddata = e => {
            _this.setState({
                loading: false,
            });
        };

        // _this.playmini.play().catch(e => {
        //     message.error(e.message);
        //     _this.setState({
        //         loading: false,
        //     });
        //     _this.dostop();
        // });

        miniplayer
            .play()
            .then(() => {
                if (this.props.from === "justPlay") {
                    _this.playmini1 = new Audio(Watermark);
                    _this.playmini1.volume = 0.2;
                    // _this.playmini1.volume=2;
                    _this.playmini1.onloadeddata = e => {
                        _this.setState({
                            loading: false,
                        });
                    };
                    _this.playmini1.play().catch(err => {
                        message.error(err.message);
                        _this.setState({
                            loading: false,
                        });
                        _this.dostop();
                    });

                    _this.playmini1.onended = () => {
                        _this.dostop();
                    };
                }
            })
            .catch(e => {
                message.error(e.message);
                _this.setState({
                    loading: false,
                });
                _this.dostop();
            });

        // _this.playmini.play().catch(err => {
        //     message.error(err.message);
        //     _this.setState({
        //         loading: false,
        //     });
        //     _this.dostop();
        // });

        // _this.playmini.onended = () => {
        //     _this.dostop();
        // };

        miniplayer.onended = () => {
            _this.dostop();
        };
    }

    dostop() {
        const _this = this;
        // const { miniplayer } = this.props;
        const miniplayer = _this.state.miniplayer;

        // this.playmini.pause();
        miniplayer.pause();

        // this.playmini.currentTime = 0;
        miniplayer.currentTime = 0;

        if (this.props.from === "justPlay") {
            this.playmini1.pause();
            this.playmini1.currentTime = 0;
        }
        this.setState({
            playing: false,
        });
    }

    render() {
        const { loading, playing } = this.state;

        return (
            <div>
                <Spin spinning={loading} indicator={antIconload}>
                    <Button
                        type={playing ? "primary" : "ghost"}
                        shape="circle"
                        onClick={() => {
                            playing ? this.dostop() : this.doplay();
                        }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {playing ? <Stop /> : <Play />}
                    </Button>
                </Spin>
            </div>
        );
    }
}

export default MiniPlayer;
