import React, { Component } from 'react';
import { Row, Col, Button, message, Spin } from 'antd';
import { config } from '../config';
import {
    PDFViewer,
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
} from '@react-pdf/renderer';
import moment from 'moment';
import axios from 'axios';
import Logo from './img/CMM-Logo.png';
import licenseImg from './img/licence.png';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        backgroundImage: 'url(' + Logo + ')',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: 80,
    },
    main: {
        // marginLeft: 50,
        // marginRight: 50,
        // marginBottom: 50,
        // height: '80%',
        flexDirection: 'column',
        borderWidth: 2,
        borderColor: '#333333',
        padding: 10,
        backgroundImage: `url(${Logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
    },
    titletag: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 18,
        color: '#000000',
        paddingBottom: 10,
        lineHeight: '80px',
    },
    ptag: {
        textAlign: 'center',
        fontSize: 11,
        fontWeight: 400,
    },

    ptag1: {
        textAlign: 'center',
        fontSize: 11,
        fontWeight: 400,
        marginTop: 20,
    },
    ptagGen: {
        fontSize: 14,
        fontWeight: 400,
        marginTop: 10,
        padding: 4,
    },
    sectiontop: {
        flexDirection: 'column',
        padding: 10,
        backgroundColor: '#c7c7c7',
    },
    sectiontop1: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'column',
        padding: 10,
        backgroundColor: '#c7c7c7',
    },
    sectiontotal: {
        flexDirection: 'row',
        minHeight: 40,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    section: {
        flexDirection: 'column',
        padding: 10,
        flexGrow: 1,
    },
    sectionfooter: {
        padding: 10,
    },
    titleP: {
        minWidth: 220,
        height: 80,
        backgroundColor: '#c7c7c7',
        lineHeight: '80px',
        paddingTop: 4,
        paddingBottom: 4,
    },
    redTitle: {
        color: '#ff0000',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 18,
        paddingBottom: 20,
        paddingTop: 20,
        lineHeight: '80px',
    },
});

class LicensePDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signature: false,
            processingLicense: false,
            licenseProcessed: true,
            bloburl: false,
            url: false,
            downloadingmix: false,
            downloadingLicense: false,
        };
    }

    componentDidMount() {
        const _this = this;
    }

    dataURItoBlob(dataURI) {
        const blob = fetch(dataURI).then(response => response.blob());
        return blob;
    }

    downloadmix(url) {
        const _this = this;
        const { license } = this.props;

        console.log(license);

        _this.setState({
            downloadingmix: true,
        });

        const axiosMixinstance = axios.create();
        axiosMixinstance.defaults.headers.common = {};

        axiosMixinstance
            .get(url, {
                responseType: 'blob',
            })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${license.project_name} - ${license.team_name}.wav`
                );
                document.body.appendChild(link);
                link.click();
                _this.setState({
                    downloadingmix: false,
                });
            });
    }

    savelisence() {
        const _this = this;

        _this.setState({
            downloadingLicense: true,
        });

        const blobsrc = document.getElementById('previewpane').childNodes[0];
        let blburl = blobsrc.src;

        if (!blburl) {
            message.error('Something Went Wrong!! Try Again Later');
            return;
        }

        const { license } = this.props;

        const blob = this.dataURItoBlob(blburl);

        blob.then(licensedata => {
            const formData = new FormData();
            // append the file to the formData object
            formData.append(
                'file',
                licensedata,
                `license - ${license.project_name} - ${license.team_name}.pdf`
            );
            formData.append('uid', config.user._id);
            formData.append('email', config.user.username);
            // formData.append("email", 'djrayhan8@gmail.com');
            formData.append('team_name', license.team_name);
            formData.append('first_name', license.first_name);
            formData.append('renderedfile', license.renderedfile);

            const axiosLicenseinstanceauth = axios.create();
            axiosLicenseinstanceauth.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${config.authKey}`;

            axiosLicenseinstanceauth
                .post(
                    config.apiurl +
                    `transactions/purchase/${license.pur_id}/saveLicense`,
                    // {
                    //     licenseblob: licensedat,
                    //     uid: config.user._id,
                    //     email: config.user.username,
                    //     renderedfile: license.renderedfile,
                    //     first_name: license.first_name,
                    //     team_name: license.team_name,
                    // }
                    formData
                )
                .then(lidata => {
                    if (lidata.data.status === 'success') {
                        const axiosLicenseinstance = axios.create();
                        axiosLicenseinstance.defaults.headers.common = {};
                        axiosLicenseinstance
                            .get(lidata.data.data.licenceLink, {
                                responseType: 'blob',
                            })
                            .then(response => {
                                const url = window.URL.createObjectURL(
                                    new Blob([response.data])
                                );
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute(
                                    'download',
                                    `license - ${license.project_name} - ${license.team_name}.pdf`
                                );
                                document.body.appendChild(link);
                                link.click();
                                _this.setState({
                                    processingLicense: false,
                                    licenseProcessed: true,
                                    downloadingLicense: false,
                                });
                            })
                            .catch(err => {
                                message.error(
                                    'Something Went Wrong!! Try Again Later'
                                );
                                _this.setState({
                                    processingLicense: false,
                                    licenseProcessed: true,
                                    downloadingLicense: false,
                                });
                            });
                    } else {
                        _this.setState(
                            {
                                processingLicense: false,
                                licenseProcessed: true,
                                downloadingLicense: false,
                            },
                            () => {
                                lidata.data.msg
                                    ? message.error(
                                        lidata.data.msg +
                                        ' Please contact admin.'
                                    )
                                    : message.error(
                                        'Something Went Wrong!! Try Again Later'
                                    );
                            }
                        );
                    }
                })
                .catch(err => {
                    _this.setState(
                        {
                            processingLicense: false,
                            licenseProcessed: true,
                            downloadingLicense: false,
                        },
                        () => {
                            message.error(
                                'Something Went Wrong!! Try Again Later'
                            );
                        }
                    );
                });
        });
    }

    getBase64(url) {
        return axios
            .get(url, { responseType: 'arraybuffer' })
            .then(response => {
                return `data:${response.headers['content-type']};base64,${btoa(
                    String.fromCharCode(...new Uint8Array(response.data))
                )}`;
            });
    }

    render() {
        const _this = this;
        const { license } = this.props;

        let today = new Date();
        let expireDay = new Date();
        let expire = expireDay.setDate(
            expireDay.getDate() +
            (config.user.plan.plan_duration > 0
                ? Math.abs(
                    moment().diff(
                        moment().add(
                            config.user?.plan?.plan_duration,
                            config.user?.plan?.plan_durationtype
                        ),
                        'days'
                    )
                )
                : Math.abs(moment().diff(moment().add(1, 'year'), 'days')))
        );

        const licenseDoc = (
            <Document
                author='Cheer Music Maker'
                title={`License for ${license.team_name}`}
            >
                <Page size='A4' style={styles.page}>
                    <View style={styles.main} className='pdf-image'>
                        <View style={styles.header}>
                            <Image
                                style={{
                                    height: 60,
                                    width: 'auto',
                                }}
                                src={Logo}
                            />
                        </View>

                        <View style={styles.sectiontop}>
                            <Text style={styles.titletag}>
                                PROOF OF MUSIC LICENSE
                            </Text>
                            <Text style={styles.ptag}>
                                Cheer Music Maker certifies that all recordings
                                provided to the program and team listed below
                                was created with properly licensed music in
                                compliance with U.S copyright law.
                            </Text>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.ptagGen}>
                                <Text style={styles.titleP}>
                                    This music mix is licensed to:
                                </Text>{' '}
                                {license && license.first_name}{' '}
                                {license && license.last_name}
                            </Text>
                            <Text style={styles.ptagGen}>
                                <Text style={styles.titleP}>E-mail:</Text>{' '}
                                {license && license.email}
                            </Text>
                            <Text style={styles.ptagGen}>
                                <Text style={styles.titleP}>
                                    This audio track is to be used by:
                                </Text>{' '}
                                {license && license.audiousedby}
                            </Text>
                            <Text style={styles.ptagGen}>
                                <Text style={styles.titleP}>
                                    For the team named:
                                </Text>{' '}
                                {license && license.team_name}
                            </Text>
                            <View style={styles.sectiontop1}>
                                <Text style={styles.titletag}>
                                    License Terms & Conditions
                                </Text>
                                <Text style={styles.ptag}>
                                    The Licensee has agreed to Cheer Music Maker
                                    and all associated parties licensing terms
                                    and conditions. Licensee understands that
                                    the music licensed may only be used in the
                                    context of live performances, virtual
                                    internet streaming/webcasted cheerleading
                                    competitions hosted by companies approved by
                                    Cheer Music Maker.{' '}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <View
                                    style={{
                                        width: '50%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {license &&
                                        config?.user?.plan?._id ===
                                        '615d7054e556ecddc39f4b1d' && (
                                            <Text style={styles.redTitle}>
                                                31 Day Music License
                                            </Text>
                                        )}
                                    <Text style={styles.ptagGen}>
                                        <Text style={styles.titleP}>
                                            License Issued on:
                                        </Text>{' '}
                                        {license &&
                                            parseInt(today.getMonth() + 1) +
                                            '-' +
                                            today.getDate() +
                                            '-' +
                                            today.getFullYear()}
                                    </Text>

                                    <Text style={styles.ptagGen}>
                                        <Text style={styles.titleP}>
                                            License will expire:
                                        </Text>
                                        <Text style={{ color: 'red' }}>
                                            {' '}
                                            {license &&
                                                parseInt(
                                                    expireDay.getMonth() + 1
                                                ) +
                                                '-' +
                                                expireDay.getDate() +
                                                '-' +
                                                expireDay.getFullYear()}
                                        </Text>
                                    </Text>

                                    {/* <Text style={styles.ptagGen}>The license granted today will remain in effect until {(config.user.plan.plan_duration > 0 ? Math.abs(moment().diff(moment().add(config.user.plan.plan_duration, config.user.plan.plan_durationtype), 'days')) : Math.abs(moment().diff(moment().add(1, 'year'), 'days')))} days from current date of the following year.</Text> */}
                                    <Text style={styles.ptagGen}>
                                        <Text style={styles.titleP}>
                                            You have selected mix length:
                                        </Text>{' '}
                                        {license && license.mix_length} Minutes
                                    </Text>
                                    {/* <Text style={styles.ptagGen}><Text style={styles.titleP}>Mix length:</Text> {license && license.mix_length}</Text> */}
                                    <Text style={styles.ptagGen}>
                                        <Text style={styles.titleP}>
                                            Coupon:
                                        </Text>{' '}
                                        {license && license.coupon
                                            ? license.coupon
                                            : "You didn't added any coupon."}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        width: '50%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            width: '180px',
                                            height: '180px',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            position: 'relative',
                                        }}
                                    >
                                        <Image
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            src={licenseImg}
                                        />

                                        <Text
                                            style={{
                                                textAlign: 'center',
                                                position: 'absolute',
                                                top: '75%',
                                                left: '28%',
                                                transform:
                                                    'translate(-50%, -50%)',
                                                fontSize: '15',
                                            }}
                                        >
                                            #ML{license && license.licenseKey}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View>
                            <Text style={{ fontSize: 13, paddingLeft: 10 }}>
                                Total: {license && config.user?.plan?.plan_name}
                            </Text>
                        </View>

                        <View style={styles.sectiontop1}>
                            <Text style={styles.titletag}>Contact Us</Text>
                            <Text style={styles.ptag}>
                                If you have any questions regarding the audio
                                track in question please contact us at:
                                info@cheer-music-maker.com
                            </Text>
                        </View>
                    </View>
                </Page>
            </Document>
        );

        return (
            <Row gutter={10}>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Download your files below!
                    </h4>

                    <Button
                        block
                        type='primary'
                        loading={_this.state.downloadingmix}
                        onClick={() => {
                            this.downloadmix(license.renderedfile);
                        }}
                        download
                    >
                        Download Mix
                    </Button>
                    <Button
                        block
                        style={{ marginTop: 20 }}
                        type='primary'
                        onClick={() => {
                            _this.savelisence();
                        }}
                        download
                        loading={_this.state.downloadingLicense}
                    >
                        Download License
                    </Button>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                    <Spin spinning={_this.state.processingLicense}>
                        <div id='previewpane'>
                            {/* {this.state.signatureuesr && ( */}
                            <PDFViewer id='previewpane'>{licenseDoc}</PDFViewer>
                            {/* )} */}
                        </div>
                        <Button
                            disabled={!this.state.licenseProcessed}
                            block
                            type='primary'
                            onClick={() => {
                                _this.props.onDone();
                            }}
                        >
                            I have saved the license and mix.
                        </Button>
                    </Spin>
                </Col>
            </Row>
        );
    }
}

export default LicensePDF;
