import React, { Component } from 'react'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/draggable';

class Droppable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentheader: 1
        }
        this.droppable = React.createRef();
    }

    componentDidMount() {
        const _this = this;
        const node = this.droppable.current;
        $(node).droppable({
            accept: ".trackslistitems",
            over: function (event, ui) {
                ui.helper.css('z-index', 10);
                _this.props.onStart(event, ui)
            },
            drop: function (e, ui) {
                let leftOffset = document.getElementById('timeline-content').offsetLeft;

                let mainoffsetLeft = _this.props.scrollleft();
                let mainpos = ui.position.left - leftOffset + mainoffsetLeft;
                let posleft = parseInt(mainpos % 12);
                let thuippos = mainpos - posleft;
                let tname = ui.draggable.attr('data-name');
                let containerid = _this.randomId();
                let id = _this.randomId();
                let url = ui.draggable.attr('data-url');
                let name = tname.split('.');
                //_this.props.onAddtrack(containerid, id, name[0], url, 0)
                _this.props.onAddtrack({
                    containerid: containerid,
                    id: id,
                    name: name[0],
                    url: url,
                    position: thuippos
                })
            }
        });
    }

    randomId() {
        return '_' + Math.random().toString(10).substr(2, 9);
    }

    render() {
        return (
            <div ref={this.droppable}
                id={this.props.id}
                className={this.props.className}
                style={this.props.style}
                onChange={() => this.props.onChange()}
            >
                {this.props.children}
            </div>
        );
    }

    componentWillUnmount() {
        $(this.refs.droppable).droppable('destroy');
    }

}

export default Droppable;
