import React, { Component } from 'react'
import {
    Layout, Row, Col, Button,
    Form,
} from 'antd';

import { Link } from 'react-router-dom'

import FooterSection from "../FooterSection";
import { config } from "../../config";
import { reactLocalStorage } from "reactjs-localstorage";
import ProfileHeader from "./ProfileHeader";
import BasicinfoUpdate from "./BasicinfoUpdate";
import PasswordUpdate from "./PasswordUpdate";

const { Content } = Layout;

class EditProfileWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 'profile',
            islogged: config.islogged,
            uid: '',
        }
    }

    componentDidMount() {
        this.setState({
            uid: config.user._id
        })
    }


    loagout() {
        reactLocalStorage.clear()
        this.setState({
            islogged: false
        })
    }

    render() {
        return (
            <Layout className='body-front'>
                <ProfileHeader
                    className='topMenu'
                    onClick={this.handleClick}
                    selectedKeys={[this.state.current]}
                    mode="horizontal"
                />
                <Content>
                    <div>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <div style={{ padding: 30 }}>
                                    <Link to={`profile/${config.user._id}`}>Back to profile</Link>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <div style={{ padding: 30 }}>
                                    <h2 style={{ textAlign: 'center' }}>Update Profile</h2>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <h3 style={{ textAlign: 'center' }}>Producer Information</h3>
                                <div style={{ padding: 30 }}>
                                    <BasicinfoUpdate user={config.user} />
                                </div>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <h3 style={{ textAlign: 'center' }}>Change Password</h3>
                                <div style={{ padding: 30 }}>
                                    <PasswordUpdate user={config.user} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
                <FooterSection />

            </Layout>
        )
    }

}

const EditProfile = Form.create()(EditProfileWrapper);

export default EditProfile
