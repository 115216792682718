import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, message, Row, Card } from "antd";
import { postApi } from "../api";
import moment from "moment";
const FormItem = Form.Item;

const RecoverForm = ({ form, match }) => {
    const [uid, setUid] = useState("");
    const [hastime, setHasTime] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState(false);

    useEffect(() => {
        getRecoveryInfo(match.params.id);
    }, [match.params.id]);

    const getRecoveryInfo = id => {
        const getinfo = postApi(`getrecoveryinfo`, {
            authcode: id,
        });

        getinfo.then(resp => {
            if (resp && resp.status === "success") {
                setUid(resp.data.uid);
                const acdate = moment(resp.data.added_at).add("3", "days");
                const curdate = moment().toISOString();
                setHasTime(moment(acdate).isBefore(curdate));
            }
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                values.uid = uid;

                const updatepass = postApi(`updatepassword`, {
                    uid: uid,
                    password: values.password,
                });
                updatepass.then(resp => {
                    if (resp.status === "success") {
                        message.success(resp.msg, 5, () => {
                            setLoading(false);
                            window.location = "/";
                        });
                    } else {
                        message.error(resp.msg);
                        setLoading(false);
                    }
                });
            }
        });
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue("password")) {
            callback("Passwords don't match!");
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    const { getFieldDecorator } = form;
    return (
        <Row>
            <Col>
                <div className="headertop-centered">
                    <img className="logo" src="/CMM-Logo.png" alt="Logo" />
                </div>
                <div className="headertop-centered">
                    <Card title="Create new password">
                        {hastime ? (
                            <h3>
                                The link has expired. Please resend or try a
                                valid link.
                            </h3>
                        ) : (
                            <Form
                                onSubmit={handleSubmit}
                                className="login-form"
                            >
                                <FormItem>
                                    {getFieldDecorator("password", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please input your Password!",
                                            },
                                            {
                                                validator:
                                                    validateToNextPassword,
                                            },
                                        ],
                                    })(
                                        <Input
                                            type="password"
                                            placeholder="New password"
                                        />
                                    )}
                                </FormItem>

                                <FormItem>
                                    {getFieldDecorator("confirmPassword", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Passwords must match",
                                            },
                                            {
                                                validator:
                                                    compareToFirstPassword,
                                            },
                                        ],
                                    })(
                                        <Input
                                            type="password"
                                            placeholder="Confirm new password"
                                        />
                                    )}
                                </FormItem>

                                <FormItem>
                                    <Button
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                    >
                                        Reset
                                    </Button>
                                </FormItem>
                            </Form>
                        )}
                    </Card>
                </div>
            </Col>
        </Row>
    );
};

const Recover = Form.create()(RecoverForm);

export default Recover;
