const BackwardSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 208 208">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M104,9A95,95,0,1,1,9,104,95.11,95.11,0,0,1,104,9m0-9A104,104,0,1,0,208,104,104,104,0,0,0,104,0Z" />
                <path
                    style={{ fill: "currentColor" }}
                    d="M138.26,76,105.5,94.59l-5.56,3.16v6.42L105.5,101,141,80.79a2.07,2.07,0,0,1,.84-.31,4.27,4.27,0,0,1,.09.86v51.27a3.07,3.07,0,0,1-.08.75,2.63,2.63,0,0,1-.69-.31L105.5,111.21l-5.56-3.4v6.52l5.56,3.39,32.78,20.07a7.14,7.14,0,0,0,3.75,1.15c2.64,0,5.47-2,5.47-6.33V81.34C147.5,76.19,142.84,73.34,138.26,76Z"
                />
                <path
                    style={{ fill: "currentColor" }}
                    d="M96.25,76,53.1,100.51a5.91,5.91,0,0,0-.18,10.75l43.35,26.53a7.15,7.15,0,0,0,3.76,1.15c2.64,0,5.47-2,5.47-6.33V81.34C105.5,76.19,100.83,73.34,96.25,76Zm3.69,56.66a2.56,2.56,0,0,1-.09.75,2.61,2.61,0,0,1-.68-.31L55.83,106.52c-.51-.32-.7-.58-.72-.66s.21-.2.74-.51L99,80.79a2.18,2.18,0,0,1,.84-.31,3.86,3.86,0,0,1,.1.86Z"
                />
            </g>
        </g>
    </svg>
);

export default BackwardSvg;
