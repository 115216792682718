import React, { Component } from "react";
import JSZip from "jszip";
import axios from "axios";
import {
    Icon,
    Button,
    Table,
    Divider,
    Modal,
    Drawer,
    Badge,
    List,
    message,
    Input,
} from "antd";
import { callapi, deleteapi } from "../helper";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import AdminLicense from "./AdminLicense";
import MiniPlayer from "../studio/MiniPlayer";

const { confirm } = Modal;

class Tracks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tracks: [],
            filteredTracks: [],
            loadingtracks: false,
            renderModal: false,
            rendering: false,
            fulltrackInfo: false,
            single: false,
            singleDetails: false,
            savedtracksdrawer: false,
            pdftrackdrawer: false,
            singleLicense: false,

            isplayingrend: false,
            loadingAudio: false,
            searchText: "",

            isDownloading: false,
        };
        this.savedtracksdrawer = this.savedtracksdrawer.bind(this);
    }

    componentDidMount() {
        this.getsavedtracks();
    }

    getsavedtracks() {
        this.setState({
            loadingtracks: true,
        });

        const getallsavedtracks = callapi("savedtracks/admin");

        getallsavedtracks
            .then(data => {
                console.log(data.status);
                if (data.status === "success") {
                    // map the data to new object, include full_name username from uid
                    let tracks = data.data.savedTracks.map(item => {
                        return {
                            ...item,
                            full_name: item.full_name,
                            username: item.username,
                            uid: item.uid,
                            key: item._id,
                            created_at: moment(item.created_at).format(
                                "MMMM Do YYYY, h:mm:ss a"
                            ),
                        };
                    });

                    console.log(tracks[0]);

                    this.setState({
                        loadingtracks: false,
                        tracks: tracks,
                        filteredTracks: tracks,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    loadingtracks: false,
                });
            });
    }

    savedtracksdrawer = () => {
        this.setState({
            savedtracksdrawer: !this.state.savedtracksdrawer,
        });
    };

    pdftrackdrawer = () => {
        this.setState({
            pdftrackdrawer: !this.state.pdftrackdrawer,
        });
    };

    doplay(url) {
        const _this = this;
        _this.setState({
            loadingAudio: true,
            isplayingrend: true,
        });

        _this.playmini = new Audio(url);
        _this.playmini.onloadeddata = e => {
            _this.setState({
                loadingAudio: false,
            });
        };

        _this.playmini.play();
        _this.playmini.onended = () => {
            _this.dostop();
        };
    }

    dostop() {
        this.playmini.pause();
        this.playmini.currentTime = 0;
        this.setState({
            isplayingrend: false,
        });
    }

    editTrack(id) {
        reactLocalStorage.set("currenttrack", id);
        window.location = "/studio";
    }

    deleteSavedtrack(id, name) {
        const _this = this;
        confirm({
            title: `Are you sure to delete "${name}"?`,
            content: "You cannot retrieve again once you deleted.",
            okText: "Delete",
            okType: "danger",
            icon: (
                <Icon
                    style={{ fontSize: "25px", color: "#ff0000" }}
                    type="delete"
                />
            ),
            onOk() {
                const getSavedTracks = deleteapi(`savedtracks/${id}`);
                getSavedTracks
                    .then(data => {
                        if (data.status === "success") {
                            _this.getsavedtracks();
                            message.success("Track successfully deleted.");
                        } else {
                            message.error("Cannot delete track now.");
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        message.error("Cannot delete track now.");
                    });
            },
            onCancel() { },
        });
    }

    _onSearch(value) {
        this.setState({
            searchText: value,
        });
        let searchvalue = value.toLowerCase();
        if (searchvalue && searchvalue.length > 0) {
            let filteredTracks = this.state.tracks.filter(
                item =>
                    (item.track_name &&
                        item.track_name.toLowerCase().includes(searchvalue)) ||
                    (item.username &&
                        item.username.toLowerCase().includes(searchvalue))
            );
            this.setState({
                filteredTracks: filteredTracks,
            });
        } else {
            this.setState({
                filteredTracks: this.state.tracks,
            });
        }
    }

    async donwloadTrack(trackData) {
        const _this = this;
        this.setState({ isDownloading: true });

        const offlineCtx = new OfflineAudioContext({
            numberOfChannels: 2,
            // length of the mix in seconds 2 minutes 30 seconds
            length: 44100 * 150,
            sampleRate: 44100,
        });

        // 1. we need to get the tracks from the trackData url.
        let trackPromises = [];
        trackData.tracks.forEach(track => {
            trackPromises.push(
                axios.get(track.url, {
                    responseType: "blob",
                })
            );
        });

        const responses = await Promise.all(trackPromises);
        const audioBuffers = await Promise.all(
            responses.map(async blob => {
                const arrayBuffer = await blob.data.arrayBuffer();
                return await offlineCtx.decodeAudioData(arrayBuffer);
            })
        );

        const sourceNodes = audioBuffers.map((audioBuffer, inx) => {
            const track = trackData.tracks[inx];

            const sourceNode = offlineCtx.createBufferSource();
            sourceNode.buffer = audioBuffer;

            let posleft = parseInt(track.position % 12);
            let thuippos = track.position - posleft;

            const startTime =
                track.position > 0
                    ? thuippos / (12 * 2) / (trackData.tempo / 120)
                    : 0;

            sourceNode.start(offlineCtx.currentTime + startTime);

            sourceNode.connect(offlineCtx.destination);

            return sourceNode;
        });

        const audioBuffer = await offlineCtx.startRendering();

        const wavFile = await _this.audioBufferToWav(audioBuffer);

        const zip = new JSZip();
        responses.forEach((response, index) => {
            zip.file(`${trackData.tracks[index].name}.wav`, response.data);
        });
        zip.file(`mix.wav`, wavFile);

        const content = await zip.generateAsync({ type: "blob" });
        _this.saveAs(content, `${trackData.tracks[0].name}.zip`);
        _this.setState({
            isDownloading: false,
        });
    }

    saveAs(data, filename) {
        // data is blob file

        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(data, filename);
        } else {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = window.URL.createObjectURL(data);

            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    audioBufferToWav(audioBuffer) {
        const channelData = audioBuffer.getChannelData(0);
        const buffer = new ArrayBuffer(44 + channelData.length * 2);
        const view = new DataView(buffer);

        // RIFF identifier 'RIFF'
        view.setUint8(0, 82);
        view.setUint8(1, 73);
        view.setUint8(2, 70);
        view.setUint8(3, 70);

        // file length
        view.setUint32(4, 36 + channelData.length * 2, true);

        // RIFF type 'WAVE'
        view.setUint8(8, 87);
        view.setUint8(9, 65);
        view.setUint8(10, 86);
        view.setUint8(11, 69);

        // format chunk identifier 'fmt '
        view.setUint8(12, 102);
        view.setUint8(13, 109);
        view.setUint8(14, 116);
        view.setUint8(15, 32);

        // format chunk length
        view.setUint32(16, 16, true);

        // sample format (raw)
        view.setUint16(20, 1, true);

        // channel count
        view.setUint16(22, 1, true);

        // sample rate
        view.setUint32(24, audioBuffer.sampleRate, true);

        // byte rate (sample rate * block align)
        view.setUint32(28, audioBuffer.sampleRate * 2, true);

        // block align (channel count * bytes per sample)
        view.setUint16(32, 2, true);

        // bits per sample
        view.setUint16(34, 16, true);

        // data chunk identifier 'data'
        view.setUint8(36, 100);
        view.setUint8(37, 97);
        view.setUint8(38, 116);
        view.setUint8(39, 97);

        // data chunk length
        view.setUint32(40, channelData.length * 2, true);

        for (let i = 0; i < channelData.length; i++) {
            view.setInt16(44 + i * 2, channelData[i] * 0x7fff, true);
        }

        return new Blob([view], { type: "audio/wav" });
    }

    render() {
        const columns = [
            {
                title: "Track Title",
                dataIndex: "track_name",
                key: "track_name",
            },
            {
                title: "User Full name",
                dataIndex: "full_name",
                key: "full_name",
            },
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
            },
            {
                title: "Purchased",
                dataIndex: "purchased_tracks",
                key: "purchased_tracks",
                render: text => {
                    if (text.length > 0) {
                        return (
                            <Badge style={{ backgroundColor: "#52c41a" }}>
                                Purchased
                            </Badge>
                        );
                    } else {
                        return (
                            <Badge style={{ backgroundColor: "#ff020c" }}>
                                Not purchased
                            </Badge>
                        );
                    }
                },
            },
            {
                title: "Actions",
                dataIndex: "actions",
                key: "actions",
                render: (text, record) => {
                    return (
                        <span>
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => {
                                    this.setState({
                                        single: record,
                                        singleDetails: record.purchased_tracks,
                                        savedtracksdrawer:
                                            !this.state.savedtracksdrawer,
                                    });
                                }}
                            >
                                <Icon type="setting" />
                            </Button>
                            <Divider type="vertical" />
                            <Button
                                shape="circle-outline"
                                icon="edit"
                                onClick={() => {
                                    this.editTrack(record._id);
                                }}
                            />
                            <Divider type="vertical" />
                            <Button
                                type="danger"
                                icon="delete"
                                onClick={() =>
                                    this.deleteSavedtrack(
                                        record._id,
                                        record.track_name
                                    )
                                }
                            />
                            <Divider type="vertical" />
                            <Button
                                type="primary"
                                icon="download"
                                onClick={() =>
                                    this.donwloadTrack(record.track_data)
                                }
                                loading={this.state.isDownloading}
                            />
                        </span>
                    );
                },
            },
        ];

        return (
            <div id="thstudiomiddle">
                <Drawer
                    width={500}
                    placement="right"
                    onClose={this.savedtracksdrawer}
                    visible={this.state.savedtracksdrawer}
                >
                    <div style={{ marginTop: 25 }}>
                        <List
                            size="small"
                            bordered
                            dataSource={
                                this.state.singleDetails
                                    ? this.state.singleDetails
                                    : []
                            }
                            renderItem={item => {
                                return (
                                    <List.Item className="track-details">
                                        <div className="track-info">
                                            <h4>
                                                Audio used by:{" "}
                                                {item.audiousedby}
                                            </h4>
                                            <h4>Team: {item.team_name}</h4>
                                            <h4>
                                                purchased At:{" "}
                                                {moment(item.added_at).format(
                                                    "MMMM Do YYYY, h:mm:ss a"
                                                )}
                                            </h4>
                                        </div>
                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MiniPlayer
                                                link={item.renderedfile}
                                            />
                                            <Divider type="vertical" />
                                            <Button
                                                shape="circle-outline"
                                                icon="eye"
                                                onClick={() => {
                                                    this.setState(
                                                        { singleLicense: item },
                                                        () => {
                                                            setTimeout(() => {
                                                                this.pdftrackdrawer();
                                                            }, 500);
                                                        }
                                                    );
                                                }}
                                            />
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </div>

                    <Drawer
                        title="Send license"
                        width="80%"
                        closable={false}
                        onClose={this.pdftrackdrawer}
                        visible={this.state.pdftrackdrawer}
                    >
                        <AdminLicense license={this.state.singleLicense} />
                    </Drawer>
                </Drawer>

                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        padding: "20px 0",
                    }}
                ></div>

                <div style={{ backgroundColor: "#fff", padding: 20 }}>
                    <div className="searchbar">
                        <Input
                            className="searchbox"
                            placeholder="Search by tracks title or username"
                            value={this.state.searchText}
                            onChange={e => {
                                this._onSearch(e.target.value);
                            }}
                            suffix={
                                <Button
                                    type="primary"
                                    icon="close"
                                    onClick={() => {
                                        this.setState({
                                            filteredTracks: this.state.tracks,
                                            searchText: "",
                                        });
                                    }}
                                />
                            }
                        />
                    </div>
                    <Table
                        loading={this.state.loadingtracks}
                        columns={columns}
                        dataSource={this.state.filteredTracks}
                    />
                </div>
            </div>
        );
    }
}

export default Tracks;
