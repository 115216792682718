import { Col, Icon, List, Row } from "antd";
import React from "react";
import { Slide } from "react-reveal";
import ForwardArrowIcon from "../ForwardArrowIcon";

const HomePricing = ({ SignInModal }) => {
    return (
        <div className="home-section">
            <h1 className="section-header-dark">
                Cheer Music Maker <span>Pricing</span>
            </h1>
            <Row gutter={50} type="flex" justify="space-between">
                <Col span={24}>
                    <Slide left>
                        <div className="pricing-package">
                            <div className="package-title">
                                1 Time Pay-Per Download
                            </div>
                            <h1 className="package-price">
                                <span>$250</span> /Per Mix
                            </h1>
                            <div className="package-features">
                                <List
                                    size="small"
                                    dataSource={[
                                        {
                                            title: "1 time pay-per download.",
                                        },
                                        {
                                            title: "Get 2 free edits with each purchase.",
                                        },
                                        {
                                            title: "Proof of 1 year license included with each download purchased.",
                                        },
                                        {
                                            title: "Option to purchase custom voice overs.",
                                        },
                                        {
                                            title: "Upload your own 8 count sheets.",
                                        },
                                        {
                                            title: "All mixes are cleared for Live & Web Streaming performances",
                                        },
                                        {
                                            title: "Download your music mix and license instantly.",
                                        },
                                    ]}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Icon
                                                        type="check"
                                                        style={{
                                                            color: "#ff52f3",
                                                            fontSize: 20,
                                                        }}
                                                    />
                                                }
                                                title={
                                                    <div className="feature">
                                                        {item.title}
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                            <a
                                className="package-button browse-now-btn"
                                onClick={() => SignInModal(true, "2")}
                            >
                                BUY NOW <ForwardArrowIcon />
                            </a>
                        </div>
                    </Slide>
                </Col>
            </Row>
        </div>
    );
};

export default HomePricing;
