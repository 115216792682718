import React, { Component } from 'react'

import $ from 'jquery'
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/sortable';

class Sortable extends Component {
    componentDidMount() {
        $(this.refs.sortable)
            .sortable(this.props.init)
            .disableSelection();

    }

    render() {
        return (
            <div ref='sortable'
                style={this.props.style}
                className={this.props.className}
                id={this.props.id}
            >
                {this.props.children}
            </div>
        );
    }

    componentWillUnmount() {
        $(this.refs.sortable).sortable('destroy');
    }

}

export default Sortable;