import React, { Component } from 'react'
import { Row, Col, Layout, } from 'antd';
const { Footer } = Layout;
class FooterSection extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Footer className='frontFooter'>
                <div className='Footersection'>
                    <Row>
                        <Col md={{ span: 24 }} lg={{ span: 24 }}>
                            <Row type="flex" justify="center">
                                <Col sm={{ span: 24 }} lg={{ span: 16 }}>
                                    <h4 style={{ textAlign: 'center' }}>Contact us:</h4>
                                    <ul className='footerlinks' style={{ textAlign: 'center' }}>
                                        <li>
                                            <a href='mailto:info@cheer-music-maker.com'>
                                                info@cheer-music-maker.com
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Footer>

        )
    }

}

export default FooterSection
