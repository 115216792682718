import React, { Component } from 'react'

import $ from 'jquery'
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';

class Draggable extends Component {
    constructor(props) {
        super(props);
        this.draggable = React.createRef();
    }
    componentDidMount() {
        const node = this.draggable.current;
        $(node).draggable(this.props.init);
        if (this.props.resize) {
            $(node).resizable(this.props.resize);
        }

    }

    handledobuleClick = (e) => {
        this.props.ondblclick(e)
    }

    handleClick = (e) => {
        this.props.click(e)
    }

    render() {
        return (
            <div ref={this.draggable}
                onClick={this.handleClick}
                onDoubleClick={this.handledobuleClick}
                style={this.props.style}
                className={this.props.className}
                id={this.props.id}
                data-id={this.props.dataid}
                data-url={this.props.dataurl}
                data-name={this.props.dataname}
            >
                {this.props.children}
            </div>
        );
    }

    componentWillUnmount() {
        const node = this.draggable.current;
        $(node).draggable('destroy');
    }

}

export default Draggable;
