import React, { Component } from 'react'
import {
    Row, Col, Table, Icon,
    Divider, Modal, Button,
    Drawer, Form, Input, message, Upload, Radio, Tag
} from 'antd';
import { callapi, multipart, deleteapi, patchapi } from "../helper";
import moment from 'moment'

const FormItem = Form.Item;

class AdsWrapper extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            addPlan: false,
            createAd: false,
            ads: [],
            loadingAds: false,
            isSingle: false,
            single_id: '',

            single: false,
            loadingImage: false,
            imageUrl: "",
        }
    }

    componentWillMount() {
        this.getAllads()
    }

    getAllads() {
        //getAllads
        const _this = this
        _this.setState({
            loadingAds: true,
        })
        const categories = callapi('ads')
        categories.then((data) => {
            _this.setState({
                ads: data.data.data,
                loadingAds: false,
            })
        })
    }

    showDrawer = () => {
        this.setState({
            createAd: true,
        });
    };

    onClose = () => {
        this.setState({
            single: false
        });
    };

    savead = (e) => {
        e.preventDefault();
        const _this = this;
        _this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                _this.setState({
                    loading: true
                });

                if (_this.state.single) {

                    const formData = new FormData();
                    formData.append('title', values.title);
                    formData.append('url', values.url);
                    formData.append('status', values.status);
                    if (values.image.file) {
                        formData.append('file', values.image.file.originFileObj);
                    } else {
                        formData.append('image', _this.state.single.image);
                    }

                    if (
                        !values.image.file &&
                        _this.state.single.title === values.title &&
                        _this.state.single.url === values.url &&
                        _this.state.single.status === values.status
                    ) {
                        _this.setState({
                            loading: false,
                        });
                        Modal.info({
                            title: "Success",
                            content: "You didn't made any changes!"
                        });
                    }

                    const updatead = values.image.file ? multipart(`ads/${_this.state.single._id}/withimage`, formData) : patchapi(`ads/${_this.state.single._id}`, formData)

                    updatead.then((data) => {
                        if (data.status === 'error' || data.status === 'failed') {
                            message.error(data.message)
                            _this.setState({
                                loading: false,
                            });
                        } else {
                            _this.setState({
                                loading: false,
                                createAd: false,
                                imageUrl: ""
                            })
                            message.success(data.msg)
                            _this.getAllads()
                            _this.props.form.resetFields()
                        }
                    })

                } else {

                    const formData = new FormData();
                    formData.append('title', values.title);
                    formData.append('url', values.url);
                    formData.append('status', values.status);

                    if (values.image.file) {
                        formData.append('file', values.image.file.originFileObj);
                    }

                    const createad = multipart('ads', formData)

                    createad.then((data) => {
                        if (data.status === 'error' || data.status === 'failed') {
                            message.error(data.message)
                            _this.setState({
                                loading: false,
                            });
                        } else {
                            _this.setState({
                                loading: false,
                                createAd: false,
                                imageUrl: ""
                            })
                            message.success(data.msg)
                            _this.getAllads()
                            _this.props.form.resetFields()
                        }
                    })

                }
            }
        });
    }

    deletead(id) {
        const _this = this;

        Modal.confirm({
            title: 'Do you want to delete this ad?',
            content: 'You cannot retrieve once you deleted.',
            onOk() {
                deleteapi(`ads/${id}`).then((data) => {
                    if (data.status === 'error' || data.status === 'failed') {
                        message.error(data.message)
                        _this.setState({
                            loading: false
                        })
                    } else {
                        _this.setState({
                            createad: false,
                            loading: false,
                        })
                        message.success('Ad deleted!')
                        _this.getAllads()
                        _this.props.form.resetFields()
                    }
                }).catch((err) => {
                    message.error(err.message)
                    _this.setState({
                        loading: false
                    })
                })
            },
            onCancel() {
            },
        });
    }

    // convert image file for base64
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // call before upload the image and decide the file types and size
    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        // check if file size is not more then 2 mbps
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    // handle on change file uploader
    handleChangeImage = (info) => {
        const _this = this;
        _this.setState({
            loadingImage: true
        })
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            _this.getBase64(info.file.originFileObj, imageUrl => {

                _this.setState({
                    imageUrl: imageUrl,
                    loadingImage: false
                })
            });
        }
    };

    // create a dummy request for file uploader
    dummyRequest({ file, onSuccess }) {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    render() {
        let _this = this
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Created',
                dataIndex: 'createdAt',
                key: 'added_at',
                render: (text, record) => {
                    return <span>{moment(record.added_at).format('MMMM Do YYYY, h:mm:ss a')}</span>;
                }
            },
            {
                title: 'Image',
                dataIndex: 'image',
                key: 'image',
                render: (text, record) => {
                    return <img style={{ height: 20 }} src={text} />;
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    return text === 1 ? <Tag color="green">Active</Tag> : <Tag color="#ff0000">Inactive</Tag>;
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    return (
                        <span>
                            <Divider type="vertical" />
                            <Button onClick={() => {
                                console.log('record', record);
                                _this.setState({
                                    single: record,
                                }, () => {
                                    _this.showDrawer()
                                })
                            }} size='small' type="primary">
                                <Icon type="edit" />
                            </Button>
                            <Divider type="vertical" />
                            <Button onClick={() => {
                                _this.deletead(record._id)
                            }} size='small' type="danger">
                                <Icon type="delete" />
                            </Button>
                        </span>
                    )
                },
            }
        ];

        // create the upload button with a loader
        const uploadButton = (
            <div>
                <Icon type={_this.state.loadingImage ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div>
                <Row>
                    <Col lg={24}>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                padding: '20px 0'
                            }}>
                            <Button type="primary" onClick={_this.showDrawer}>Create Ad</Button>
                        </div>

                        <Drawer
                            title={this.state.single ? 'Update Ad' : 'Create new ad'}
                            width={520}
                            className="add_new_category"
                            closable={false}
                            onClose={_this.onClose}
                            visible={_this.state.createAd}
                        >
                            <div>
                                <Form onSubmit={(e) => _this.savead(e)}>
                                    <FormItem
                                        label="Title"
                                    >
                                        {getFieldDecorator('title', {
                                            rules: [
                                                {
                                                    required: true, message: 'You must type title!',
                                                }
                                            ],
                                            initialValue: _this.state.single.title
                                        })(
                                            <Input />
                                        )}
                                    </FormItem>

                                    <FormItem
                                        label="Url (https://example.com)"
                                    >
                                        {getFieldDecorator('url', {
                                            rules: [
                                                {
                                                    type: "url",
                                                    required: true, message: 'You must type url!',
                                                }
                                            ],
                                            initialValue: _this.state.single.url
                                        })(
                                            <Input />
                                        )}
                                    </FormItem>

                                    <Form.Item label="Select ad Image (png, jpg file only, Maintain aspect Ratio of 182x45px )">
                                        {getFieldDecorator('image', {
                                            initialValue: [],
                                            valuePropName: 'files',
                                            rules: [
                                                {
                                                    required: !_this.state.single, message: 'You must select ad image!',
                                                }
                                            ],
                                        })(
                                            <Upload
                                                name="ad_image"
                                                listType="picture-card"
                                                className="image-uploader"
                                                showUploadList={false}
                                                customRequest={_this.dummyRequest}
                                                beforeUpload={_this.beforeUpload}
                                                onChange={_this.handleChangeImage}
                                                accept="image/png, image/jpeg"
                                            >
                                                {
                                                    _this.state.imageUrl ?
                                                        <img src={_this.state.imageUrl} alt="ad_image"
                                                            style={{ width: '100%' }} />
                                                        :
                                                        _this.state.single.image ?
                                                            <img
                                                                src={_this.state.single.image}
                                                                alt="ad_image" style={{ width: '100%' }} /> : uploadButton

                                                }
                                            </Upload>,
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Ad Status">
                                        {getFieldDecorator('status', {
                                            rules: [
                                                {
                                                    required: true, message: 'You must select ad status!',
                                                }
                                            ],
                                            initialValue: _this.state.single ? parseInt(_this.state.single.status) : 1
                                        })(
                                            <Radio.Group buttonStyle="solid">
                                                <Radio.Button value={1}>Active</Radio.Button>
                                                <Radio.Button value={0}>Inactive</Radio.Button>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                    <FormItem>
                                        <Button.Group>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={_this.state.loading}
                                                disabled={_this.state.loading}
                                            >
                                                {_this.state.single ? 'Update ad' : 'Save ad'}
                                            </Button>
                                            <Button
                                                type="danger"
                                                disabled={_this.state.loading}
                                                onClick={() => {
                                                    this.setState({
                                                        createAd: false,
                                                        single: false,
                                                        imageUrl: ""
                                                    });
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Button.Group>

                                    </FormItem>
                                </Form>
                            </div>
                        </Drawer>
                    </Col>
                </Row>
                <Row>
                    <div style={{ backgroundColor: '#fff', padding: 20 }}>
                        <Table loading={_this.state.loadingAds} columns={columns} rowKey={record => record._id} dataSource={this.state.ads} />
                    </div>

                </Row>
            </div>
        )
    }

}

const Ads = Form.create({ name: 'adsform' })(AdsWrapper);

export default Ads
