import React, { Component } from 'react'
import {
    message, Button, Form, Input,
} from 'antd';
import { postapi } from "../../helper";

const FormItem = Form.Item;

class PasswordUpdateWrapper extends Component {
    constructor(props) {
        super(props)

        this.state = {
            confirmDirty: false
        }

    }

    handleSubmit = (e) => {
        e.preventDefault();
        let _this = this
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                const updateprofile = postapi(`users/${_this.props.user._id}/updatepassword`, {
                    password: values.password,
                })

                updateprofile.then((data) => {
                    if (data.status === 'error' || data.status === 'failed') {
                        message.error(data.message)
                        _this.setState({
                            loading: false
                        })
                    }
                    else if (data.status === 'success') {
                        message.info(data.msg)
                        _this.setState({
                            loading: false
                        })
                    }
                    else {
                        _this.setState({
                            loading: false,
                        })
                        message.success(data.msg)
                    }
                })
            }
        });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <Form onSubmit={this.handleSubmit}>

                <FormItem
                    {...formItemLayout}
                    label="Password"
                >
                    {getFieldDecorator('password', {
                        rules: [{
                            required: true, message: 'Please input your password!',
                        }, {
                            validator: this.validateToNextPassword,
                        }],
                    })(
                        <Input type="password" />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="Confirm Password"
                >
                    {getFieldDecorator('confirm', {
                        rules: [{
                            required: true, message: 'Please confirm your password!',
                        }, {
                            validator: this.compareToFirstPassword,
                        }],
                    })(
                        <Input type="password" onBlur={this.handleConfirmBlur} />
                    )}
                </FormItem>

                <FormItem {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Update Password</Button>
                </FormItem>
            </Form>
        )
    }

}

const PasswordUpdate = Form.create()(PasswordUpdateWrapper);

export default PasswordUpdate
