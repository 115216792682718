import React from "react";
import ForwardArrowIcon from "../ForwardArrowIcon";
import { Col, Row } from "antd";
import { Fade } from "react-reveal";
import headerBg1 from "../images/homepage-hero-1.png";

const HomeFeature = ({ SignInModal }) => {
    return (
        <div className="hero-section">
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 15 }}>
                    <Fade left>
                        <div className="hero-section-img">
                            <img
                                className="img-left"
                                src={headerBg1}
                                alt="cheer music maker's screen"
                            />
                        </div>
                    </Fade>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 9 }}>
                    <Fade cascade right>
                        <div className="hero-section-content">
                            <h1>
                                Over <span>800</span> - <span>8 Counts</span>
                            </h1>
                            <p>of original cheer music tracks to choose from</p>
                            <p>
                                Customize Your Cheer Music Maker Mix
                                <br />
                                <span>
                                    - Order custom voice-overs to add to your
                                    mix.
                                </span>
                                <br />
                                <span>
                                    - Upload your 8 count sheets and add sound
                                    effects to your music.
                                </span>
                            </p>
                            <p>
                                Download your Cheer Mix and License Instantly.
                            </p>
                            <a
                                className="browse-now-btn"
                                onClick={() => SignInModal(true, "2")}
                            >
                                Create Your Mix Now <ForwardArrowIcon />
                            </a>
                        </div>
                    </Fade>
                </Col>
            </Row>
        </div>
    );
};

export default HomeFeature;
