import { reactLocalStorage } from "reactjs-localstorage";

// reactLocalStorage.clear();

const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
};

export const config = {
    // apiurl: 'http://localhost:5000/api/v1/',
    apiurl: 'https://cheer-mp3-rest-api.herokuapp.com/api/v1/',
    cdnurl: 'https://cheeraudiofiles.s3-us-west-1.amazonaws.com/',
    sqprod: 'https://js.squareup.com/v2/paymentform',
    sqdev: 'https://js.squareupsandbox.com/v2/paymentform',
    user: reactLocalStorage.get('user') ? JSON.parse(reactLocalStorage.get('user')) : false,
    islogged: reactLocalStorage.get('islogged') ? true : undefined,
    copyright: `© ${new Date().getFullYear()} Cheer Music Maker.`,
    // stripeApikey: "pk_test_VyEpO8kQC6YSmxmEDXJUTLSI00TpD0zEy5", // development
    // stripeSecretkey: "sk_test_eqxiWl7AKfPCnd5dJas2DJWl00CFojzBpC", // development
    stripeApikey: "pk_test_51GqqreJ5jzb8kRSyYWW3MLgk4JTexb5GtDQxEO9N80Ys5w5erLTWcJKwRMmeApCSX050NBreTwpmbHrLhEphfh6D00JpNCKb3P", // development
    stripeSecretkey: "sk_test_51GqqreJ5jzb8kRSyhppRldYcCrmWjmyTnNBKXufZIhUtQIBfu2KGkkto7nh4rzwy4SlGolcPYlMCEsx4mb5eiplu007C1c6EE0", // development
    // plan_id: "price_1JQ6ujJ5jzb8kRSyfd1zpUW3", // development

    // stripeApikey: "pk_live_1kLLPyd6oSnUK9kTIuJ1bMsf00GbnBNkOS", // live
    // stripeSecretkey: "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", // live
    // plan_id: "price_1GrWjXJ5jzb8kRSyZRnhDZRL", // live

    // authkey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvY2hlZXJtcDMtbXVzaWMtbWFrZXIuY29tXC8iLCJ1c2VybmFtZSI6ImFkbWluIiwidWlkIjoiMSJ9.yIXfWqxqsSgNMsiBnrpdQBOjwuYtPhWUkqH0Uqu4yj4",
    authKey: reactLocalStorage.get('token') ? reactLocalStorage.get('token') : null,
};

