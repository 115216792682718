import React, { useState, useEffect, useRef } from "react";
import { Carousel, Spin, Button } from "antd";
import { callApi } from "../api";

const VideoTutorial = () => {
    const [loadingposts, setLoadingPosts] = useState(false);
    const [single, setSingle] = useState(null);
    const [posts, setPosts] = useState([]);
    const carousel = useRef(null);

    const getYoutubeVideoID = (url) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    const loadPosts = async () => {
        setLoadingPosts(true);
        try {
            const data = await callApi('posts');
            const postData = data?.data?.data;
            if (postData) {
                setSingle(postData[0]);
                setPosts(postData);
            }
        } catch (error) {
            console.error("Error loading posts:", error);
        } finally {
            setLoadingPosts(false);
        }
    }

    useEffect(() => {
        loadPosts();
    }, []);

    const renderPlayer = (id) => {
        return (
            <iframe
                id="existing-iframe-example"
                src={`https://www.youtube.com/embed/${id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
            ></iframe>
        );
    }

    const generateSlides = () => {
        return posts.map((item, index) => (
            <a
                key={index}
                className="custom-slides"
                onClick={() => setSingle(item)}
            >
                <div>
                    <img style={{ width: '100%' }} src={`https://img.youtube.com/vi/${getYoutubeVideoID(item.post_featured_embed)}/0.jpg`} alt={`Thumbnail for ${item.post_title}`} />
                </div>
                <div className="video-slide-title">
                    <span>{item.post_title}</span>
                </div>
            </a>
        ));
    }

    if (single) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <h1
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            fontSize: 16
                        }}
                    >{single.post_title}</h1>
                </div>
                <div
                    style={{
                        position: 'relative',
                        paddingBottom: '56.25%',
                        height: 0,
                        overflow: 'hidden'
                    }}
                >
                    {renderPlayer(getYoutubeVideoID(single.post_featured_embed))}
                </div>
                <div
                    style={{
                        padding: 10
                    }}>
                    <div className="video-post-slider">
                        <div className="video-slider-nav video-slider-nav-prev">
                            <Button
                                type="link"
                                size="small"
                                icon='left'
                                shape="circle"
                                onClick={() => carousel.current.prev()}
                            />
                        </div>
                        <Carousel
                            ref={carousel}
                            dots={false}
                            infinite={false}
                            slidesToShow={3}
                        >
                            {generateSlides()}
                        </Carousel>
                        <div className="video-slider-nav video-slider-nav-next">
                            <Button
                                type="link"
                                size="small"
                                icon='right'
                                shape="circle"
                                onClick={() => carousel.current.next()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="loading-content">
            <Spin />
        </div>
    );
}

export default VideoTutorial;
