import React, { Component } from "react";
import {
    Icon,
    Button,
    Upload,
    Row,
    Col,
    message,
    Card,
    Select,
    Table,
    Input,
    Spin,
    Form,
    Switch,
    Typography,
    Divider,
} from "antd";
import { config } from "../config";
import { callapi, deleteapi, patchapi } from "../helper";
import ReactAudioPlayer from "react-audio-player";

const Option = Select.Option;
const Dragger = Upload.Dragger;

// const FilterComponent = ({ filterText, onFilter, onClear }) => (
//     <Input
//         className="searchbox"
//         placeholder="Search project"
//         value={filterText}
//         onChange={onFilter}
//         suffix={<Button type="primary" icon="close" onClick={onClear} />}
//     />
// );

class UploadTracks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showaddtrack: true,
            volume: true,
            categories: [],
            selectedCats: "",
            filelist: [],

            tracks: [],
            filteredTracks: [],
            totalTracks: "",

            loadingtracks: false,
            isUploading: false,
            isOpen: false,
            searchText: "",
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        this.getCategories();
        this.loadTracks();
    }

    getCategories() {
        //getcategories
        const _this = this;
        _this.setState({
            loading: true,
        });
        const categories = callapi("categories");
        categories.then(data => {
            _this.setState({
                categories: data.data.data,
            });
        });
    }

    loadTracks(search = false) {
        const _this = this;
        _this.setState({
            loadingtracks: true,
        });

        const tracks = callapi("tracks?limit=1000");
        tracks.then(data => {
            _this.setState({
                tracks: data.data.data,
                filteredTracks: data.data.data,
                totalTracks: data.data.data.length,
                loadingtracks: false,
            });
        });
    }

    isOpentoggle = e => {
        this.setState({ isOpen: e });
    };

    handleChange(value) {
        this.setState({
            selectedCats: value,
        });
    }

    deleteTrack(id) {
        const deletetrack = deleteapi(`tracks/${id}`);

        deletetrack
            .then(data => {
                if (data.status === "error" || data.status === "failed") {
                    message.error(data.message);
                } else {
                    message.success("Track Deleted Successfully");
                    this.loadTracks();
                }
            })
            .catch(err => {
                message.error(err);
            });
    }

    updateisOpen(e, track_id) {
        const updateTrack = patchapi(`tracks/${track_id}`, {
            isOpen: e ? true : false,
        });

        updateTrack.then(data => {
            if (data.status === "error" || data.status === "failed") {
                message.error(data.message);
            } else {
                message.success(data.msg);
                this.loadTracks();
            }
        });
    }

    _onSearch(value) {
        this.setState({
            searchText: value,
        });
        let searchvalue = value.toLowerCase();
        if (searchvalue && searchvalue.length > 0) {
            let filteredTracks = this.state.tracks.filter(
                item =>
                    item.track_name &&
                    item.track_name.toLowerCase().includes(searchvalue)
            );
            this.setState({
                filteredTracks: filteredTracks,
            });
        } else {
            this.setState({
                filteredTracks: this.state.tracks,
            });
        }
    }

    render() {
        const columns = [
            {
                title: "Track Name",
                dataIndex: "track_name",
                key: "track_name",
                render: text => {
                    return (
                        <div style={{ width: 200, overflowWrap: "break-word" }}>
                            {text}
                        </div>
                    );
                },
            },
            {
                title: "Category",
                dataIndex: "cat_id",
                key: "cat_id",
                render: cat_ids => {
                    let cat_name = "";
                    if (cat_ids) {
                        cat_ids.forEach(cat_id => {
                            cat_name +=
                                this.state.categories.find(
                                    cat => cat._id === cat_id
                                )?.cat_name + ", ";
                        });
                    }
                    return (
                        <div style={{ overflowWrap: "break-word" }}>
                            {cat_name || "Uncategorized"}
                        </div>
                    );
                },
            },
            // {
            //     title: "Subscribers",
            //     dataIndex: "isOpen",
            //     key: "isOpen",
            //     render: (text, record) => {
            //         return (
            //             <Switch
            //                 onChange={e => {
            //                     this.updateisOpen(e, record._id);
            //                 }}
            //                 defaultChecked={record.isOpen}
            //             />
            //         );
            //     },
            // },
            {
                title: "Preview",
                dataIndex: "isOpen",
                key: "isOpen",
                render: (text, record) => {
                    return (
                        <ReactAudioPlayer
                            src={record.track_url}
                            controls
                            autoPlay={false}
                            preload="none"
                        />
                    );
                },
            },
            {
                title: "Actions",
                dataIndex: "actions",
                key: "actions",
                render: (text, record) => {
                    return (
                        <div>
                            <Button
                                type="danger"
                                icon="delete"
                                onClick={() => this.deleteTrack(record._id)}
                            />
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <Card>
                    <Typography.Title level={3}>
                        Upload New Track
                    </Typography.Title>

                    <Form.Item label="For Subscribers">
                        <Switch
                            onChange={this.isOpentoggle}
                            checked={this.state.isOpen}
                        />
                    </Form.Item>
                    <Select
                        mode="multiple"
                        style={{ width: "100%", marginBottom: 50 }}
                        placeholder="Please select category"
                        onChange={this.handleChange}
                    >
                        {this.state.categories &&
                            this.state.categories.map((item, index) => {
                                return (
                                    <Option key={index} value={item._id}>
                                        {item.cat_name}
                                    </Option>
                                );
                            })}
                    </Select>

                    <Dragger
                        accept="audio/*"
                        name="file"
                        key="musicfile"
                        multiple
                        method="POST"
                        headers={{
                            Authorization: `Bearer ${config.authKey}`,
                        }}
                        data={{
                            cat: this.state.selectedCats,
                            isOpen: this.state.isOpen ? 1 : 0,
                        }}
                        showUploadList={false}
                        action={`${config.apiurl}tracks/uploadtrack`}
                        beforeUpload={file => {
                            this.setState({ isUploading: true });
                            const iscats = this.state.selectedCats !== "";

                            if (!iscats) {
                                message.error(
                                    "Please select at least one category."
                                );
                                this.setState({ isUploading: false });
                            }
                            return iscats;
                        }}
                        onChange={info => {
                            const status = info.file.status;
                            if (status !== "uploading") {
                                console.log(status);
                            }

                            if (status === "done") {
                                const resp = info.file.response;
                                if (resp.status === "success") {
                                    this.loadTracks();
                                    this.setState({
                                        isUploading: false,
                                    });
                                    message.success(
                                        `${info.file.originFileObj.name} file uploaded successfully.`
                                    );
                                } else {
                                    this.setState({ isUploading: false });
                                    message.error(
                                        `${info.file.originFileObj.name} - ${resp.message}.`
                                    );
                                }
                            } else if (
                                status === "error" ||
                                status === "failed"
                            ) {
                                this.setState({
                                    isUploading: false,
                                });
                                message.error(
                                    `${info.file.originFileObj.name} file upload failed.`
                                );
                            }
                        }}
                    >
                        <Spin spinning={this.state.isUploading}>
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Please select .mp3 or .wav file to upload. File
                                size should not be bigger then 6mb.
                            </p>
                        </Spin>
                    </Dragger>
                </Card>

                <Divider />
                <Row>
                    <Col span={24}>
                        <Card>
                            <div className="searchbar">
                                <Input
                                    className="searchbox"
                                    value={this.state.searchText}
                                    placeholder="Search by Tracks name"
                                    onChange={e => {
                                        this._onSearch(e.target.value);
                                    }}
                                    suffix={
                                        <Button
                                            type="primary"
                                            icon="close"
                                            onClick={() => {
                                                this.setState({
                                                    filteredTracks:
                                                        this.state.tracks,
                                                    searchText: "",
                                                });
                                            }}
                                        />
                                    }
                                />
                            </div>
                            <Table
                                rowKey="_id"
                                loading={this.state.loadingtracks}
                                columns={columns}
                                dataSource={this.state.filteredTracks}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UploadTracks;
