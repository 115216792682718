import React, { useState } from "react";
import {
    Form,
    Icon,
    Input,
    Button,
    message,
    Tooltip,
    Select,
    Checkbox,
} from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { callapi, postapi } from "../helper";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;

const SignUpForm = ({ form, islogged, SignInModal }) => {
    const [loading, setLoading] = useState(false);
    const [profileType, setProfileType] = useState("gymowner");

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);

                const signup = postapi(
                    "users/signup",
                    {
                        username: values.email,
                        password: values.password,
                        profile_affiliation: values.profile_affiliation,
                        profile_type: values.profile_type,
                        added_at: moment().toISOString(),
                        first_name: values.first_name,
                        last_name: values.last_name,
                    },
                    false
                );

                signup.then(data => {
                    if (data.status === "failed" || data.status === "error") {
                        message.error(data.message);
                        setLoading(false);
                    } else {
                        reactLocalStorage.set("token", data.token);
                        message.success(data.msg);

                        const uid = data.data.user._id;
                        const getuser = callapi(
                            `users/${uid}`,
                            true,
                            data.token
                        );

                        getuser.then(datau => {
                            reactLocalStorage.setObject(
                                "user",
                                datau.data.user
                            );
                            reactLocalStorage.set("islogged", true);
                            setLoading(false);

                            islogged(true);
                            SignInModal(false);
                            window.location = `/profile/${datau.data.user._id}`;
                        });
                    }
                });
            }
        });
    };

    const compareToFirstPassword = (rule, value, callback) => {
        const currentForm = form;
        if (value && value !== currentForm.getFieldValue("password")) {
            callback("Passwords don't match!");
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        const currentForm = form;
        if (value && currentForm.getFieldValue("confirmDirty")) {
            currentForm.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    const handleProfileTypeChange = profileType => {
        setProfileType(profileType);
    };

    const { getFieldDecorator } = form;

    return (
        <div>
            <div className="logo-container">
                <img src="/CMM-Logo.png" alt="Cheer Music Maker Logo" />
            </div>

            <Form onSubmit={handleSubmit} className="login-form">
                <FormItem>
                    {getFieldDecorator("email", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="mail"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                />
                            }
                            placeholder="Email"
                        />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator("password", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                            { validator: validateToNextPassword },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                />
                            }
                            type="password"
                            placeholder="Password"
                        />
                    )}
                </FormItem>

                <FormItem>
                    {getFieldDecorator("confirmPassword", {
                        rules: [
                            {
                                required: true,
                                message:
                                    "Confirm password and password must match",
                            },
                            { validator: compareToFirstPassword },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                />
                            }
                            type="password"
                            placeholder="Confirm Password"
                        />
                    )}
                </FormItem>

                <FormItem label="Enter First Name">
                    {getFieldDecorator("first_name", {
                        rules: [
                            {
                                required: true,
                                message: "Please type your first name",
                            },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                />
                            }
                            type="text"
                            placeholder="first Name"
                        />
                    )}
                </FormItem>

                <FormItem label="Enter Last Name">
                    {getFieldDecorator("last_name", {
                        rules: [
                            {
                                required: true,
                                message: "Please type your last name",
                            },
                        ],
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                />
                            }
                            type="text"
                            placeholder="Last Name"
                        />
                    )}
                </FormItem>

                <FormItem label="Program or Gym Name">
                    {getFieldDecorator("profile_affiliation", {
                        rules: [
                            {
                                required: true,
                                message: "Please type Program or Gym Name",
                            },
                        ],
                    })(<Input type="text" placeholder="Program or Gym Name" />)}
                </FormItem>

                <FormItem label="Affiliation with Program">
                    {getFieldDecorator("profile_type", {
                        rules: [
                            {
                                required: true,
                                message:
                                    "Please select Affiliation with Program",
                            },
                        ],
                    })(
                        <Select onChange={handleProfileTypeChange}>
                            <Option value="gymowner">GYM Owner</Option>
                            <Option value="coach">Coach</Option>
                            <Option value="choreographer">Choreographer</Option>
                            <Option value="athlete">Athlete</Option>
                        </Select>
                    )}
                </FormItem>

                <FormItem>
                    {getFieldDecorator("agreement", {
                        valuePropName: "checked",
                        // rules: [[
                        //     {
                        //         validator: (_, value) =>
                        //             value ? Promise.resolve() : Promise.reject(new Error('Please Accept the Lisence Agreement')),
                        //     },
                        // ]],
                        rules: [
                            {
                                validator: (_, value) =>
                                    value
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error(
                                                  "Please accept the License Agreement"
                                              )
                                          ),
                            },
                        ],
                    })(
                        <Checkbox className="dispBlock">
                            I agree to the{" "}
                            <a
                                target="_blank"
                                rel="noopener noreferer"
                                href="/terms.pdf"
                            >
                                licensing terms and services
                            </a>
                        </Checkbox>
                    )}
                </FormItem>

                <FormItem>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        Sign Up
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
};

const SignUp = Form.create()(SignUpForm);

export default SignUp;
