import React from "react";
import { Button, Col, Divider, Layout, Row } from "antd";
import { Link } from "react-router-dom";
import { config } from "../../config";

const { Header } = Layout;

const HomeHeader = ({ SignInModal, toggle, sidemenu }) => {
    return (
        <Header className="front-header">
            <Row type="flex" justify="space-between">
                <Col xs={{ span: 12 }} md={{ span: 5 }} sm={{ span: 4 }}>
                    <Link to="/" style={{ height: "60px" }}>
                        <img className="header-logo" src="/CMM-Logo.png" />
                    </Link>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                    <div className="float-right">
                        <div className="header-dflex-right">
                            <div className="d-inline" id="header-menu">
                                {config.user &&
                                config.user._id &&
                                config.user.user_type !== "admin" ? (
                                    <>
                                        <Link
                                            to={`/profile/${config.user._id}`}
                                            className="ant-btn-link"
                                        >
                                            Your Profile
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <a
                                            onClick={() => SignInModal(true)}
                                            className="color-dark ant-btn-link"
                                        >
                                            LOG IN
                                        </a>
                                        <Divider
                                            style={{
                                                backgroundColor: "#404040",
                                            }}
                                            type="vertical"
                                        />
                                        <a
                                            onClick={() =>
                                                SignInModal(true, "2")
                                            }
                                            className="ant-btn-link"
                                        >
                                            SIGN UP
                                        </a>
                                    </>
                                )}
                            </div>

                            <Button
                                className="hero-button"
                                id="header-menu-toggle"
                                type="link"
                                onClick={toggle}
                                icon={sidemenu ? "menu-unfold" : "menu-fold"}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Header>
    );
};

export default HomeHeader;
