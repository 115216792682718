import React, { Component } from 'react'
import {
    Icon, message, Button,
    Form, Input, Tooltip, Select
} from 'antd';
import { config } from "../../config";
import { callapi, patchapi } from "../../helper";
import { reactLocalStorage } from "reactjs-localstorage";

const FormItem = Form.Item;
const Option = Select.Option;

class BasicinfoUpdateWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            islogged: config.islogged,
            uid: '',
            confirmDirty: false,
            loading: false
        }
    }

    componentDidMount() {
        const { setFieldsValue } = this.props.form;
        this.setState({
            loading: false
        })
        setFieldsValue({
            email: this.props.user.username,
            full_name: this.props.user.full_name,
            phone: this.props.user.user_phone,
            prefix: this.props.user.phone_ext,
            profile_affiliation: this.props.user.profile_affiliation,
            profile_type: this.props.user.profile_type,
            first_name: this.props.user.first_name,
            last_name: this.props.user.last_name,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let _this = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                const updateprofile = patchapi(`users/${_this.props.user._id}`, {
                    user_phone: values.phone,
                    phone_ext: values.prefix,
                    profile_type: _this.state.profile_type,
                    profile_affiliation: values.profile_affiliation,
                    first_name: values.first_name,
                    last_name: values.last_name,
                });

                updateprofile.then((data) => {
                    _this.setState({
                        loading: false,
                    });
                    message.success('Profile successfully updated.', () => {
                        _this.resetsessionuser(_this.props.user._id)
                    })
                })
            }
        });
    }

    resetsessionuser(uid) {
        const dologin = callapi(`users/${uid}`);

        dologin.then((data) => {
            if (data.status === 'success') {
                reactLocalStorage.setObject('user', data.data.user)
                window.location.reload()
            }
        })
    }

    handleClick = (e) => {
        this.setState({
            current: e.key,
        });
    }

    handleprofiletypeChange = (profile_type) => {
        this.setState({ profile_type });
    }


    render() {
        const _this = this;
        const { getFieldDecorator } = _this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix')(
            <Select style={{ width: 100 }}>
                <Option data-countryCode="DZ" value="213">(+213)</Option>
                <Option data-countryCode="AD" value="376">(+376)</Option>
                <Option data-countryCode="AO" value="244">(+244)</Option>
                <Option data-countryCode="AI" value="1264">(+1264)</Option>
                <Option data-countryCode="AG" value="1268">(+1268)</Option>
                <Option data-countryCode="AR" value="54">(+54)</Option>
                <Option data-countryCode="AM" value="374">(+374)</Option>
                <Option data-countryCode="AW" value="297">(+297)</Option>
                <Option data-countryCode="AU" value="61">(+61)</Option>
                <Option data-countryCode="AT" value="43">(+43)</Option>
                <Option data-countryCode="AZ" value="994">(+994)</Option>
                <Option data-countryCode="BS" value="1242">(+1242)</Option>
                <Option data-countryCode="BH" value="973">(+973)</Option>
                <Option data-countryCode="BD" value="880">(+880)</Option>
                <Option data-countryCode="BB" value="1246">(+1246)</Option>
                <Option data-countryCode="BY" value="375">(+375)</Option>
                <Option data-countryCode="BE" value="32">(+32)</Option>
                <Option data-countryCode="BZ" value="501">(+501)</Option>
                <Option data-countryCode="BJ" value="229">(+229)</Option>
                <Option data-countryCode="BM" value="1441">(+1441)</Option>
                <Option data-countryCode="BT" value="975">(+975)</Option>
                <Option data-countryCode="BO" value="591">(+591)</Option>
                <Option data-countryCode="BA" value="387">(+387)</Option>
                <Option data-countryCode="BW" value="267">(+267)</Option>
                <Option data-countryCode="BR" value="55">(+55)</Option>
                <Option data-countryCode="BN" value="673">(+673)</Option>
                <Option data-countryCode="BG" value="359">(+359)</Option>
                <Option data-countryCode="BF" value="226">(+226)</Option>
                <Option data-countryCode="BI" value="257">(+257)</Option>
                <Option data-countryCode="KH" value="855">(+855)</Option>
                <Option data-countryCode="CM" value="237">(+237)</Option>
                <Option data-countryCode="CA" value="1">(+1)</Option>
                <Option data-countryCode="CV" value="238">(+238)</Option>
                <Option data-countryCode="KY" value="1345">(+1345)</Option>
                <Option data-countryCode="CF" value="236">(+236)</Option>
                <Option data-countryCode="CL" value="56">(+56)</Option>
                <Option data-countryCode="CN" value="86">(+86)</Option>
                <Option data-countryCode="CO" value="57">(+57)</Option>
                <Option data-countryCode="KM" value="269">(+269)</Option>
                <Option data-countryCode="CG" value="242">(+242)</Option>
                <Option data-countryCode="CK" value="682">(+682)</Option>
                <Option data-countryCode="CR" value="506">(+506)</Option>
                <Option data-countryCode="HR" value="385">(+385)</Option>
                <Option data-countryCode="CU" value="53">(+53)</Option>
                <Option data-countryCode="CY" value="90392">(+90392)</Option>
                <Option data-countryCode="CY" value="357">(+357)</Option>
                <Option data-countryCode="CZ" value="42">(+42)</Option>
                <Option data-countryCode="DK" value="45">(+45)</Option>
                <Option data-countryCode="DJ" value="253">(+253)</Option>
                <Option data-countryCode="DM" value="1809">(+1809)</Option>
                <Option data-countryCode="DO" value="1809">(+1809)</Option>
                <Option data-countryCode="EC" value="593">(+593)</Option>
                <Option data-countryCode="EG" value="20">(+20)</Option>
                <Option data-countryCode="SV" value="503">(+503)</Option>
                <Option data-countryCode="GQ" value="240">(+240)</Option>
                <Option data-countryCode="ER" value="291">(+291)</Option>
                <Option data-countryCode="EE" value="372">(+372)</Option>
                <Option data-countryCode="ET" value="251">(+251)</Option>
                <Option data-countryCode="FK" value="500">(+500)</Option>
                <Option data-countryCode="FO" value="298">(+298)</Option>
                <Option data-countryCode="FJ" value="679">(+679)</Option>
                <Option data-countryCode="FI" value="358">(+358)</Option>
                <Option data-countryCode="FR" value="33">(+33)</Option>
                <Option data-countryCode="GF" value="594">(+594)</Option>
                <Option data-countryCode="PF" value="689">(+689)</Option>
                <Option data-countryCode="GA" value="241">(+241)</Option>
                <Option data-countryCode="GM" value="220">(+220)</Option>
                <Option data-countryCode="GE" value="7880">(+7880)</Option>
                <Option data-countryCode="DE" value="49">(+49)</Option>
                <Option data-countryCode="GH" value="233">(+233)</Option>
                <Option data-countryCode="GI" value="350">(+350)</Option>
                <Option data-countryCode="GR" value="30">(+30)</Option>
                <Option data-countryCode="GL" value="299">(+299)</Option>
                <Option data-countryCode="GD" value="1473">(+1473)</Option>
                <Option data-countryCode="GP" value="590">(+590)</Option>
                <Option data-countryCode="GU" value="671">(+671)</Option>
                <Option data-countryCode="GT" value="502">(+502)</Option>
                <Option data-countryCode="GN" value="224">(+224)</Option>
                <Option data-countryCode="GW" value="245">(+245)</Option>
                <Option data-countryCode="GY" value="592">(+592)</Option>
                <Option data-countryCode="HT" value="509">(+509)</Option>
                <Option data-countryCode="HN" value="504">(+504)</Option>
                <Option data-countryCode="HK" value="852">(+852)</Option>
                <Option data-countryCode="HU" value="36">(+36)</Option>
                <Option data-countryCode="IS" value="354">(+354)</Option>
                <Option data-countryCode="IN" value="91">(+91)</Option>
                <Option data-countryCode="ID" value="62">(+62)</Option>
                <Option data-countryCode="IR" value="98">(+98)</Option>
                <Option data-countryCode="IQ" value="964">(+964)</Option>
                <Option data-countryCode="IE" value="353">(+353)</Option>
                <Option data-countryCode="IL" value="972">(+972)</Option>
                <Option data-countryCode="IT" value="39">(+39)</Option>
                <Option data-countryCode="JM" value="1876">(+1876)</Option>
                <Option data-countryCode="JP" value="81">(+81)</Option>
                <Option data-countryCode="JO" value="962">(+962)</Option>
                <Option data-countryCode="KZ" value="7">(+7)</Option>
                <Option data-countryCode="KE" value="254">(+254)</Option>
                <Option data-countryCode="KI" value="686">(+686)</Option>
                <Option data-countryCode="KP" value="850">(+850)</Option>
                <Option data-countryCode="KR" value="82">(+82)</Option>
                <Option data-countryCode="KW" value="965">(+965)</Option>
                <Option data-countryCode="KG" value="996">(+996)</Option>
                <Option data-countryCode="LA" value="856">(+856)</Option>
                <Option data-countryCode="LV" value="371">(+371)</Option>
                <Option data-countryCode="LB" value="961">(+961)</Option>
                <Option data-countryCode="LS" value="266">(+266)</Option>
                <Option data-countryCode="LR" value="231">(+231)</Option>
                <Option data-countryCode="LY" value="218">(+218)</Option>
                <Option data-countryCode="LI" value="417">(+417)</Option>
                <Option data-countryCode="LT" value="370">(+370)</Option>
                <Option data-countryCode="LU" value="352">(+352)</Option>
                <Option data-countryCode="MO" value="853">(+853)</Option>
                <Option data-countryCode="MK" value="389">(+389)</Option>
                <Option data-countryCode="MG" value="261">(+261)</Option>
                <Option data-countryCode="MW" value="265">(+265)</Option>
                <Option data-countryCode="MY" value="60">(+60)</Option>
                <Option data-countryCode="MV" value="960">(+960)</Option>
                <Option data-countryCode="ML" value="223">(+223)</Option>
                <Option data-countryCode="MT" value="356">(+356)</Option>
                <Option data-countryCode="MH" value="692">(+692)</Option>
                <Option data-countryCode="MQ" value="596">(+596)</Option>
                <Option data-countryCode="MR" value="222">(+222)</Option>
                <Option data-countryCode="YT" value="269">(+269)</Option>
                <Option data-countryCode="MX" value="52">(+52)</Option>
                <Option data-countryCode="FM" value="691">(+691)</Option>
                <Option data-countryCode="MD" value="373">(+373)</Option>
                <Option data-countryCode="MC" value="377">(+377)</Option>
                <Option data-countryCode="MN" value="976">(+976)</Option>
                <Option data-countryCode="MS" value="1664">(+1664)</Option>
                <Option data-countryCode="MA" value="212">(+212)</Option>
                <Option data-countryCode="MZ" value="258">(+258)</Option>
                <Option data-countryCode="MN" value="95">(+95)</Option>
                <Option data-countryCode="NA" value="264">(+264)</Option>
                <Option data-countryCode="NR" value="674">(+674)</Option>
                <Option data-countryCode="NP" value="977">(+977)</Option>
                <Option data-countryCode="NL" value="31">(+31)</Option>
                <Option data-countryCode="NC" value="687">(+687)</Option>
                <Option data-countryCode="NZ" value="64">(+64)</Option>
                <Option data-countryCode="NI" value="505">(+505)</Option>
                <Option data-countryCode="NE" value="227">(+227)</Option>
                <Option data-countryCode="NG" value="234">(+234)</Option>
                <Option data-countryCode="NU" value="683">(+683)</Option>
                <Option data-countryCode="NF" value="672">(+672)</Option>
                <Option data-countryCode="NP" value="670">(+670)</Option>
                <Option data-countryCode="NO" value="47">(+47)</Option>
                <Option data-countryCode="OM" value="968">(+968)</Option>
                <Option data-countryCode="PW" value="680">(+680)</Option>
                <Option data-countryCode="PA" value="507">(+507)</Option>
                <Option data-countryCode="PG" value="675">(+675)</Option>
                <Option data-countryCode="PY" value="595">(+595)</Option>
                <Option data-countryCode="PE" value="51">(+51)</Option>
                <Option data-countryCode="PH" value="63">(+63)</Option>
                <Option data-countryCode="PL" value="48">(+48)</Option>
                <Option data-countryCode="PT" value="351">(+351)</Option>
                <Option data-countryCode="PR" value="1787">(+1787)</Option>
                <Option data-countryCode="QA" value="974">(+974)</Option>
                <Option data-countryCode="RE" value="262">(+262)</Option>
                <Option data-countryCode="RO" value="40">(+40)</Option>
                <Option data-countryCode="RU" value="7">(+7)</Option>
                <Option data-countryCode="RW" value="250">(+250)</Option>
                <Option data-countryCode="SM" value="378">(+378)</Option>
                <Option data-countryCode="ST" value="239">(+239)</Option>
                <Option data-countryCode="SA" value="966">(+966)</Option>
                <Option data-countryCode="SN" value="221">(+221)</Option>
                <Option data-countryCode="CS" value="381">(+381)</Option>
                <Option data-countryCode="SC" value="248">(+248)</Option>
                <Option data-countryCode="SL" value="232">(+232)</Option>
                <Option data-countryCode="SG" value="65">(+65)</Option>
                <Option data-countryCode="SK" value="421">(+421)</Option>
                <Option data-countryCode="SI" value="386">(+386)</Option>
                <Option data-countryCode="SB" value="677">(+677)</Option>
                <Option data-countryCode="SO" value="252">(+252)</Option>
                <Option data-countryCode="ZA" value="27">(+27)</Option>
                <Option data-countryCode="ES" value="34">(+34)</Option>
                <Option data-countryCode="LK" value="94">(+94)</Option>
                <Option data-countryCode="SH" value="290">(+290)</Option>
                <Option data-countryCode="KN" value="1869">(+1869)</Option>
                <Option data-countryCode="SC" value="1758">(+1758)</Option>
                <Option data-countryCode="SD" value="249">(+249)</Option>
                <Option data-countryCode="SR" value="597">(+597)</Option>
                <Option data-countryCode="SZ" value="268">(+268)</Option>
                <Option data-countryCode="SE" value="46">(+46)</Option>
                <Option data-countryCode="CH" value="41">(+41)</Option>
                <Option data-countryCode="SI" value="963">(+963)</Option>
                <Option data-countryCode="TW" value="886">(+886)</Option>
                <Option data-countryCode="TJ" value="7">(+7)</Option>
                <Option data-countryCode="TH" value="66">(+66)</Option>
                <Option data-countryCode="TG" value="228">(+228)</Option>
                <Option data-countryCode="TO" value="676">(+676)</Option>
                <Option data-countryCode="TT" value="1868">(+1868)</Option>
                <Option data-countryCode="TN" value="216">(+216)</Option>
                <Option data-countryCode="TR" value="90">(+90)</Option>
                <Option data-countryCode="TM" value="7">(+7)</Option>
                <Option data-countryCode="TM" value="993">(+993)</Option>
                <Option data-countryCode="TC" value="1649">(+1649)</Option>
                <Option data-countryCode="TV" value="688">(+688)</Option>
                <Option data-countryCode="UG" value="256">(+256)</Option>
                <Option data-countryCode="GB" value="44">(+44)</Option>
                <Option data-countryCode="UA" value="380">(+380)</Option>
                <Option data-countryCode="AE" value="971">(+971)</Option>
                <Option data-countryCode="UY" value="598">(+598)</Option>
                <Option data-countryCode="US" value="1">(+1)</Option>
                <Option data-countryCode="UZ" value="7">(+7)</Option>
                <Option data-countryCode="VU" value="678">(+678)</Option>
                <Option data-countryCode="VA" value="379">(+379)</Option>
                <Option data-countryCode="VE" value="58">(+58)</Option>
                <Option data-countryCode="VN" value="84">(+84)</Option>
                <Option data-countryCode="VG" value="84">(+1284)</Option>
                <Option data-countryCode="VI" value="84">(+1340)</Option>
                <Option data-countryCode="WF" value="681">(+681)</Option>
                <Option data-countryCode="YE" value="969">(+969)</Option>
                <Option data-countryCode="YE" value="967">(+967)</Option>
                <Option data-countryCode="ZM" value="260">(+260)</Option>
                <Option data-countryCode="ZW" value="263">(+263)</Option>
            </Select>
        );

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem
                    {...formItemLayout}
                    label="E-mail"
                >
                    {getFieldDecorator('email', {
                        initialValue: _this.props.user.username,
                        rules: [{
                            type: 'email', message: 'The input is not valid E-mail!',
                        }, {
                            required: true, message: 'Please input your E-mail!',
                        }],
                    })(
                        <Input />
                    )}
                </FormItem>

                {/* <FormItem
                    {...formItemLayout}
                    label={(
                        <span>Full Name&nbsp;
                            <Tooltip title="What do you want others to call you?">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    )}
                >
                    {getFieldDecorator('full_name', {
                        rules: [{
                            whitespace: true
                        }],
                    })(
                        <Input />
                    )}
                </FormItem> */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <span>First Name&nbsp;
                            <Tooltip title="What do you want others to call you?">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    )}
                >
                    {getFieldDecorator('first_name', {
                        rules: [{
                            whitespace: true
                        }],
                    })(
                        <Input />
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label={(
                        <span>Last Name&nbsp;
                            <Tooltip title="What do you want others to call you?">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    )}
                >
                    {getFieldDecorator('last_name', {
                        rules: [{
                            whitespace: true
                        }],
                    })(
                        <Input />
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="Phone Number"
                >
                    {getFieldDecorator('phone')(
                        <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="Program or Gym Name"
                >
                    {getFieldDecorator('profile_affiliation', {
                        rules: [{ required: true, message: 'Please type Program or Gym Name' },],
                    })(
                        <Input type="text" placeholder="Program or Gym Name" />
                    )}

                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label="Affiliation with Program"
                >
                    {getFieldDecorator('profile_type', {
                        rules: [{ required: true, message: 'Please select Affiliation with Program' },],
                    })(
                        <Select
                            onChange={this.handleprofiletypeChange}
                        >
                            <Option value="gymowner">GYM Owner</Option>
                            <Option value="athlete">Athlete</Option>
                            <Option value="coach">Coach</Option>
                            <Option value="choreographer">Choreographer</Option>
                        </Select>
                    )}
                </FormItem>

                <FormItem {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Update Producer Information</Button>
                </FormItem>
            </Form>
        )
    }

}

const BasicinfoUpdate = Form.create()(BasicinfoUpdateWrapper);

export default BasicinfoUpdate
